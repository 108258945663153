import React, { Component } from "react"
import { Container, Row, Col } from 'reactstrap'
import PaymentPlanListHeader from "./PaymentPlanListHeader"
import PaymentPlanList from "./PaymentPlanList"

class PaymentPlanListContainer
    extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                orderBy: ["-id"],
                status: "ACTIVE"
            },
            refetchPaymentPlans: false,
        }
    }

    updateOrder = (string) => {
        let order = this.state.filterValues.orderBy ? [...this.state.filterValues.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState((prevState) => ({ filterValues: { ...prevState.filterValues, orderBy: order } }))
    };

    render() {
        return (
            <Container fluid className="bos-listing-container">
                <PaymentPlanListHeader
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    defaultFilters={this.state.filterValues}
                    currentUser={this.props.currentUser}
                    location={this.props.location}
                    history={this.props.history}
                />
                <Row>
                    <Col>
                        <PaymentPlanList
                            key={String(this.state.refetchPaymentPlans)}
                            orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                            updateOrder={this.updateOrder}
                            currentUser={this.props.currentUser}
                            searchTerm={this.state.filterValues && this.state.filterValues.searchTerm ? this.state.filterValues.searchTerm : null}
                            status={this.state.filterValues && this.state.filterValues.status !== undefined && this.state.filterValues.status !== null ? this.state.filterValues.status : "A_1"}
                        />

                    </Col>
                </Row>
            </Container>

        )
    }
}

export default PaymentPlanListContainer
