/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, UncontrolledTooltip } from 'reactstrap'
import { ReactComponent as ViewPrices } from '../assets/images/icons/view-prices.svg'
import moment from 'moment'

const CarPricingModal = ({ carPk, prices, title, iconWidth }) => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    return (
        <div className="all-prices" id={"bos-car-price" + carPk} >
            <UncontrolledTooltip placement="bottom" target={"car-prices-" + carPk}>View All</UncontrolledTooltip>
            <a id={"car-prices-" + carPk} onClick={toggle}><ViewPrices width={iconWidth ? iconWidth : 25} fill="#303e67" /></a>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}> {title} </ModalHeader>
                <ModalBody>
                    <Row>
                        {prices && prices.edges && prices.edges.length > 0 && prices.edges.map((price, i) =>
                            <Col xs={6} className="pt-3" key={i}>
                                <div className="pricing-details">
                                    <div className="pricing-heading">{price.node.title ? price.node.title : price.node.startDate ? ("Starting On " + moment(price.node.startDate).tz("America/New_York").format(moment.HTML5_FMT.DATE)) : "--"}</div>
                                    <span className="pricing-price"> $ <strong>{price.node.price}</strong> /- {price.node.interval && price.node.interval > 1 ? price.node.interval : ""} {price.node.intervalUnit} </span>

                                    <span>$ {price.node.minimumPrice} - $ {price.node.maximumPrice}</span>
                                </div>
                            </Col>
                        )}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

//Iagami - Code upgradation React 18
export default CarPricingModal