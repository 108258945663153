import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Button, Row, Col, Label, Badge } from 'reactstrap'
import { DriverInsuranceStatus } from "./Queries"
import "./InsurancePreviewModal.css"
import gql from "graphql-tag"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import renderEnumToInt from "../Functions/renderEnumToInt"
import Loader from "../Material/Loader"
import InsuranceDetailSection from "./InsuranceDetailSection"
import withApolloClient from "../withApolloClient.js"

const OldInsurancesQuery = gql`query OldInsurancesQuery($cursor: String, $first:Int!, $driverId: ID!, $orderBy: [String]) {
    insurances: allInsuranceStatus(first:$first, driverId: $driverId, after: $cursor, orderBy: $orderBy){
        edges{
            node{
                id
                pk
                dateAdded
                surcharge
                deposit
                status
                notes
                metadata
                dateToReconsider
                broker{
                    id
                    name
                }
                createdBy {
                    id
                    username
                    firstName
                    lastName
                }
                driverremovedreasonSet {
                    edges {
                    node {
                        id
                        reason{
                            id
                            reasonName
                        }
                    }
                    }
                }
                insuranceReason{
                    id
                    title
                }
                extraDocsRequired {
                    edges {
                        node {
                            id
                            typeName
                        }
                    }
                }
                driverInsuranceType{
                    id
                    name
                    surcharge
                    deposit
                    broker{
                        id
                        name
                    }
                }
                driver{
                    id
                    pk
                    currentAgreement{
                        id
                    }
                    reservationDriver {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                    missingInsuranceDocuments{
                        id
                        typeName
                    }
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
            length
        }
    } }`

class DriverInsuranceListing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: "",
            openModal: "",
            documentUrl: "",
            openSection: ["DriverInfo", "InsuranceInfo"],
            actionSuccess: false,
            actionSuccess: false,
            loading: false,
            tab: "InfoTab",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    refetchQuery = () => {
        this.props.refetchInsuranceQuery()
        this.props.refetchQuery()
    }

    toggleSection = (section) => {
        if (this.state.openSection.includes(section)) {
            this.setState({ openSection: this.state.openSection.filter((sec) => sec !== section) })
        } else {
            this.setState({ openSection: [...this.state.openSection, section] })
        }
    }

    handleClose = () => {
        this.setState({ openModal: "" })
        this.props.handleClose()
    }

    renderMetadataVal(metadata, key) {
        let metaObject = JSON.parse(metadata.replace(/'/g, '"'))
        if (metaObject && metaObject[key]) {
            return <><Label>{key.replace("_", " ")}</Label><br /><p>{metaObject[key]}</p></>
        }
        return ""
    }

    render() {
        return (
            <Row style={{ marginTop: "10px" }}>
                <Col>
                    {this.props.loading && <Loader />}
                    {!this.props.loading && this.props.insurances && this.props.insurances.edges && this.props.insurances.edges.length > 0 ? this.props.insurances.edges.filter(insurance => insurance.node.id !== this.props.excludeInsuranceId).map((insurance, i) =>
                        <>
                            <Label className={`toggle-section-heading ${this.state.openSection.includes("InsuranceInfo" + insurance.node.pk) ? "active" : ""}`} onClick={() => this.toggleSection("InsuranceInfo" + insurance.node.pk)}><small>{i + 1}.</small> {insurance && insurance.node.dateAdded ? <DatetimeRenderer datetime={insurance.node.dateAdded} /> : "--"} - <span style={{ color: renderEnumToInt(insurance.node.status) === 1 ? "green" : renderEnumToInt(insurance.node.status) === 3 ? "orange" : "red" }}>{this.props.DriverInsuranceStatusStatus.states.find(val => val.name === insurance.node.status).description}</span> {this.state.openSection.includes("InsuranceInfo" + insurance.node.pk) ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                            {this.state.openSection.includes("InsuranceInfo" + insurance.node.pk) && <>
                                <InsuranceDetailSection
                                    insurance={insurance.node}
                                    refetchQuery={this.refetchQuery}
                                    currentUser={this.props.currentUser}
                                    handleClose={this.props.handleClose}
                                />
                            </>}
                            <hr />
                        </>
                    ) : !this.props.loading && <p>No Insurances Found</p>
                    }
                    {!this.props.loading && this.props.insurances && this.props.insurances.pageInfo && this.props.insurances.pageInfo.hasNextPage ?
                        <Row>
                            <Col>
                                <Button
                                    onClick={this.loadMoreEntries}

                                    color="link linkHoverEffect">Show More <i className={"fa fa-caret-down"}></i>
                                </Button>
                            </Col>
                        </Row> : ""
                    }
                </Col>
            </Row>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(OldInsurancesQuery, {
    options: ({ driverId }) => ({
        variables: {
            driverId,
            orderBy: ["-id"],
            first: 30
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }),
    props: ({ data: { insurances, loading, refetch, fetchMore, variables } }) => ({
        insurances,
        loading,
        refetch,
        fetchMore,
        refetchInsurancesQuery: () => {
            let currentLength = insurances && insurances.edges ? insurances.edges.length : 30
            return fetchMore({
                query: OldInsurancesQuery,
                variables: {
                    ...variables,
                    first: currentLength,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.insurances.edges
                    const pageInfo = fetchMoreResult.insurances.pageInfo
                    return {
                        insurances: {
                            edges: [...newEdges],
                            pageInfo,
                            searchTerm: previousResult.searchTerm,
                            __typename: previousResult.insurances.__typename
                        }
                    }
                }
            })
        },
        loadMoreEntries: () => {
            return fetchMore({
                query: OldInsurancesQuery,
                variables: {
                    cursor: insurances.pageInfo.endCursor,
                    ...variables
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.insurances.edges
                    const pageInfo = fetchMoreResult.insurances.pageInfo
                    return {
                        insurances: {
                            edges: [...previousResult.insurances.edges, ...newEdges],
                            pageInfo,
                            searchTerm: previousResult.searchTerm,
                            __typename: previousResult.insurances.__typename
                        }
                    }
                }
            })
        }
    })
})(withApolloClient(DriverInsuranceListing));


