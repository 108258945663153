import React, { useState, useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Button, ModalHeader, ModalBody, Modal, ModalFooter, Progress, Label } from 'reactstrap'
import DenyInsurance from '../ApplicationsNew/DenyInsurance'
import SubmitToInsuranceForm from '../ApplicationsNew/SubmitToInsuranceForm'
import "./ProcessInsuranceModal.css"


const MissingDocuments = ({ groupedMissingFields, missingInsuranceDocuments }) => {
    const [openSections, setOpenSections] = useState([])

    const toggleSection = (documentName) => {
        setOpenSections(prev =>
            prev.includes(documentName) ? prev.filter(name => name !== documentName) : [...prev, documentName]
        )
    }

    return (
        <>
            <p style={{ color: "red", fontSize: "14px", marginBottom: "10px" }}>
                Insurance cannot be processed due to missing documents or incomplete fields.
            </p>
            {Object.entries(groupedMissingFields).map(([documentName, fields], index) => {
                const isMissingDocument = missingInsuranceDocuments.some(doc => doc.typeName === documentName)
                const isOpen = openSections.includes(documentName)

                return (
                    <div key={index} className="document-section">
                        {isMissingDocument ?
                            <div key={`missing-${index}`} className="missing-document-header">
                                {documentName}<span className="missing-label">(Missing document)</span>
                            </div>
                            : <Label className="document-header" onClick={() => toggleSection(documentName)}>
                                <span>
                                    {documentName}{!isOpen && <span className="missing-label">(Missing fields)</span>}
                                </span>
                                <i className={`fa ${isOpen ? "fa-minus" : "fa-plus"}`} />
                            </Label>}
                        {!isMissingDocument && isOpen && fields.length > 0 && (
                            <div className="document-fields">
                                <ul>
                                    {fields.map((field, idx) => (
                                        <li key={idx}>{field.fieldName}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )
            })}
            {missingInsuranceDocuments
                .filter(doc => !groupedMissingFields[doc.typeName])
                .map((doc, index) => (
                    <div key={`missing-${index}`} className="document-section">
                        <div className="missing-document-header">
                            {doc.typeName}<span className="missing-label">(Missing document)</span>
                        </div>
                    </div>
                ))}
        </>
    )
}


const DriverQuery = gql`query DriverQuery($id: ID) {
    driver(id: $id) {
      id
      pk
      missingInsuranceDocuments {
        id
        typeName
      }
    }
  }
`

const DriverDocumentDataQuery = gql`
  query DriverDocumentData($driverId: ID!) {
    driverDocumentData(driverId: $driverId) {
      documentData {
        documentName
        requiredData {
          displayName
          value
        }
      }
    }
  }
`

const ProcessInsuranceModal = ({ open, handleClose, id: driverId, refetchQuery }) => {
    const [openModal, setOpenModal] = useState("")
    const [missingInsuranceDocuments, setMissingInsuranceDocuments] = useState([])
    const [missingDocumentFields, setMissingDocumentFields] = useState([])
    const isMissingInsuranceDocuments = missingInsuranceDocuments.length > 0
    const isMissingFields = missingDocumentFields.length > 0
    const { data: driverData, loading: driverDataLoading } = useQuery(DriverQuery, { variables: { id: driverId }, })
    const { data, loading: driverDocumentDataLoading } = useQuery(DriverDocumentDataQuery, { variables: { driverId: driverId }, })
    const isLoading = driverDataLoading || driverDocumentDataLoading

    useEffect(() => {
        if (driverData?.driver?.missingInsuranceDocuments) {
            setMissingInsuranceDocuments(driverData.driver.missingInsuranceDocuments)
        }

        if (data?.driverDocumentData?.documentData) {
            const missingFields = data?.driverDocumentData?.documentData.flatMap(doc =>
                doc.requiredData
                    .filter(field => !field.value || field.value === "")
                    .map(field => ({
                        documentName: doc.documentName,
                        fieldName: field.displayName,
                    }))
            )
            setMissingDocumentFields(missingFields)
        }
    }, [data, driverData])

    const handleModalClose = () => {
        setOpenModal("")
        handleClose?.()
    }

    const toggleModal = (modalName) => {
        setOpenModal((prev) => (prev === modalName ? "" : modalName))
    }

    const groupedMissingFields = useMemo(() => {
        return missingDocumentFields.reduce((acc, field) => {
            if (!acc[field.documentName]) {
                acc[field.documentName] = []
            }
            acc[field.documentName].push(field)
            return acc
        }, {})
    }, [missingDocumentFields])

    return (
        <Modal isOpen={open} id={"process-insurance-modal-popover"}>
            {openModal === "DenyInsurance" && (
                <DenyInsurance
                    refetchDriverQuery={refetchQuery}
                    handleClose={handleModalClose}
                    open={openModal === "DenyInsurance"}
                    driverId={driverId}
                />
            )}
            {openModal === "SubmitToInsuranceForm" && (
                <SubmitToInsuranceForm
                    refetchDriverQuery={refetchQuery}
                    handleClose={handleModalClose}
                    open={openModal === "SubmitToInsuranceForm"}
                    driverId={driverId}
                />
            )}
            <ModalHeader id={"process-insurance-modal-popover-header"}>Process Insurance</ModalHeader>
            <ModalBody id={"process-insurance-modal-popover-body"}>
                {(isMissingFields || isMissingInsuranceDocuments) && !isLoading && (
                    <MissingDocuments
                        groupedMissingFields={groupedMissingFields}
                        missingInsuranceDocuments={missingInsuranceDocuments}
                    />
                )}
                <div className="process-insurance-modal-btn-container">
                    <Button
                        size="sm"
                        onClick={() => toggleModal("DenyInsurance")}
                        className={"insurance-primary-btn"}
                        style={{ backgroundColor: "red" }}
                    >
                        Deny Insurance
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        size="sm"
                        onClick={() => toggleModal("SubmitToInsuranceForm")}
                        className={"insurance-primary-btn"}
                        disabled={isMissingInsuranceDocuments || isMissingFields || isLoading}
                    >
                        Request Approval
                    </Button>
                </div>
                {isLoading && <Progress animated color="info" value="100" />}
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClose} className={"process-insurance-modal-secondary-btn"}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ProcessInsuranceModal
