import { debounce } from "lodash"
import flattenObject from "./FlattenObject"

// Iagami - Code upgradation React 18
const updateFilters = debounce((filters, configurations, navigate, location, setFilterValues) => {
    let visibleFilters = configurations.filter(item => item.showFilter).map(item => item.name)
    let newFilterValues = {}

    Object.keys(filters).forEach(key => {
        let value = filters[key]
        if (visibleFilters.includes(key) || key === "searchTerm") {
            if (typeof value === "string") {
                if (/^\d{10}$/.test(value)) {
                    value = `+1${value}`
                }
                if (key === "searchTerm" && /^\d+$/.test(value)) {
                    value = `+1${value.replace("+1", "")}`
                }
                else if (isNaN(value)) {
                    value = value.toLowerCase()
                }
            }

            newFilterValues[key] = value
        }
    })

    newFilterValues = flattenObject(newFilterValues)
    const queryString = new URLSearchParams(newFilterValues).toString()
    navigate({
        pathname: location.pathname,
        hash: location.hash,
        search: queryString,
    })
    setFilterValues({ ...filters })

}, 1000)

export default updateFilters
