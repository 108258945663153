import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'//Iagami - Code upgradation React 18
import Loader from '../../Material/Loader'
import OverviewTab from './BranchDetailTabs/OverviewTab'
import SettingTab from './BranchDetailTabs/SettingTab'
import MemberTab from './BranchDetailTabs/MemberTab'
import HistoryTab from './BranchDetailTabs/HistoryTab'
import FinancialAccountsTab from './BranchDetailTabs/FinancialAccountsTab'
import RestrictedSection from '../../Components/RestrictedSection'
import withApolloClient from '../../withApolloClient'//Iagami - Code upgradation React 18
import './BranchListContainer.css'
import { Col, Label, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import Container from "reactstrap/lib/Container"
import './ObjectDetail.css'
import BusinessHoursTab from "./BranchDetailTabs/BusinessHoursTab"

// GraphQL Query
const BranchQuery = gql`
  query Branch($id: ID!) {
    branch(id: $id) {
      id
      name
      customId
      isActive
      city
      stateCode
      country
      postalCode
    }
}`

const AllBusinessHoursQuery = gql`query AllBusinessHours($branchId: ID!){
    allBusinessHours(branchId: $branchId){
        edges {
            node {
                id
            }
        }
    }
}`

const BRANCH_DETAIL_TABS = [
    { id: '#overview', name: 'OVERVIEW' },
    { id: '#settings', name: 'SETTINGS' },
    { id: '#members', name: 'MEMBERS' },
    { id: '#history', name: 'HISTORY' },
    { id: '#financialAccounts', name: 'FINANCIAL ACCOUNTS' },
    { id: "#businesshours", name: "BUSINESS HOURS" },
]

class BranchDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'overview',
            renderedTabPanes: [],
        }
    }

    toggleTab = (tabId) => {
        if (!this.state.renderedTabPanes.includes(tabId)) {
            this.setState({ renderedTabPanes: [...this.state.renderedTabPanes, tabId] })
        }
        this.setState({ activeTab: tabId })
    };

    componentDidMount() {
        let defaultTab = BRANCH_DETAIL_TABS[0].id
        if (window.location.hash && BRANCH_DETAIL_TABS.map((item) => item.id).includes(window.location.hash)) {
            this.setState({ activeTab: window.location.hash, renderedTabPanes: [window.location.hash] })
        } else {
            window.location.hash = defaultTab
            this.setState({ activeTab: defaultTab, renderedTabPanes: [defaultTab] })
        }
    }

    componentDidUpdate(_, prevState) {
        if (this.state.activeTab !== prevState.activeTab) {
            window.location.hash = this.state.activeTab
        }
    }

    render() {
        const { loading, branch } = this.props

        if (loading) {
            return <Loader />
        }

        if (!branch) {
            return <div>Branch not found.</div>
        }

        return (
            <Container fluid className="bos-object-detail-container">
                <Row className="bos-object-detail-header">
                    <Col xs="1">
                        <span className="box-object-icon">
                            <i className="fa fa-2x fa-code-fork" aria-hidden="true"></i>
                        </span>
                    </Col>
                    <Col xs={5} className="bos-object-title-wrapper">
                        <Label>{branch.name}</Label>
                        &nbsp;&nbsp;&nbsp;
                        {branch.isActive ? (
                            <i className="fa fa-check-circle fa-2x text-success" aria-hidden="true" />
                        ) : (
                            <i className="fa fa-times-circle fa-2x text-danger" aria-hidden="true" />
                        )}
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper">
                        <Label>{branch.customId}</Label>
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper text-right">
                        <Label>
                            {branch.city}, {branch.stateCode}, {branch.country} {branch.postalCode}
                        </Label>
                    </Col>
                </Row>
                <Row className="bos-object-detail-body">
                    <Nav tabs>
                        {BRANCH_DETAIL_TABS.map((item) => (
                            <NavItem key={item.id} className={this.state.activeTab === item.id ? 'active' : 'inactive'}>
                                <NavLink
                                    className={this.state.activeTab === item.id ? 'active' : 'inactive'}
                                    onClick={() => this.toggleTab(item.id)}
                                >
                                    {/* {item.name} */}
                                    {item.name === "BUSINESS HOURS" && this.props.allBusinessHours && this.props.allBusinessHours.edges && this.props.allBusinessHours.edges.length === 0 ?
                                        <>
                                            {item.name}&nbsp;&nbsp;<i id={"businessHours"} style={{ color: "red" }} class="fa fa-info-circle" aria-hidden="true"></i>
                                            <UncontrolledTooltip placement="top" target="businessHours">Missing Business Hours</UncontrolledTooltip>
                                        </> : item.name
                                    }
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <div className="bos-object-nav-content">
                        <TabContent activeTab={this.state.activeTab}>
                            {this.state.renderedTabPanes.includes('#overview') && (
                                <TabPane tabId="#overview">
                                    <RestrictedSection requiredPermission="view_branch">
                                        <OverviewTab branchId={branch.id} />
                                    </RestrictedSection>
                                </TabPane>
                            )}
                            {this.state.renderedTabPanes.includes('#settings') && (
                                <TabPane tabId="#settings">
                                    <SettingTab branchId={branch.id} />
                                </TabPane>
                            )}
                            {this.state.renderedTabPanes.includes('#members') && (
                                <TabPane tabId="#members">
                                    <RestrictedSection requiredPermission="view_member">
                                        <MemberTab branchId={branch.id} />
                                    </RestrictedSection>
                                </TabPane>
                            )}
                            {this.state.renderedTabPanes.includes('#history') && (
                                <TabPane tabId="#history">
                                    <HistoryTab branchId={branch.id} />
                                </TabPane>
                            )}
                            {this.state.renderedTabPanes.includes('#financialAccounts') && (
                                <TabPane tabId="#financialAccounts">
                                    <RestrictedSection requiredPermission="view_financialaccount">
                                        <FinancialAccountsTab branchId={branch.id} />
                                    </RestrictedSection>
                                </TabPane>
                            )}
                            {this.state.rendererdTabPanes && this.state.rendererdTabPanes.includes("#businesshours") && <TabPane tabId="#businesshours">
                                <BusinessHoursTab branchId={this.props.branch.id} />
                            </TabPane>}
                        </TabContent>
                    </div>
                </Row>
            </Container>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(BranchQuery, {
    options: (props) => ({
        variables: { id: props.params.id },
        fetchPolicy: 'network-only',
    }),
    props: ({ data: { branch, loading, refetch, variables } }) => ({
        branch,
        loading,
        variables,
        refetchBranchQuery: () => {
            return refetch({
                query: BranchQuery,
                variables: { ...variables },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { branch: fetchMoreResult.branch };
                },
            });
        },
    }),
})(
    graphql(AllBusinessHoursQuery, {
        options: (props) => ({
            variables: { branchId: props.params.id },
            fetchPolicy: 'network-only',
        }),
        props: ({ data: { allBusinessHours, loading, variables } }) => ({
            allBusinessHours,
            businessHoursLoading: loading,
            variables,
        }),
    })(withApolloClient(BranchDetail))
);

