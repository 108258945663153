import React, { Component } from "react"
import { Progress } from "reactstrap"
import "./PrimaryListingContainer.css"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

class PrimaryListingContainer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (<div className="vgm-primary-listing-container">
            <div className="vgm-primary-listing-container__body">
                {this.props.children}
                {this.props.listLoading && <Progress style={{ marginTop: "10px", maxHeight: "5px" }} animated color="info" value="100" />}
            </div>
            {!this.props.listLoading && <div className="vgm-primary-listing-container__footer">
                {this.props.refetchListQuery && <a className="float-left" onClick={this.props.refetchListQuery}><i className="fa fa-refresh"></i>&nbsp;&nbsp;RELOAD</a>}
                {this.props.pageInfo && this.props.pageInfo.length ? <span className="float-center">Showing {this.props.totalFetchedRows} out of {this.props.pageInfo.length} {this.props.pageInfo.length > 1 ? "entries" : "entry"}.</span> : ""}
                {this.props.loadMoreEntries && <a className="float-right" onClick={this.props.loadMoreEntries}><i className="fa fa-angle-double-down"></i>&nbsp;&nbsp;LOAD MORE</a>}
            </div>}
        </div>)
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(PrimaryListingContainer)