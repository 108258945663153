import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress
} from 'reactstrap'
import Alert from 'reactstrap/lib/Alert'
import toTitleCase from "../Functions/toTitleCase"
import { RepsQuery } from "../Functions/querys"
import DateTimePicker from "../Material/DateTimePicker"
import { AgreementTypeDepositSchemes } from '../Reservations/Modals/Queries'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
import { getEnumDescription } from '../Functions/processEnumDescription'


const UpdateReservation = gql`
mutation updateReservationMutation($input: UpdateReservationMutationInput!){
    updateReservation(input:$input){
        ok
        errors{
            messages
        }
    }
} `
const AllCarLocations = gql`{
    allCarLocations(isPickupLocation:true){
      edges{
        node{
          name
          isDefaultPickupLocation
          branch{
          id
          name
          }
        }
      }
    }
}`
const AllAgreementTypes = gql`{
    allAgreementTypes(isActive:true){
      edges{
        node{
            id
            name
            forTlc
            branch{
                id
                name
            }
        }
      }
    }
}`
const AllCarModelsQuery = gql`query AllCarModelsQuery($orderBy: [String], $carStages: [Int], $hasReturnDate: Boolean, $hasReservation: Boolean){
    allCarModels(orderBy: $orderBy, carStages: $carStages, hasReturnDate: $hasReturnDate, hasReservation: $hasReservation ){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`
const UpdateReservationPrice = gql`mutation updateReservationPrice($input: UpdateReservationPriceMutationInput!){
    updateReservationPrice(input:$input){
        ok
        errors{
            field
            messages
        }
        clientMutationId
    }
}`

const pickupTypes = ["Accident Switch", "Mechanical Switch", "Personal Switch",
    "Insurance/TLC Switch", "Split Switch"]

class UpdateReservationInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: "",
            reservation: props.reservation,
            reservationPlan: props.reservationPlan,
            input: {},
            loading: false,
            disabledMessage: "",
        }
    }

    updateReservation = () => {
        if (this.props.reservationPlan) {
            this.setState({ loading: true })
            let input = this.state.input
            this.props.apolloClient.mutate({
                mutation: UpdateReservationPrice,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateReservationPrice && result.data.updateReservationPrice.ok) {
                    this.props.refetchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, errorMessage: "" })
                } else {
                    let errorMessage = result.data && result.data.updateReservationPrice && result.data.updateReservationPrice.errors && result.data.updateReservationPrice.errors[0] && result.data.updateReservationPrice.errors[0].messages ?
                        result.data.updateReservationPrice.errors[0].messages.join(". ") : "An error has occured. Please try again or contact admin."
                    this.setState({ loading: false, errorMessage: errorMessage })
                }
            }).catch((err) => {
                this.setState({ loading: false, errorMessage: "An error has occured. Please try again or contact admin." })
            })
        } else {
            if (this.state.input.reservationId && ((this.props.name === 'pickupDate' && this.state.input[this.props.name] !== undefined) || (this.props.name !== 'pickupDate' && this.state.input[this.props.name]))) {
                this.setState({ loading: true })
                let input = this.state.input
                this.props.apolloClient.mutate({
                    mutation: UpdateReservation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateReservation && result.data.updateReservation.ok) {
                        this.props.refetchQuery()
                        this.props.handleClose()
                        this.setState({ input: {}, loading: false, errorMessage: "" })
                    } else {
                        let errorMessage = result.data && result.data.updateReservation && result.data.updateReservation.errors && result.data.updateReservation.errors[0] && result.data.updateReservation.errors[0].messages ?
                            result.data.updateReservation.errors[0].messages.join(". ") : "An error has occured. Please try again or contact admin."
                        this.setState({ loading: false, errorMessage: errorMessage })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, errorMessage: "An error has occured. Please try again or contact admin." })
                })
            } else {
                this.setState({ errorMessage: "A value is required." })
            }
        }
    }

    updateInput = (e) => {
        if (this.state.reservation) {
            let { name, value, min, max, type } = e.target
            if (type === "number") {
                value = Number(value)
            }
            if (type === "number" && (min != undefined || max != undefined)) {
                if (min != undefined && Number(value) < Number(min)) {
                    this.setState({ disabledMessage: "Please make sure " + name + " is greater than " + Number(min) })
                } else if (max != undefined && Number(value) > Number(max)) {
                    this.setState({ disabledMessage: "Please make sure " + name + " is less than " + Number(max) })
                } else {
                    this.setState({ disabledMessage: "" })
                }
            }
            let input = {
                reservationId: this.state.reservation.id,
                [name]: value
            }
            this.setState({ input: input })
        } else if (this.state.reservationPlan) {
            let { name, value, min, max, type } = e.target

            let input
            if (this.props.name.includes("selectedPlanStartDate"))
                input = {
                    reservationPrice: this.state.reservationPlan.id,
                    startDate: value
                }
            else if (this.props.name.includes("selectedPlanBasePrice")) {
                if (type === "number" && (min != undefined || max != undefined)) {
                    if (min != undefined && Number(value) < Number(min)) {
                        this.setState({ disabledMessage: "Plan base price should be greater than " + Number(min) })
                    } else if (max != undefined && Number(max) > Number(value) && Number(value) > Number(max)) {
                        this.setState({ disabledMessage: "Plan base price should be less than " + Number(max) })
                    } else {
                        this.setState({ disabledMessage: "" })
                    }
                }
                input = {
                    reservationPrice: this.state.reservationPlan.id,
                    price: value
                }
            }
            this.setState({ input: input })
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.reservation !== prevProps.reservation) {
            let reservation = this.props.reservation
            this.setState({ reservation: reservation })
        }
        if (this.props.reservationPlan !== prevProps.reservationPlan) {
            let reservationPlan = this.props.reservationPlan
            this.setState({ reservationPlan: reservationPlan })
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }


    render() {
        let filteredCarLocation = this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 ? this.props.allCarLocations.edges : []
        if (filteredCarLocation.length > 0 && this.props.name === "pickUpLocation")
            filteredCarLocation = filteredCarLocation.filter(pickupLocation => this.props.reservation.driver.branch.id == pickupLocation.node.branch.id)
        return (
            <div >
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose} >
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody>
                        {this.state.errorMessage && <Alert color="danger">{this.state.errorMessage}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.name === "pickUpLocation" ?
                                <Input type="select" style={{ padding: "5px", display: "block", marginBottom: "10px", borderRadius: "6px" }} name="pickUpLocation" value={this.state.value} id="pickUpLocation" onChange={this.updateInput}>
                                    <option value={null}>Choose Pickup Location</option>
                                    {filteredCarLocation && filteredCarLocation.map((carLocation, i) =>
                                        <option key={i} value={carLocation.node.name}>{carLocation.node.name}</option>
                                    )}
                                </Input>
                                : this.props.name === "agreementType" ?
                                    <Input type="select" style={{ padding: "5px", display: "block", marginBottom: "10px", borderRadius: "6px", width: "100%" }} name="agreementType" defaultValue={this.props.value} id="agreementType" onChange={this.updateInput}>
                                        <option value={null} disabled selected hidden>Select Agreement Type</option>
                                        {this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0 &&
                                            this.props.allAgreementTypes.edges.filter(agreementType => this.state.reservation.driver.branch.id === agreementType.node.branch.id).map((agreementType, i) =>
                                                <option key={i} value={agreementType.node.name}>{agreementType.node.name}</option>
                                            )
                                        }
                                    </Input>
                                    : this.props.name === "depositScheme" ?
                                        <Input type="select" style={{ padding: "5px", display: "block", marginBottom: "10px", borderRadius: "6px", width: "100%" }} name="depositScheme" defaultValue={this.props.value} id="depositScheme" onChange={this.updateInput}>
                                            <option value={null} disabled selected hidden>Select Deposit Scheme</option>
                                            {this.props.agreementTypeDepositSchemes && this.props.agreementTypeDepositSchemes.enumValues && getEnumDescription(this.props.agreementTypeDepositSchemes.enumValues).map((item, i) =>
                                                <option key={i} value={item.description}>{item.description}</option>
                                            )
                                            }
                                        </Input>
                                        : this.props.name === "pickupType" ?
                                            <Input type="select" name="pickupType" defaultValue={pickupTypes && pickupTypes.length > 0 && pickupTypes.includes(this.props.value) ? this.props.value : ""} id="pickupType" onChange={this.updateInput}>
                                                <option disabled={true} value="">Select Pickup Type</option>
                                                {pickupTypes && pickupTypes.length > 0 && pickupTypes.map((pickupType, i) =>
                                                    <option key={i} value={pickupType}>{pickupType}</option>
                                                )}
                                            </Input>
                                            : this.props.name === "preferredCar" ?
                                                <Input type="select" style={{ padding: "5px", display: "block", marginBottom: "10px", borderRadius: "6px" }} name="preferredCar" defaultValue={this.props.value} id="pickUpLocation" onChange={this.updateInput}>
                                                    <option value={null} disabled selected hidden>Select Desired Car Type</option>
                                                    {this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.length > 0 && this.props.allCarModels.edges.map((carModel, i) => {
                                                        let modelValue = toTitleCase(carModel.node.make) + " " + carModel.node.name + (carModel.node.series ? " | " + carModel.node.series : "") + (carModel.node.groupType ? " | " + carModel.node.groupType.name : " | No Group Selected")
                                                        return <option key={i} value={modelValue}>{modelValue}</option>
                                                    })}
                                                </Input>
                                                // :this.props.name === "typeOfDiscountId"?
                                                //     <Input type="select" name="typeOfDiscountId" defaultValue={this.props.value} id="typeOfDiscountId" onChange={this.updateInput}>
                                                //         <option value="">Choose a discount type</option>
                                                //         {this.props.allSupplementalDiscountTypes && this.props.allSupplementalDiscountTypes.edges && this.props.allSupplementalDiscountTypes.edges.map((discountType,i) =>
                                                //             <option key={i} value={discountType.node.id}>{discountType.node.name}</option>
                                                //         )}
                                                //     </Input>
                                                : this.props.name === "representative" ?
                                                    <Input type="select" name="representative" defaultValue={this.props.value} id="rep" onChange={this.updateInput}>
                                                        <option disabled={true} value="">Select Representative</option>
                                                        {this.props.allReps && this.props.allReps.edges.length > 0 && this.props.allReps.edges.map((rep, i) =>
                                                            <option key={i} value={rep.node.id}>{rep.node ? rep.node.firstName ? rep.node.firstName + " " + rep.node.lastName : rep.node.username : "--"}</option>
                                                        )}
                                                    </Input>
                                                    // <Select
                                                    //     className="bos-custom-select" classNamePrefix="bos-select"
                                                    //     options={this.state.allReps}
                                                    //     defaultValue={this.props.defaultValue}
                                                    //     onChange={(item) => this.setState(this.props.name, item.value)}
                                                    // />
                                                    : this.props.name.includes("selectedPlanStartDate") ?
                                                        <Input type={this.props.type} min={this.props.min} max={this.props.max} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} />
                                                        : this.props.name.includes("selectedPlanBasePrice") ?
                                                            <Input type={this.props.type} min={this.props.min} max={this.props.max} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} />
                                                            : ["datetime-local", "datetime"].find(type => type === this.props.type) ?
                                                                <DateTimePicker type="datetime-local" min={this.props.min} max={this.props.max} name={this.props.name} id={this.props.name} value={this.props.value} placeholder={this.props.title} updateInput={(name, value) => this.setState({ input: { reservationId: this.state.reservation.id, [name]: value } })} setError={(errorMessage) => this.setState({ errorMessage })} />
                                                                : <Input type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200} min={this.props.min} max={this.props.max} name={this.props.name} id={this.props.name} defaultValue={this.props.value} placeholder={this.props.title} onChange={this.updateInput} />
                            }
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> : <Button type="button"
                            disabled={this.state.disabledMessage ? true : false} size="sm" onClick={this.updateReservation}>{this.state.disabledMessage ? this.state.disabledMessage : "Submit"}</Button>}
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AgreementTypeDepositSchemes, {
    props: ({ data: { __type: agreementTypeDepositSchemes } }) => ({
        agreementTypeDepositSchemes,
    }),
})(
    graphql(AllCarLocations, {
        props: ({ data: { allCarLocations } }) => ({
            allCarLocations,
        }),
    })(
        graphql(AllAgreementTypes, {
            props: ({ data: { allAgreementTypes } }) => ({
                allAgreementTypes,
            }),
        })(
            graphql(AllCarModelsQuery, {
                options: () => ({
                    variables: {
                        carStages: [8, 9, 10, 11],
                        hasReturnDate: true,
                        hasReservation: false,
                        orderBy: ["-make"],
                    },
                }),
                props: ({ data: { loading, allCarModels } }) => ({
                    allCarModelsLoading: loading,
                    allCarModels,
                }),
            })(
                graphql(RepsQuery, {
                    options: ({ groups }) => ({
                        variables: { groups },
                    }),
                    props: ({ data: { allReps } }) => ({
                        allReps,
                    }),
                })(
                    withApolloClient(UpdateReservationInfo)
                )
            )
        )
    )
);


