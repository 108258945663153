import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Input, Label, Progress } from 'reactstrap'
import { updateTracker } from "./CarTrackerMutations"
import { GetConfigurationSettings } from '../Functions/querys'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'


const repoReasons = gql`
query repoReasons{
    globalSettings (settingsName_Icontains:"repo_reasons") {
        edges {
          node {
            settingsName
            settingsValue
          }
        }
    }
}`


class CarTrackerModal extends Component {
    constructor(props) {
        super(props)
        let location = {
            address: "", longitude: "", latitude: "", speed: "", date: ""
        }
        this.state = {
            modalOpen: false,
            repoReason: "",
            keysInCar: "",
            changingStatus: false,
            commandSent: false,
            repoTriggerSuccess: false,
            location: location,
            showTriggerError: false,
            loading: false,
            openModal: "",
            errorMsg: ""
        }
    }

    updateInput = (e) => {
        let currentState = this.state
        let name = e.target.name
        let value = e.target.value
        this.setState({ ...currentState, [name]: value })
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    updateTracker = (newvals) => {
        if (this.props.car.id) {
            try {
                this.setState({ showTriggerError: false, loading: true })
                let input = { carId: this.props.car.id, ...newvals, trackerCustomId: this.props.trackerId }
                if (newvals.command === "Repo") {
                    input.repoReason = this.state.repoReason
                    input.keysInCar = this.state.keysInCar
                }
                this.props.update(input).then(result => {
                    if (newvals.command !== "Repo") {
                        this.setState({ changingStatus: false })
                    }
                    if (result.data && result.data.changeTrackerStatus && result.data.changeTrackerStatus.ok) {
                        this.setState({ showTriggerError: false, commandSent: true, openModal: "", loading: false })
                    } else {
                        this.setState({ showTriggerError: true, loading: false, errorMsg: result.data.changeTrackerStatus.errors[0]['messages'][0] })
                    }
                }).catch(error => {
                    this.setState({ loading: false, commandSent: false })
                })
            } catch (error) {
                this.setState({ loading: false, commandSent: false })
            }
        }
    }

    updateTrackerIP = () => {
        if (this.props.car.id) {
            let input = {
                carId: this.props.car.id,
                command: 'Update Car Tracker'
            }
            this.setState({ loading: true })
            this.props.update(input).then(result => {
                if (result.data && result.data.changeTrackerStatus && result.data.changeTrackerStatus.ok) {
                    this.setState({ loading: false, showTriggerError: false })
                } else {
                    this.setState({ loading: false, showTriggerError: true })
                }
            }).catch(error => {
                this.setState({ loading: false, showTriggerError: true })
            })
        }
    }
    componentDidMount() {
        if (this.props.command === "Update Car Tracker") {
            this.updateTrackerIP(this.props.car.id)
        }
    }
    render() {
        let repoReasons = null
        try {
            repoReasons = this.props.globalSettings &&
                this.props.globalSettings && this.props.globalSettings.edges && this.props.globalSettings.edges.length > 0 ?
                (this.props.globalSettings.edges[0].node.settingsValue) : null
        } catch (err) { }
        if (!repoReasons) {
            repoReasons = ["Driver not responsive", "Owes too much", "COVID-19 Sick/Quarantine"]
        }

        return (
            <div>
                <Modal isOpen={this.props.open}>
                    <ModalHeader>{this.props.car ? "Tracker Details for car " + this.props.car.pk : "Tracker Info"}</ModalHeader>
                    <ModalBody>
                        {this.state.loading && <Progress animated color="info" value="100" />}
                        {this.state.commandSent && <Alert color="success">Command sent successfully!</Alert>}
                        {this.state.showTriggerError && <Alert color="warning">Error. {this.state.errorMsg}. Please Contact Admin!</Alert>}
                        {this.state.repoTriggerSuccess && <Alert color="success">Repo trigger sent successfully.</Alert>}
                        {this.props.command === "Repo" && !this.state.repoTriggerSuccess &&
                            <Col xs={12}>
                                <br />
                                <FormGroup>
                                    <Label for="repoReason">Please choose repo reason</Label>
                                    <Input type="select" name="repoReason" id="repoReason" onChange={this.updateInput} placeholder="Choose reason">
                                        <option value="" selected={true} disabled={true}>Choose Reason</option>
                                        {repoReasons && repoReasons.map((reason, i) =>
                                            <option key={i} value={reason}>{reason}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                                <br />
                                <FormGroup>
                                    <Label for="keysInCar">Did the driver leave the keys in the car?</Label>
                                    <Input type="select" name="keysInCar" id="keysInCar" onChange={this.updateInput} placeholder="Did the driver leave the keys in the car?">
                                        <option value="" selected={true} disabled={true}>Choose One</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </Input>
                                </FormGroup>
                                <Button outline color="secondary" size="small" disabled={!this.state.repoReason || !this.state.keysInCar} onClick={() => this.updateTracker({ command: "Repo" })}>Confirm Repo</Button>&nbsp;&nbsp;&nbsp;
                            </Col>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col xs={12}>
                                <Button outline color="secondary" onClick={this.props.handleClose}>Close</Button>&nbsp;&nbsp;
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(updateTracker, {
    props: ({ mutate }) => ({
        update: (input) => mutate({ variables: { input } }),
    }),
})(
    graphql(GetConfigurationSettings, {
        options: () => ({
            variables: { name: "SERVER_ENVIRONMENT" },
        }),
        props: ({ data: { getConfigurationSettings } }) => ({
            environment: getConfigurationSettings,
        }),
    })(
        graphql(repoReasons, {
            props: ({ data: { globalSettings } }) => ({
                globalSettings,
            }),
        })(withApolloClient(CarTrackerModal))
    )
);

