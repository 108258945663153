import React from "react"
import gql from "graphql-tag"
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import Loader from "./Loader"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

const CurrentUserQuery = gql`
  query CurrentUserQuery {
    currentUser {
      id
      member {
        id
      }
    }
  }
`
const memberTablePreference = gql`
  query memberTablePreference($memberId: ID!, $tableId: String!) {
    memberTablePreference(memberId: $memberId, tableId: $tableId)
  }
`

const ManagePreferenceHOC = (tableId, tableHeaders) => {
    return WrappedComponent => {
        class GraphQLContainer extends React.Component {
            constructor(props) {
                super(props)
                this.state = {
                    columnConfig: tableHeaders,
                }
            }
            componentDidUpdate(prevProps) {
                if (!this.props.loading && (prevProps.memberTablePreference !== this.props.memberTablePreference || this.state.columnConfig.filter(col => col.visible).length !== this.props.memberTablePreference.length)) {
                    this.processPreferences(this.props.memberTablePreference)
                }
            }
            processPreferences(preferenceData) {
                if (preferenceData && preferenceData.length > 0) {
                    const visibleIds = new Set(preferenceData)
                    const updatedColumns = tableHeaders
                        .map(col => ({
                            ...col,
                            visible: visibleIds.has(col.id),
                            order: preferenceData.indexOf(col.id) >= 0 ? preferenceData.indexOf(col.id) : tableHeaders.length,
                        })).sort((a, b) => a.order - b.order)

                    if (updatedColumns !== this.state.columnConfig) {
                        this.setState({ columnConfig: updatedColumns })
                    }
                }
            }

            render() {
                const { loading, currentUser, ...rest } = this.props
                const { columnConfig } = this.state
                if (loading || !currentUser) return <Loader />
                return (
                    <WrappedComponent {...rest} columnConfig={columnConfig} refetchPreferences={this.props.refetchPreference} currentUser={this.props.currentUser} />
                )
            }
        }
        //Iagami - Code upgradation React 18
        return graphql(CurrentUserQuery, {
            props: ({ data: { currentUser, loading } }) => ({
                currentUser,
                loading,
            }),
        })(
            graphql(memberTablePreference, {
                options: (props) => {
                    const memberId = props.currentUser && props.currentUser.member && props.currentUser.member.id
                    return {
                        variables: {
                            memberId: memberId,
                            tableId: typeof tableId === "function" ? tableId(props) : tableId,
                        },
                        fetchPolicy: "cache-and-network",
                    }
                },
                skip: (props) => {
                    const memberId = props.currentUser && props.currentUser.member && props.currentUser.member.id
                    return !memberId
                },
                props: ({ data: { memberTablePreference, loading, error, refetch } }) => ({
                    memberTablePreference,
                    loading,
                    error,
                    refetchPreference: refetch,
                }),
            })(GraphQLContainer)
        )
    }
}

export default ManagePreferenceHOC
