//Iagami - Code upgradation React 18
//----------------------------------
export function processCarReturnStates(carReturnStates) {
    return {
        ...carReturnStates,
        states: carReturnStates.states.map(state => {
            let value = state.name
            if (!value) return { ...state, description: "" }
            if (value.includes("_")) {
                value = value.split("_").slice(1).join(" ")
            }
            value = value.replace(/\d+/g, '')
            value = value.trim()
            value = value
                .split(" ")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(" ")

            return { ...state, description: value }
        }),
    }
}

export function getEnumDescription(query) {
    return query.map(item => {
        let value = item.description || item.name
        if (!value) return ""
        value = value.toLowerCase()
        if (value.includes('_')) {
            value = value.replace(/_/g, ' ')
        }
        value = value.replace(/\b\w/g, char => char.toUpperCase())
        return { ...item, description: value }
    })
}

export function getDescription(query) {
    let value = query.description || query.name
    value = value.toLowerCase()
    if (value.includes('_')) {
        value = value.replace(/_/g, ' ')
    }
    value = value.replace(/\b\w/g, char => char.toUpperCase())
    return value
}

export function statusEnumDescriptions(data) {
    return data.map(item => {
        let description = item.description || item.name || ""
        if (!description) return { ...item, description: "" }
        description = description.toLowerCase().replace(/\d+/g, '').replace(/_+/g, ' ').trim().replace(/\b\w/g, char => char.toUpperCase())
        return { ...item, description }
    })
}