import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import { Link } from 'react-router-dom'
import { Badge, Button, Col, Row, Label, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap'
import { EnumQuery } from "../../../../../Functions/querys"
import Loader from "../../../../../Material/Loader"
import "react-awesome-lightbox/build/style.css"
import toTitleCase from "../../../../../Functions/toTitleCase"
import renderEnumToInt from "../../../../../Functions/renderEnumToInt"
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import ReservationModalNew from "../../../../../Reservations/Modals/ReservationModalNew"
import gql from 'graphql-tag'
import PickupModal from "../../../../../Reservations/Modals/PickupModal"
import withApolloClient from "../../../../../withApolloClient"
const ReservationDetails = gql`query Reservation($id: ID!){
    reservation(id:$id){  
        id
        pk
        status
        notes
        surcharge
        pickupType
        pickupDate
        dateAdded
        depositScheme
        deposit
        depositAmount
        preferredCar
        priceRange
        cancelReason
        status
        isSplit
        totalLeaseAmount
        leaseTermInMonths
        rep{
            id
            username
            firstName
            lastName
        }
        agreementType {
            id
            name
        }
        driver{
            id
            isTlc
            phone
            tlcLicense
            dmvLicense
            name
            currentAgreement {
                id
            }
            missingRentalDocuments {
                id
                typeName
            }
        }
        branch {
            id
            name
        }
        history {
            date
            user
            comment
        }
        car{
            id
            pk
            year
            vin
            color
            stage
            group
            dailymileageSet {
                edges {
                  node {
                    id
                    dateAdded
                    dateModified
                    mileage
                  }
                }
              }
            branch{
                id
                name
            }
            currentAgreement {
                id
            }
            unresolvedCarIssues {
                id
                canPickup
            }
            activePolicy{
                id
                insurancePolicy{
                    id
                    policyNumber
                    broker{
                        id
                        name
                    }
                }
            }
            plate{
                id
                dmvPlate
                fhv
            }
            carModel{
                id
                make
                name
                series
                groupType{
                    id
                    name
                }
            }
        }
        reservationpromoSet{
            edges {
              node {
                id
                dateAdded
                interval
                intervalUnit
                currency { code symbol }
                title
                startDate
                expirationDate
                isPercentage
                promoPrice
                minimumPromoPrice
                maximumPromoPrice
              }
            }
        }
        reservationpriceSet{
            edges{
                node{
                    id
                    price
                    minimumPrice
                    maximumPrice
                    interval
                    intervalUnit
                    currency { code symbol }
                    startDate
                    isActive
                    tierType
                    dateAdded
                    dateModified
                    tiers{
                        edges{
                            node{
                                id
                                price
                                interval
                                intervalUnit
                                currency { code symbol }
                                upperLimit
                                upperLimitUnit
                            }
                        }
                    }
                    
                    
                }
            }
        }
         
    }
}
`

const historyTable = [
    { id: "date", name: "Date" },
    { id: "detail", name: "Details" },
    { id: "createdBy", name: "Created By" },
]
const PRICING_TABLE_HEADER = [
    { id: "date_added", name: "Date Added" },
    { id: "price", name: "Default Value" },
    { id: "min_price", name: "Min Value" },
    { id: "max_price", name: "Max Value" },
    { id: "startDate", name: "Start Date" },
    { id: "status", name: "Status" },
]


class DriverReservationDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            reservationDetailSubsection: "Reservation Info",
            carDetailSubsection: "carDetailView",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    render() {
        let reservation = this.props.reservation
        return (
            <>
                <Col className="zero-padding">
                    {this.props.loading ? <Loader /> : <>
                        <div className="agreement-detail">
                            <Nav tabs>
                                <NavItem className={this.state.tab === "carDetailView" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carDetailSubsection === "carDetailView" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carDetailSubsection", "carDetailView")}>
                                        CAR DETAILS
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "carDailyMileageView" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carDetailSubsection === "carDailyMileageView" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carDetailSubsection", "carDailyMileageView")}>
                                        DAILY MILEAGE
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div className="info-container" style={{ margin: "0px", borderTopLeftRadius: "0px" }}>
                                <Row>
                                    <Col>
                                        {reservation && reservation.car ?
                                            this.state.carDetailSubsection === "carDetailView" ?
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <Label>Car ID</Label>
                                                            <p className="columnContent">
                                                                {reservation.car ? reservation.car.pk : "---"}
                                                                {reservation.car && reservation.car.pk && <small>&nbsp;<Link color="primary" to={'/car/' + reservation.car.pk} target="_blank"><i className={"fa fa-external-link-square"}></i> OPEN</Link></small>}
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Vin</Label>
                                                            <p className="columnContent">{reservation.car ? reservation.car.vin : "---"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Plate</Label>
                                                            <p className="columnContent">{reservation.car && reservation.car.plate ? reservation.car.plate.dmvPlate : "---"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Model</Label>
                                                            <p className="columnContent">{reservation.car && reservation.car.carModel ? toTitleCase(reservation.car.carModel.make ? reservation.car.carModel.make : "--") + " " + toTitleCase(reservation.car.carModel.name ? reservation.car.carModel.name : "--") : "--"}  <small>{reservation.car && reservation.car.carModel && reservation.car.carModel.series ? reservation.car.carModel.series : ""}</small></p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Label>Group</Label>
                                                            <p className="columnContent">{reservation.car && reservation.car.carModel && reservation.car.carModel.groupType ? reservation.car.carModel.groupType.name : "--"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Series</Label>
                                                            <p className="columnContent">{reservation.car && reservation.car.carModel && reservation.car.carModel.series ? reservation.car.carModel.series : "--"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Color/Year</Label>
                                                            <p className="columnContent">{reservation.car ? reservation.car.color : "---"} - {reservation.car ? reservation.car.year : "---"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Policy</Label>
                                                            <p className="columnContent">
                                                                {reservation.car && reservation.car.activePolicy && reservation.car.activePolicy.insurancePolicy ?
                                                                    (reservation.car.activePolicy.insurancePolicy.broker && reservation.car.activePolicy.insurancePolicy.broker.name ? reservation.car.activePolicy.insurancePolicy.broker.name : "--") + ": " + reservation.car.activePolicy.insurancePolicy.policyNumber :
                                                                    "None found"
                                                                }
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Label>Car Branch</Label>
                                                            <p className="columnContent"><Badge className='bos-badge-blue'>{reservation && reservation.car && reservation.car.branch ? reservation.branch.name : "No Car Branch"}</Badge> </p>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Label>Stage</Label>
                                                            <p className="columnContent">{reservation.car ? renderEnumToInt(reservation.car.stage) : "--"}</p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                <div>
                                                    <Row>
                                                        <Col><Label>Date Added</Label></Col>
                                                        <Col><Label>Date Modified</Label></Col>
                                                        <Col><Label>Mileage</Label></Col>
                                                    </Row>
                                                    {
                                                        reservation.car &&
                                                            reservation.car.dailymileageSet &&
                                                            reservation.car.dailymileageSet.edges &&
                                                            reservation.car.dailymileageSet.edges.length > 0 &&
                                                            reservation.car.dailymileageSet.edges.length > 0 ?
                                                            reservation.car.dailymileageSet.edges.slice().reverse().map(item =>
                                                                <Row key={item.node.id}>
                                                                    <Col>
                                                                        <p>{item.node.dateAdded ? <DatetimeRenderer datetime={item.node.dateAdded} /> : "--"}</p>
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{item.node.dateModified ? <DatetimeRenderer datetime={item.node.dateModified} /> : "--"}</p>
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{item.node.mileage ? item.node.mileage : "--"}</p>
                                                                    </Col>
                                                                </Row>
                                                            ) :
                                                            <Row> <Col>No Daily Mileage found! </Col></Row>}
                                                </div>
                                            : <p style={{ color: "red" }}>No Reserved Car Found!</p>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <br /><br />
                            <Row >
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Reservation Info" ? "active" : "inactive"}>
                                            <NavLink className={this.state.reservationDetailSubsection === "Reservation Info" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("reservationDetailSubsection", "Reservation Info")}>
                                                RESERVATION INFO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Reservation Pricing" ? "active" : "inactive"}>
                                            <NavLink className={this.state.reservationDetailSubsection === "Reservation Pricing" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("reservationDetailSubsection", "Reservation Pricing")}>
                                                RESERVATION PRICING
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Reservation History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.reservationDetailSubsection === "Reservation History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("reservationDetailSubsection", "Reservation History")}>
                                                RESERVATION HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                        {this.state.reservationDetailSubsection === "Reservation Info" && reservation &&
                                            <div>
                                                <Row>
                                                    <Col xs={3}>
                                                        <Label>Date Added</Label>
                                                        <p className="columnContent">{reservation.dateAdded ? <DatetimeRenderer datetime={reservation.dateAdded} format={"MMM Do, YYYY - hh:mm A"} /> : "--"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Pickup Date</Label>
                                                        <p className="columnContent">{reservation.pickupDate ? <DatetimeRenderer datetime={reservation.pickupDate} format={"MMM Do, YYYY - hh:mm A"} /> : "--"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Pickup Type</Label>
                                                        <p className="columnContent">{reservation.pickupType}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Agreement Type</Label>
                                                        <p className="columnContent">{reservation.agreementType ? reservation.agreementType.name : "--"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Deposit</Label>
                                                        <p className="columnContent">{toTitleCase(reservation.depositScheme)} - {`$${reservation.depositAmount}`}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Surcharge</Label>
                                                        <p className="columnContent">${reservation.surcharge}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Desired Car</Label>
                                                        <p className="columnContent">{reservation.preferredCar ? reservation.preferredCar : ""}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Driver's Price Range</Label>
                                                        <p className="columnContent">{reservation.priceRange ? "$" + reservation.priceRange : "--"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Is Split?</Label>
                                                        <p className="columnContent">{reservation.isSplit ? "YES" : "NO"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Status</Label>
                                                        <p className={"columnContent"} style={{ color: reservation.status === "CLOSED" ? "green" : "orange" }}>{reservation.status ? reservation.status : ""}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Reservation Branch</Label>
                                                        <p className="columnContent"><Badge className='bos-badge-blue'>{reservation && reservation.branch ? reservation.branch.name : "No Reservation Branch"}</Badge> </p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Representative</Label>
                                                        <p className="columnContent">{reservation && reservation.rep ? reservation.rep.firstName ? reservation.rep.firstName + " " + reservation.rep.lastName : reservation.rep.username : "No Rep Assigned"} </p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Notes</Label>
                                                        <p className="columnContent">{reservation.notes}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Cancel Reason</Label>
                                                        <p className="columnContent">{reservation.cancelReason ? reservation.cancelReason : "--"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Lease Amount (if LTO)</Label>
                                                        <p className="columnContent">{reservation.totalLeaseAmount ? reservation.totalLeaseAmount : "--"}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Label>Lease Term in Months (if LTO)</Label>
                                                        <p className="columnContent">{reservation.leaseTermInMonths ? reservation.leaseTermInMonths : "--"}</p>
                                                    </Col>
                                                    {!["CLOSED", "CANCELLED"].includes(reservation.status) && <Col xs={12}>
                                                        <Label>Actions</Label>
                                                        <p className="columnContent">
                                                            <Button size="sm" className="primary" onClick={() => this.toggleModal("EditReservationModal")}>Edit</Button>&nbsp;&nbsp;
                                                            {!reservation.driver.currentAgreement && reservation.car && !reservation.car.currentAgreement && reservation.reservationpriceSet &&
                                                                reservation.reservationpriceSet.edges && reservation.reservationpriceSet.edges.length > 0 && //No current agreement
                                                                !(reservation.car.unresolvedCarIssues && reservation.car.unresolvedCarIssues.length > 0 && reservation.car.unresolvedCarIssues.filter(issue => !issue.canPickup || issue.canPickup == false).length > 0) && //No car issues
                                                                <Button size="sm" className="primary" onClick={() => this.toggleModal("RunPickupModalNew")}>Run Pickup</Button>
                                                            }
                                                        </p>
                                                        {this.state.openModal === "RunPickupModalNew" &&
                                                            <PickupModal reservationId={reservation.id} driverId={reservation && reservation.driver ? reservation.driver.id : null} agreementTypeId={reservation && reservation.agreementType ? reservation.agreementType.id : null} open={this.state.openModal === "RunPickupModalNew"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />
                                                        }
                                                        {(reservation && reservation.id && this.state.openModal === "EditReservationModal") &&
                                                            <ReservationModalNew
                                                                id={reservation && reservation.id}
                                                                title="Update Reservation"
                                                                isUpdate={true}
                                                                open={this.state.openModal === "EditReservationModal"}
                                                                handleClose={() => this.toggleModal("")}
                                                            />
                                                        }
                                                    </Col>}
                                                </Row>
                                            </div>
                                        }
                                        {this.state.reservationDetailSubsection === "Reservation Pricing" &&
                                            <div>
                                                <Row>
                                                    {reservation && reservation.reservationpriceSet && reservation.reservationpriceSet.edges && reservation.reservationpriceSet.edges.length > 0 && PRICING_TABLE_HEADER.map(tableHeader =>
                                                        <Col key={tableHeader.id} >
                                                            <Label>{tableHeader.name}</Label>
                                                        </Col>
                                                    )}
                                                </Row>
                                                {reservation && reservation.reservationpriceSet && reservation.reservationpriceSet.edges && reservation.reservationpriceSet.edges.length > 0 ? reservation.reservationpriceSet.edges.map(plan =>
                                                    <Row className={plan.node.isActive ? "activePrice" : ""} style={{ background: "#3e4c6d", opacity: plan.node.isActive ? 1 : 0.8, color: "white", padding: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center" }}>
                                                        <Col><i className="fa fa-money" aria-hidden="true"></i>&nbsp;&nbsp; {plan.node.dateAdded ? <DatetimeRenderer datetime={plan.node.dateAdded} /> : "--"}</Col>
                                                        <Col>${plan.node.price} per {plan.node.interval > 1 ? plan.node.interval : ""}{plan.node.intervalUnit}</Col>
                                                        <Col>${plan.node.minimumPrice ? plan.node.minimumPrice : "--"}</Col>
                                                        <Col>${plan.node.maximumPrice ? plan.node.maximumPrice : "--"}</Col>
                                                        <Col>{plan.node.startDate ? <DatetimeRenderer datetime={plan.node.startDate} /> : "--"}</Col>
                                                        <Col>{plan.node.isActive ? "ACTIVE" : "IN-ACTIVE"}</Col>
                                                    </Row>) : <Row><Col xs={12}><p>No Reservation Price Found</p></Col></Row>
                                                }
                                                <br />
                                                {reservation && reservation.reservationpromoSet && reservation.reservationpromoSet.edges && reservation.reservationpromoSet.edges.length > 0 ? reservation.reservationpromoSet.edges.map(promo =>
                                                    <Row style={{ backgroundColor: "green", color: "white", padding: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center" }}>
                                                        <Col><i className="fa fa-tags" aria-hidden="true"></i>&nbsp;&nbsp; {promo.node.dateAdded ? <DatetimeRenderer datetime={promo.node.dateAdded} /> : "--"}</Col>
                                                        <Col>{!promo.node.isPercentage ? "$" : ""}{promo.node.promoPrice}{promo.node.isPercentage ? "%" : ""} per {promo.node.interval > 1 ? promo.node.interval : ""}{promo.node.intervalUnit}</Col>
                                                        <Col>${promo.node.minimumPromoPrice ? promo.node.minimumPromoPrice : "--"}</Col>
                                                        <Col>${promo.node.maximumPromoPrice ? promo.node.maximumPromoPrice : "--"}</Col>
                                                        <Col>{promo.node.startDate ? <DatetimeRenderer datetime={promo.node.startDate} /> : "--"}</Col>
                                                        <Col>--</Col>
                                                    </Row>) : <Row><Col xs={12}><p>No Reservation Promo Found</p></Col></Row>
                                                }

                                            </div>
                                        }
                                        {this.state.reservationDetailSubsection === "Reservation History" &&
                                            <div>
                                                <Row>
                                                    {historyTable.map(tableHeader =>
                                                        <Col key={tableHeader.id} xs={4}><Label >{tableHeader.name}</Label></Col>
                                                    )}
                                                </Row>
                                                {reservation.history.length > 0 ? reservation.history.map(history =>
                                                    <Row>
                                                        <Col><p>{history.date ? <DatetimeRenderer datetime={history.date} /> : "--"}</p></Col>
                                                        <Col><p>{history.comment ? history.comment : "--"}</p></Col>
                                                        <Col><p>{history.user ? history.user : "--"}</p></Col>
                                                    </Row>) :
                                                    <Row>
                                                        <Col>
                                                            <p>No Reservation History Found</p>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>}
                </Col>
            </>
        )
    }
}

export default
    graphql(ReservationDetails, {
        options: ({ id }) => ({ variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { reservation, loading, refetch, variables } }) {
            return {
                reservation, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: ReservationDetails,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { reservation: fetchMoreResult.reservation }
                        },
                    })
                },
            }
        }
    })(withApolloClient(DriverReservationDetails))
