import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import './RepAssignmentModal.css'
import {
    Row, Col, Badge, Alert, Progress,
    Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { RepsQuery } from "../Functions/querys"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
const autoAssignRep = gql`
mutation CreateAutoAssignment ($input:CreateAutoDriverAssignmentInput!){
    createAutoAssignment(input:$input){
        errors{
            messages
        }
        ok
        assignment{
            id
        }
    }
}`

class RepAssignmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedReps: [],
            loading: false,
            error: null,
            assignmentSuccess: false
        }
    }

    toggleRep = (repId) => {
        let selectedReps = this.state.selectedReps
        if (selectedReps.includes(repId)) {
            selectedReps = selectedReps.filter(rep => rep !== repId)
        } else {
            selectedReps.push(repId)
        }
        this.setState({ selectedReps: selectedReps })
    }

    handleClose = () => {
        this.setState({ selectedReps: [], assignmentSuccess: false, error: null, loading: false })
        this.props.handleClose()
    }


    runAutoAssign = () => {
        this.setState({ loading: true })
        let repIds = this.state.selectedReps
        let input = { reps: repIds }
        if (this.props.drivers) {
            input["drivers"] = this.props.drivers
        }
        if (this.props.billingDay) {
            input["billingDay"] = this.props.billingDay
        }
        this.props.apolloClient.mutate({
            mutation: autoAssignRep,
            variables: { input },
        }).then((result) => {
            if (result.data && result.data.createAutoAssignment && result.data.createAutoAssignment.ok) {
                this.setState({ loading: false, error: null, assignmentSuccess: true })
                if (this.props.refetchDrivers) {
                    this.props.refetchDrivers()
                } else if (this.props.handleSuccess) {
                    this.props.handleSuccess()
                }
                this.handleClose()
            } else {
                this.setState({ assignmentSuccess: false, loading: false, error: "Error auto assigning. Try again" })
            }
        }).catch((error) => {
            // let errorMessage = "An error has occured"
            this.setState({ error: error.message, loading: false, assignmentSuccess: false })
        })
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (
            <div >
                <Modal className="repAssignmentModal" isOpen={this.props.open}>
                    <ModalHeader>{this.props.title ? this.props.title : "Assign Drivers to Reps"}</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                            {this.state.assignmentSuccess ?
                                <Col xs={12}>
                                    <Alert color="success">Assignments created successfully!</Alert>
                                </Col> :
                                <Col xs={12}>
                                    {this.props.allReps && this.props.allReps.edges && this.props.allReps.edges.map((rep, i) =>
                                        <Button key={i} onClick={() => this.toggleRep(rep.node.id)} className={this.state.selectedReps.includes(rep.node.id) ? "multiSelectButton active" : "multiSelectButton"}>
                                            {this.state.selectedReps.includes(rep.node.id) ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}&nbsp;&nbsp;
                                            {rep.node.name}:  {rep.node.assignedCount}
                                        </Button>
                                    )}
                                </Col>
                            }
                        </Row>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    </ModalBody>
                    <ModalFooter>
                        {!this.state.loading &&
                            <>
                                <Button outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                <Button color="secondary" onClick={this.runAutoAssign} disabled={this.state.selectedReps.length > 0 ? false : true}>Create Assignment</Button>
                            </>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(
    RepsQuery, {
    options: ({ groups }) => ({
        variables: { groups },
    }),
    props: ({ data: { allReps } }) =>
        ({ allReps })

}
)(
    RepAssignmentModal
)
