import React, { Component } from 'react'

import gql from 'graphql-tag'

import MassMessageModal from '../Material/MassMessageModal'
import ListingFilters from '../Material/ListingFilters'
import { debounce } from 'lodash'
import '../Drivers/DriversNew/DriversListContainer.css'
import toTitleCase from '../Functions/toTitleCase'
import renderEnumToInt from '../Functions/renderEnumToInt'
import moment from "moment"
import { Popover, PopoverBody, PopoverHeader, Row, Col, Input, InputGroup, InputGroupText } from "reactstrap"
import { DateRange } from "react-date-range"
import BulkRepAssignment from "../Material/BulkRepAssignment"
import ConfirmationPopup from "../Material/ConfirmationPopup"
import ReservationModalNew from "../Reservations/Modals/ReservationModalNew"
import updateFilters from "../Functions/updateFilters"
import getFilterValuesFromQueryString from "../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../Functions/handleConfigurationChange"
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
import { getEnumDescription } from '../Functions/processEnumDescription'//Iagami - Code upgradation React 18


const RepAssignment = gql`
mutation BulkRepAssignment ($input:BulkRepAssignmentMutationInput!){
    bulkRepAssignment(input:$input){
        errors{
            messages
        }
        ok
    }
}`



const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const PickupTypes = gql` query pickTypes{
    optionsList:__type(name: "ReservationPickupType") {
        states: enumValues {
            name
            description
        }
    }
}`

const AgreementTypes = gql`{
    optionsList: allAgreementTypes{
      edges{
        node{
            id
            name
        }
      }
    }
}`

const AllCarLocations = gql`{
    optionsList: allCarLocations(isPickupLocation:true,orderBy:["name"]){
      edges{
        node{
            id
            name
        }
      }
    }
}`

const AllCarModelsQuery = gql`query AllCarModelsQuery{
    optionsList: allCarModels{
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`
const AllCarModels = gql`
  {
    optionsList: allCarModels(orderBy: ["-make"]){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`

class ReservationsListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            filterValues: this.props.filterValues
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "text", name: "searchTerm", title: "Search Term", placeholder: "Search By Name, TLC, Phone", showFilter: false },
            { type: "number", name: "driverId", title: "Driver ID", placeholder: "1234", showFilter: true },
            //Iagami - Code upgradation React 18
            {
                type: "select", name: "driverType", title: "Driver Type", options: [
                    { value: "ACTIVE", label: "Active" },
                    { value: "INACTIVE", label: "Inactive", },
                    { value: "SWITCHING_CARS", label: "Switching", },
                    { value: "FUTURE_RD", label: "Future RD", },
                ], placeholder: "Filter By Driver Type", showFilter: ["active", "all", "inactive"].includes(activeTab)
            },
            {
                type: "select", name: "status", title: "Reservation Status", options: [
                    { value: "Open", label: "Open" },
                    { value: "Cancelled", label: "Cancelled", },
                    { value: "Closed", label: "Closed", },
                ], placeholder: "Filter By Status", showFilter: ["all"].includes(activeTab)
            },
            {
                type: "boolean", name: "hasCar", title: "Reservation with Car", optionOne: "YES",
                optionTwo: "NO", placeholder: "WITH/WITHOUT", showFilter: ["all"].includes(activeTab)
            },
            { type: "select", name: "carModelNames", title: "Car Model Name", optionsQuery: AllCarModels, placeholder: "Filter By Car Model Name", valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: true },
            {
                type: "boolean", name: "isReady", title: "Car Is Ready", optionOne: "YES",
                optionTwo: "NO", placeholder: "READY/NOT READY", showFilter: ["active", "all"].includes(activeTab)
            },
            { type: "boolean", name: "hasPromo", title: "Reservation With Promo", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Reservation With Promo", showFilter: true },
            { type: "select", name: "pickUpLocation", title: "Pickup Location", optionsQuery: AllCarLocations, placeholder: "Filter By Car Location", valueSelector: "name", labelSelector: "name", isMulti: true, showFilter: true },
            { type: "select", name: "pickupType", title: "Pickup Type", optionsQuery: PickupTypes, placeholder: "Filter By Pickup Types", valueSelector: "description", labelSelector: "description", isMulti: true, showFilter: true },
            { type: "select", name: "agreementType", title: "Agreement Type", optionsQuery: AgreementTypes, placeholder: "Filter By Agreement Types", valueSelector: "name", labelSelector: "name", showFilter: ["all", "inactive"].includes(activeTab) },
            { type: "select", name: "preferredCar", title: "Preferred Car", optionsQuery: AllCarModelsQuery, placeholder: "Filter By Preferred Car", valueSelector: "name", labelSelector: "name", showFilter: ["inactive", "all"].includes(activeTab) },
            {
                type: "select", name: "pickupDateRange", title: "Pickup Day", options: [
                    { label: "All", value: "all" },
                    { label: "Next 7 days", value: "7" },
                    { label: "A Week From Today", value: "8" },
                    { label: "No Pickup Date", value: "0" }
                ], placeholder: "Filter By All Pickup Day", valueSelector: "name", labelSelector: "name", showFilter: ["inactive", "all"].includes(activeTab)
            },
            {
                type: "select", name: "assignedTo", title: "Assigned Reps", valueSelector: "id", labelSelector: "name",
                optionsQuery: RepsQuery, variables: [{ groups: ["Sales Team"] }], placeholder: "Filter By Assigned Reps",
                showFilter: true
            }

        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.navigate, this.props.location, this.props.setFilterValues)
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.navigate, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range")
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = () => {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.apolloClient.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        if (filter['name'] === "carModelNames") {
                            options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node.name, label: toTitleCase(options.node.make) + " " + options.node.name })).filter((obj, index, arr) => arr.findIndex(model => model.value === obj.value) === index)
                        }
                        else {
                            options = result.data.optionsList.edges.map(options => options.node && ({
                                value: filter.name === "preferredCar" ? (toTitleCase(options.node.make) + " " + options.node.name + (options.node.series ? " | " + options.node.series : "") + (options.node.groupType ? " | " + options.node.groupType.name : " | No Group Selected")) : options.node[filter.valueSelector], label: filter.name === "preferredCar" ? (toTitleCase(options.node.make) + " " + options.node.name + (options.node.series ? " | " + options.node.series : "") + (options.node.groupType ? " | " + options.node.groupType.name : " | No Group Selected")) : options.node[filter.labelSelector]
                            }))
                        }
                    }
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        //Iagami - Code upgradation React 18
                        if (filter['name'] == "pickupType") {
                            let optionsList = getEnumDescription(result.data.optionsList.states)
                            options = optionsList.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? (options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                        } else {
                            options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                        }

                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        const { apolloClient } = this.props
        if (this.props.activeTab)
            this.setConfigurations()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.setConfigurations()
        }

        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.navigate, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    unAssignReps = (unassignDriverMutations) => {
        let input = {
            objectIds: this.props.selectedDrivers.map(item => item.id),
        }
        this.props.apolloClient.mutate({
            mutation: RepAssignment,
            variables: { input }
        }).then((result) => {
            this.props.refetchQuery()
        })
    }
    applyDateFitler = () => {
        this.setState({ showDateRangePopup: true })
        this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [{ startDate: new Date(), endDate: null, key: 'selection' }] })
    }
    clearDateFilter = () => {
        let filterValues = this.props.filterValues
        filterValues = delete filterValues['selectionRange']
        this.props.setFilterValues({ ...this.props.filterValues })
    }

    render() {
        return (
            <Row>
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.navigate, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" &&
                    <MassMessageModal
                        handleClose={() => this.toggleModal("MassMessageModal")}
                        open={this.state.openModal === "MassMessageModal"}
                        drivers={this.props.selectedDrivers && this.props.selectedDrivers.length > 0 ? this.props.selectedDrivers.map(item => item.driver.id) : null}
                    />
                }
                {this.state.openModal === "assignReps" && <BulkRepAssignment
                    open={this.state.openModal === "assignReps"}
                    title={"Assign " + this.props.selectedDrivers.length + " Selected Reservations to Reps"}
                    selectedObjectIds={this.props.selectedDrivers.map(item => item.id)}
                    handleClose={() => this.toggleModal("assignReps")}
                    refetchQuery={this.props.refetchQuery}
                    groups={["Sales Team"]}
                />}
                {this.state.openModal === "confirmation" && <ConfirmationPopup
                    open={this.state.openModal === "confirmation"}
                    message={"Unassign " + this.props.selectedDrivers.length + " Selected Reservations"}
                    handleClose={() => this.toggleModal("confirmation")}
                    action={"Yes"}
                    confirmAction={this.unAssignReps}
                    color="danger"
                />}
                {/* {this.state.openModal === "AddNewReservation" && (
                    <NewReservationModal
                        handleClose={() => this.toggleModal("")}
                        open={this.state.openModal === "AddNewReservation"}
                        title={"Create New Reservation"}
                        refetchQuery={this.props.refetchReservationsQuery}
                    />
                )} */}
                {this.state.openModal === "AddNewReservation" && (
                    <ReservationModalNew
                        handleClose={() => this.toggleModal("")}
                        open={this.state.openModal === "AddNewReservation"}
                        title={"Create New Reservation"}
                        refetchQuery={this.props.refetchQuery}
                        skip={true}
                        isUpdate={false}
                    />
                )}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={3}>
                    <InputGroup className="mb-0 date-range-group">
                        <a id="customDateSelection" onClick={this.applyDateFitler}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                        <Input disabled placeholder="Select Custom Date Range" value={this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate ? moment(this.props.filterValues.selectionRange[0].startDate).tz("America/New_York").format("ddd, MMM D YYYY") + " - " + moment(this.props.filterValues.selectionRange[0].endDate).tz("America/New_York").format("ddd, MMM D YYYY") : ""} />
                        {this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate && <i className="fa fa-times-circle-o reset-date-range" onClick={() => this.clearDateFilter()} aria-hidden="true"></i>}
                        <Popover trigger="legacy" placement="bottom" isOpen={this.state.showDateRangePopup} target="customDateSelection" toggle={() => this.setState({ showDateRangePopup: false })}>
                            <PopoverHeader className="text-center">Select Custom Date Range</PopoverHeader>
                            <PopoverBody>
                                <DateRange ranges={this.props.filterValues && this.props.filterValues.selectionRange} onChange={(ranges) => this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [ranges.selection] })} editableDateInputs={true} moveRangeOnFirstSelection={true} />
                            </PopoverBody>
                        </Popover>
                    </InputGroup>
                </Col>
                <Col xs={5} className="text-right mt-2">
                    <a className="driver-list-button" onClick={() => this.toggleModal("MassMessageModal")}>
                        Message Drivers | <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.props.activeTab === "all" && <>
                        <a className="driver-list-button" onClick={() => this.toggleModal("AddNewReservation")}>
                            New Reservations | <i className="fa fa-plus-square" aria-hidden="true"></i>
                        </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </>}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 &&
                        <>
                            <a className="driver-list-button" onClick={() => this.toggleModal("assignReps")}>
                                Assign Rep | <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className="driver-list-button" onClick={() => this.toggleModal("confirmation")}>
                                Unassign Reps | <i className="fa fa-minus" aria-hidden="true"></i>
                            </a>
                        </>
                    }
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

//Iagami - Code upgradation React 18
export default withApolloClient(ReservationsListHeader)
