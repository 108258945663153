import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import gql from 'graphql-tag'
import './RepAssignmentModal.css'
import {
    Row, Col, Alert, Progress, Label,
    Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import collectionsListCharge from "../graphql/collectionsListCharge"
import moment from 'moment'
import { COLLECTION_DAYS } from "../Constants"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18

class WeeklyChargeModal extends Component {
    constructor(props) {
        super(props)
        let currentDate = new Date()
        currentDate = moment(currentDate).tz("America/New_York").format("YYYY-MM-DD")
        this.state = {
            loading: false,
            error: null,
            chargeSuccess: false,
            chargeDueDate: currentDate,
            billingCode: ""
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({ [name]: value })
    };

    chargeDrivers = () => {
        try {
            this.setState({ loading: true })
            let currentDay = new Date()
            currentDay = currentDay.getDay()
            let getDay = COLLECTION_DAYS.filter(day => day.jsValue === currentDay)
            let collectionDay = getDay[0].pyValue
            let input = {
                billingCode: this.state.billingCode,
                collectionDay: collectionDay,
                dueDate: this.state.chargeDueDate,
            }
            this.props.apolloClient.mutate({
                mutation: collectionsListCharge,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.collectionsListCharge && result.data.collectionsListCharge.ok) {
                    this.setState({ loading: false, chargeSuccess: true, error: null })
                } else {
                    let error = "An error has occured. Please contact system admin"
                    if (result.data && result.data.collectionsListCharge && result.data.collectionsListCharge.errors && result.data.collectionsListCharge.errors[0] && result.data.collectionsListCharge.errors[0].messages) {
                        error = result.data.collectionsListCharge.errors[0].messages[0]
                    }
                    this.setState({ loading: false, chargeSuccess: false, error: error })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, chargeSuccess: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, chargeSuccess: false, })
        }
    };
    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        let currentDay = new Date()
        currentDay = currentDay.getDay()
        let getDay = COLLECTION_DAYS.filter(day => day.jsValue === currentDay)
        let currentDayOfWeek = getDay[0].name
        return (
            <div >
                <Modal className="weeklyChargeModal" isOpen={this.props.open}>
                    <ModalHeader>{this.props.title ? this.props.title : "Charge Weekly Rent for " + currentDayOfWeek + " Drivers"}</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.error &&
                                <Col xs={12}>
                                    <Alert color="danger">{this.state.error}</Alert>
                                </Col>
                            }
                            {this.state.chargeSuccess ?
                                <Col xs={12}>
                                    <Alert color="success">Weekly charge started successfully! You will be notified once this process is complete.</Alert>
                                </Col> :
                                <>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="chargeDueDate">Enter due date</Label>
                                            <Input type="date" name="chargeDueDate" id="chargeDueDate" onChange={this.updateInput} placeholder="Due Date" />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label for="billingCode">Enter billing code</Label>
                                            <Input type="text" name="billingCode" id="billingCode" onChange={this.updateInput} placeholder="Enter billing code" />
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                            <a ref={el => this.downloadLink = el} target="_blank" >&nbsp;</a>
                        </Row>
                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                    </ModalBody>
                    <ModalFooter>
                        {!this.state.loading &&
                            <Row>
                                <Col xs={12}>
                                    <Button outline color="secondary" onClick={this.props.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button color="secondary" onClick={() => this.chargeDrivers()} disabled={!this.state.chargeDueDate || !this.state.billingCode || this.state.chargeSuccess}>Charge Drivers</Button>&nbsp;&nbsp;
                                </Col>
                            </Row>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
//Iagami - Code upgradation React 18
export default (withApolloClient(WeeklyChargeModal))
