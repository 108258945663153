import React, { Component } from 'react'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Row, Col, Alert, Button, FormGroup, Popover, PopoverBody, PopoverHeader, Progress } from 'reactstrap'
import Select from 'react-select'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'
const AllCarDocumentTypesQuery = gql`
    query AllCarDocumentTypesQuery{
        allCarDocumentTypes(isActive:true){
            edges{
                node{
                  typeName
                  id
                }
            }
      }
    }
`

const AllDriverDocumentTypesQuery = gql`query AllDriverDocumentTypesQuery($driverId:ID){
    allDriverDocumentTypes(isActive:true, driverId: $driverId){
        edges{
            node{
              typeName
              id
            }
        }
    }
}
`


const UpdateDocumentMutation = gql`
mutation updateDocumentMutation($input: UpdateDocumentMutationInput!){
    updateDocument(input:$input){
    ok
    errors{
        messages
    }
}} `
class UpdateCarDocument extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            documentUpdated: false,
            document: null,
            documentType: "",
            openModal: "",
            fileName: null,
            selectedDocumentType: "",
        }
    }
    componentDidMount() {
        const { apolloClient } = this.props
    }

    updateDocument = () => {
        this.setState({ loading: true })
        try {
            let input = {
                id: this.props.selectedDocId ? this.props.selectedDocId : "",
                documentTypeId: this.state.documentType ? this.state.documentType : "",
            }
            this.props.apolloClient.mutate({
                mutation: UpdateDocumentMutation,
                variables: { input },
            }).then((result) => {
                if (result.data && result.data.updateDocument && result.data.updateDocument.ok) {
                    this.setState({ loading: false, documentUpdated: true, error: null })
                    this.props.refetchQuery()
                    this.props.handleClose()
                } else if (result && result.data && result.data.updateDocument && result.data.updateDocument.errors && result.data.updateDocument.errors[0] && result.data.updateDocument.errors[0].messages) {
                    this.setState({ loading: false, error: result.data.updateDocument.errors[0].messages.toString() })
                } else {
                    this.setState({ loading: false, documentUpdated: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, documentUpdated: false, })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, documentUpdated: false, })
        }
    };

    render() {
        let documentTypes = this.props.objectType === "car" ? this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges.filter(carDocumentType => carDocumentType.node.typeName.toLowerCase() != "registration") : this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges
        return (
            <div>
                <Popover placement="left" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose} style={{ minWidth: "250px" }}>
                    <PopoverHeader onClick={this.props.handleClose}>Edit Document Type<i style={{ float: "right", marginLeft: 15 }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && !this.state.documentUpdated && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                        {this.state.documentUpdated && !this.state.error && <Row><Col xs={12}><Alert color="success">Document Type Updated successfully!</Alert></Col></Row>}
                        {this.state.loading && <Progress animated color="info" value="100" />}
                        <br />
                        <FormGroup>
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                isLoading={this.props.allCarDocumentTypesLoading || this.props.allDriverDocumentTypesLoading}
                                options={documentTypes && documentTypes.length > 0 && documentTypes.map(item => ({ value: item.node.id, label: item.node.typeName }))}
                                placeholder="Select Document Type"
                                onChange={docType => this.setState({ documentType: docType.value })}
                            />
                        </FormGroup>
                        <Button color="secondary" className="float-right" onClick={this.updateDocument} disabled={this.state.documentType ? false : true}>Update Document Type</Button>
                        <br />
                    </PopoverBody>
                    <br />
                </Popover>
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllCarDocumentTypesQuery, {
    options: { fetchPolicy: 'cache-first' },
    props: ({ data }) => {
        // Safeguard: Check if data is available
        if (!data) {
            console.error('AllCarDocumentTypesQuery data is undefined')
            return { allCarDocumentTypesLoading: true, allCarDocumentTypes: [] }  // Fallback if data is undefined
        }
        const { loading, allCarDocumentTypes } = data
        return { allCarDocumentTypesLoading: loading, allCarDocumentTypes }
    }
})(
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ driverId }) => ({ variables: { driverId }, fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: true }),
        props: ({ data }) => {
            // Safeguard: Check if data is available
            if (!data) {
                console.error('AllDriverDocumentTypesQuery data is undefined')
                return { allDriverDocumentTypesLoading: true, allDriverDocumentTypes: [] }  // Fallback if data is undefined
            }
            const { loading, allDriverDocumentTypes } = data
            return { allDriverDocumentTypesLoading: loading, allDriverDocumentTypes }
        }
    })(withApolloClient(
        UpdateCarDocument
    ))
)
