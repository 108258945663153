import React, { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import withApolloClient from "../../withApolloClient"
import gql from 'graphql-tag'
import { Col, Row, Input } from 'reactstrap'
import ListingFilters from "../../Material/ListingFilters"
import './DriversListContainer.css'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import toTitleCase from '../../Functions/toTitleCase'
import { HasPermissionsQuery } from "../../Functions/querys"
import updateFilters from "../../Functions/updateFilters"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"
import { PaymentsStatusesQuery } from "../../Billing/PaymentSchedules/Queries"
import { BILLING_DAYS as COLLECTION_DAYS } from "../../Constants"
import { getEnumDescription } from "../../Functions/processEnumDescription"

const TagsQuery = gql`query TagsQuery{
    optionsList: allTags(contentType: 13){
        edges{
            node{
                id
                name
                color
            }
        }
    }
}`

const RepsQuery = gql`
     query AllRepsQuery($groups:[String], $orderBy:[String]){
        optionsList: allReps(isStaff:true, isActive:true, groups:$groups, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            firstName
            lastName
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const AllPromoNames = gql`
{
    optionsList: allPromotionsTemplateNames
}`

class DriversListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "text", name: "searchTerm", title: "Search Term", placeholder: "Search By Name, TLC, Phone", showFilter: false },
            { type: "number", name: "pk", title: "Driver ID", placeholder: "1234", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "email", title: "Email", placeholder: "john.doe@joinbuggy.com", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "phone", title: "Phone", placeholder: "+19499425025", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "tlcLicense", title: "TLC License #", placeholder: "784273498", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "text", name: "dmvLicense", title: "DMV License #", placeholder: "3123123", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab) },
            { type: "select", name: "statuses", title: "Payment Status ", optionsQuery: PaymentsStatusesQuery, valueSelector: "description", labelSelector: "description", placeholder: "Filter By Payment Status", showFilter: activeTab === "Schedules", isMulti: true },
            {
                type: "select", name: "stages", title: "Driver Stage", options: [
                    { value: 6, label: "Active" },
                    { value: 10, label: "Inactive", },
                    { value: 1, label: "Applied" },
                    { value: 0, label: "Didnt Take Car" },
                ], placeholder: "Filter By Stage", showFilter: ["Collections", "All Drivers"].includes(activeTab), isMulti: true
            },
            {
                type: "select", name: "carStatusIn", title: "Car Status", options: [
                    { value: "Enabled", label: "Enabled" },
                    { value: "Disabled", label: "Disabled" },
                    { value: "Repo", label: "Repo" },
                ], isMulti: true, placeholder: "Filter By Car Status", showFilter: ["All Drivers", "Active Drivers", "Collections"].includes(activeTab),
            },
            { type: "select", name: "chargeDayofweek", title: "Billing Day", options: COLLECTION_DAYS, placeholder: "Filter By Billing Days", showFilter: ["Collections"].includes(activeTab) },
            { type: "select", name: "driverTags", title: "Driver Tags", optionsQuery: TagsQuery, placeholder: "Filter By Driver Tags", valueSelector: "name", labelSelector: "name", isMulti: true, showFilter: ["All Drivers", "Active Drivers", "Collections",].includes(activeTab) },
            {
                type: "boolean", name: "hasBalanceWriteOff", title: "Filter By Bad Debt", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Bad Debt", showFilter: ["All Drivers", "Active Drivers"].includes(activeTab)
            },
            {
                type: "boolean", name: "hasPaymentPlan", title: "Filter By Payment Plan", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Payment Plan", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab)
            },
            { type: "number", name: "minBalance", title: "Min Balance", placeholder: "-10000", showFilter: ["All Drivers", "Active Drivers", "Collections",].includes(activeTab) },
            { type: "number", name: "maxBalance", title: "Max Balance", placeholder: "10000", showFilter: ["All Drivers", "Active Drivers", "Collections",].includes(activeTab) },
            { type: "boolean", name: "inOffice", title: "Filter By Driver In Office", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Driver In Office", showFilter: ["All Drivers", "Active Drivers", "Collections",].includes(activeTab) },
            {
                type: "boolean", name: "hideFromCollections", title: "Show Hidden", optionOne: "YES",
                optionTwo: "NO", placeholder: "Show Hidden Only", showFilter: ["Collections"].includes(activeTab)
            },
            {
                type: "boolean", name: "needsCollection", title: "Needs Collection", optionOne: "YES",
                optionTwo: "NO", placeholder: "Needs Collection", showFilter: ["Collections",].includes(activeTab)
            },
            {
                type: "select", name: "assignedTo", title: "Assigned Reps", valueSelector: "id", labelSelector: "name",
                optionsQuery: RepsQuery, variables: [{ groups: ["Billing"] }], placeholder: "Filter By Assigned Reps",
                showFilter: ["All Drivers", "Active Drivers", "Collections",].includes(activeTab)
            },
            {
                type: "boolean", name: "autoCharge", title: "Filter By Auto Charge", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Auto Charge", showFilter: ["Collections"].includes(activeTab)
            },
            {
                type: "boolean", name: "isBlocked", title: "Filter By Is Blocked", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Is Blocked", showFilter: ["Collections", "All Drivers", "Active Drivers"].includes(activeTab)
            },
            { type: "select", name: "promoNames", title: "Promo Names", placeholder: "Filter By Promo Name", optionsQuery: AllPromoNames, showFilter: ["All Drivers", "Active Drivers", "Collections",].includes(activeTab), showFilter: ["Active Drivers"].includes(activeTab), isMulti: true },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props?.navigate, this.props.location, this.props.setFilterValues)
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props?.navigate, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item)?.label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value === value) && options.find(option => option.value === value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = (activeTab) => {
        let conf = this.getFilterConfigurations(activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props?.apolloClient?.query({
                    query: filter.optionsQuery,
                    variables: { ...filter?.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                    }
                    else if (filter['name'] === "promoNames") {
                        options = result.data.optionsList.map(option => ({ value: option, label: option }))
                    }
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        if (filter['name'] == "statuses") {
                            let optionsList = getEnumDescription((result.data.optionsList.states))
                            options = optionsList.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                        } else {
                            options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                        }
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        //Iagami - Code upgradation React 18
        const { apolloClient } = this.props
        if (this.props.activeTab)
            this.setConfigurations(this.props.activeTab)
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.setConfigurations(this.props.activeTab)
        }

        if ((this.props.location.search !== prevProps.location.search && this.props.location.search)) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            updateFilters({ ...this.props.filterValues, ...urlFilterValues, }, this.state.configurations, this.props?.navigate, this.props.location, this.props.setFilterValues)
        }

        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.navigate, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    getFiltersLength = () => {
        let keys = Object.keys(this.props.filterValues)
        let filtersValue = keys.filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length
        return filtersValue ? ` | ${filtersValue}` : ""
    }
    render() {
        return (
            <Row>
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props?.filterValues} placement="right"
                        setFilterValues={(filters => {
                            updateFilters(filters, this.state.configurations, this.props?.navigate, this.props?.location, this.props?.setFilterValues); this.props.resetSelectedDrivers()
                        })}
                        filters={this.state.configurations}
                    />}
                <Col xs={12} className="mt-2">
                    <Row>
                        <Col>
                            <span className="driver-list-search-filter">
                                <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} key={`searchTerm-${this.props.activeTab}`} />
                                <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                            </span>
                        </Col>
                        <Col className="mt-1 p-0">
                            <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                                <i className="fa fa-sliders fa-solid fa-2x" aria-hidden="true"></i>
                                {this.getFiltersLength()}
                            </a>
                        </Col>
                    </Row>
                </Col>
                {this.props.filterValues && <Col xs={12} className="mt-2">
                    {Object.keys(this.props?.filterValues).filter(key => this.props?.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props?.filterValues[key])}
                        </span>
                    )}
                </Col>}
            </Row>
        )
    }
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(HasPermissionsQuery, {
    options: () => ({
        variables: {
            userPermissions: [
                "custom_can_unhide_collection",
                "custom_can_hide_collection",
                "add_driverassignment",
            ],
        },
    }),
    props: ({ data: { hasPermissions, loading, variables } }) => ({
        hasPermissions,
        loading,
        variables,
    }),
})(withApolloClient(DriversListHeader));

