import React, { Component } from 'react'
import { Input, Row, Col } from "reactstrap"
import moment from "moment"
import Select from "react-select"
import gql from 'graphql-tag'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'

const CurrentUser = gql`
query UserQuery{
    currentUser{
      id
      member {
        id
        preferredTimezone
      }
    }
}
`
const DEFAULT_TIMEZONE = "America/New_York"
class DateTimePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allTimezones: moment.tz.names().map(timezone => ({ value: timezone, label: moment.tz(timezone).format('zz') })),
            timezone: DEFAULT_TIMEZONE,
            datetime: this.props.value,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.datetime !== prevState.datetime) {
            this.props.setError(null)
        }

        if (this.props.currentUser !== prevProps.currentUser && this.props.currentUser.member && this.props.currentUser.member.preferredTimezone) {
            if (this.state.datetime && moment(this.state.datetime).isValid()) {
                this.setState({ datetime: moment(this.state.datetime).tz(this.props.currentUser.member.preferredTimezone).format(moment.HTML5_FMT.DATETIME_LOCAL) })
            }
            this.setState({ timezone: this.props.currentUser.member.preferredTimezone })
        }

        let currentDate = moment(this.state.datetime)
        let prevDate = moment(prevState.datetime)

        if (this.state.timezone !== prevState.timezone && moment(this.state.datetime).isValid()) {
            let dateTimeTz = moment(this.state.datetime).tz(this.state.timezone, 'ddd DD-MMM-YYYY, hh:mm A')
            this.setState({ datetime: dateTimeTz.format(moment.HTML5_FMT.DATETIME_LOCAL) })
            this.props.updateInput(this.props.name, dateTimeTz.format())
        } else if (prevDate.diff(currentDate) && prevDate.diff(currentDate) !== 0) {
            if (!moment(this.state.datetime).isValid()) {
                this.props.setError("Enter valid date time value")
                return
            }
            this.props.updateInput(this.props.name, moment(this.state.datetime).tz(this.state.timezone, 'ddd DD-MMM-YYYY, hh:mm A').format())
        }

    }

    render() {
        return (
            <Row>
                <Col xs={8}>
                    <Input
                        type={this.props.type}
                        id={this.props.id}
                        name={this.props.name}
                        value={this.state.datetime}
                        min={this.props.min}
                        max={this.props.max}
                        onInput={(e) => this.setState({ datetime: e.target.value })}
                    />
                </Col>
                <Col xs={4} style={{ padding: "5px" }}>
                    <Select
                        className="bos-custom-select" classNamePrefix="bos-select"
                        name="timezone" placeholder="Select Timezone"
                        options={this.state.allTimezones} isDisabled
                        value={this.state.allTimezones.find(item => item.value === this.state.timezone)}
                        onChange={(item) => this.setState({ timezone: item.value })}
                    />
                </Col>
            </Row>
        )
    }
}

//Iagami - Code upgradation React 18
export default
    withApolloClient(
        graphql(CurrentUser, {
            options: {
                fetchPolicy: 'cache-and-network',
            },
            props: ({ data: { currentUser } }) => ({ currentUser }),
        })(DateTimePicker)
    )
