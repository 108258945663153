import React, { useState, Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, InputGroup, Label, Input, Badge, Nav, NavItem, NavLink, TabContent, TabPane, InputGroupText, FormGroup, Table } from 'reactstrap'
import { CarStages } from '../../Functions/querys'
import Select from 'react-select'
import gql from 'graphql-tag'
import PlatesList from './PlatesList'
//Iagami - Code upgradation React 18
import withApolloClient from '../../withApolloClient'

const AllOwnersQuery = gql`query AllOwnersQuery{
    allOwners{
        edges{
            node{
                id
                pk
                name
            }
        }
    }   
}`

const ActiveCarsQuery = gql`query ActiveCarsQuery($cursor: String, $first: Int!, $searchTerm: String){
    activeCars: allCars(isPlateNull: true, first:$first, after: $cursor, searchTerm:$searchTerm){
        edges {
            node {
                id
                pk
                vin
                make
                model
                group
                stage
                branch{
                    id
                    name
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    }
}`

const UpdateCarMutation = gql`
    mutation updateCarMutation($input: UpdateCarMutationInput!){
        updateCar(input:$input){
            ok
            errors{
                messages
            }
    }
} `

const CreatePlateMutation = gql`
    mutation CreatePlateMutation($input: CreatePlateMutationInput!){
        createPlate(input:$input){
            ok
            errors{
                messages
            }
    }
} `

const UpdatePlate = gql`
mutation updatePlateMutation($input: UpdatePlateMutationInput!){
    updatePlate(input:$input){
        ok
        errors{
            messages
        }
    }
} `

class ManagePlatesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            activeTab: 1,
            error: "",
            selectedPlates: [],
            transferred: false,
            carToTransfer: null,
            plateFilters: {
                term: '',
                carStages: []
            },
            allPlatesFilters: {
                term: '',
                carStage: null,
                isActive: null,
                isAvailable: false,
            },
            newPlate: {
                dmvPlate: null,
                fhv: null,
                fhvExpiration: null,
                owner: null,
            },
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    handleRegisterPlate = (dmvPlate, fhv, fhvExpiration, plateOwner) => {
        this.setState({ loading: true, error: "" })
        if ((dmvPlate || fhv) && ((fhv && fhv.length < 10 && fhvExpiration) || (!fhv && !fhvExpiration))) {
            let input = {}
            if (dmvPlate) input = { ...input, dmvPlate }
            if (fhv) input = { ...input, fhv }
            if (fhvExpiration) input = { ...input, fhvExpiration }
            if (plateOwner) input = { ...input, owner: plateOwner }
            try {
                this.props.apolloClient.mutate({
                    mutation: CreatePlateMutation,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.createPlate && result.data.createPlate.ok) {
                        this.setState({ plateFilters: { term: "", carStages: null }, activeSearch: "", newPlate: { dmvPlate: null, fhv: null, fhvExpiration: null, owner: null }, carToTransfer: null, selectedPlates: [], loading: false, error: "" })
                        // this.props.refetchAllPlates(this.state.searchTerm, true, null)
                    } else if (result && result.data && result.data.createPlate && result.data.createPlate.errors && result.data.createPlate.errors[0] && result.data.createPlate.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.createPlate.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } catch (err) {
                this.setState({ loading: false, error: err })
            }
        } else {
            this.setState({ loading: false, error: "Invalid values found in FHV/DMV! Please try again with valid details." })
        }
    }

    handleDeactivatePlates = (plates) => {
        plates.filter(plate => plate.isActive === true).forEach(plate => {
            let input = {
                id: plate.id,
                isActive: false
            }
            try {
                this.props.apolloClient.mutate({
                    mutation: UpdatePlate,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updatePlate && result.data.updatePlate.ok) {
                        this.setState({ plateFilters: { term: "", carStages: null }, activeSearch: "", carToTransfer: null, selectedPlates: [], loading: false, error: "" })
                        // this.props.refetchAllPlates(this.state.searchTerm, true, null)
                    } else if (result && result.data && result.data.updatePlate && result.data.updatePlate.errors && result.data.updatePlate.errors[0] && result.data.updatePlate.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.updatePlate.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } catch (err) {
                this.setState({ loading: false, error: err })
            }
        })
    }

    handleTransferPlates = async (plate, car) => {
        if (plate && plate.pk && plate.car && plate.car.id && car && car.id) {
            this.handleUpdatePlates([plate], null, () => this.handleUpdatePlates([car], plate, null))
        } else if (plate && plate.pk && !plate.car && car && car.id) {
            this.handleUpdatePlates([car], plate, null)
        } else {
            this.setState({ loading: false, error: "Something went wrong, due to the items selection!" })
        }
    }

    handleUpdatePlates = (cars, plate, callback) => {
        if ((!plate && cars.length >= 1) || (plate && cars.length == 1 && plate.isActive)) {
            this.setState({ loading: true })
            if (!plate || plate.length === 0)
                cars = cars.map(plate => plate.car)
            cars.forEach(car => {
                let input = {
                    id: car.id,
                    platePk: plate ? plate.pk : null,
                    fhv: plate ? plate.fhv : null,
                    dmvPlate: plate ? plate.dmvPlate : null,
                }
                try {
                    this.props.apolloClient.mutate({
                        mutation: UpdateCarMutation,
                        variables: { input },
                    }).then((result) => {
                        if (result && result.data && result.data.updateCar && result.data.updateCar.ok) {
                            this.setState({ plateFilters: { term: "", carStages: null }, activeSearch: "", carToTransfer: null, selectedPlates: [], loading: false, error: "" })
                            if (callback) {
                                callback()
                            } else {
                                this.setState({ transferred: !this.state.transferred })
                                // this.props.refetchAllPlates(this.state.searchTerm, true, null);
                                this.props.refetchActiveCars()
                            }
                        } else if (result && result.data && result.data.updateCar && result.data.updateCar.errors && result.data.updateCar.errors[0] && result.data.updateCar.errors[0].messages) {
                            this.setState({ loading: false, error: result.data.updateCar.errors[0].messages.toString() })
                        } else {
                            this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                        }
                    }).catch((err) => {
                        this.setState({ loading: false, error: err.message })
                    })
                } catch (err) {
                    this.setState({ loading: false, error: err })
                }
            })
        } else {
            this.setState({ loading: false, error: "Something went wrong, due to the items selection!" })
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props
    }

    // handlePlateFilterUpdate(searchTerm, carStages){
    //     this.setState({plateFilters.term: searchTerm, plateFilters.carStages: carStages, selectedPlates: []})
    //     this.props.refetchAllPlates(searchTerm, (carStages && carStages.length > 0 ? false : true), (carStages && carStages.length > 0 ? carStages : null))
    // }

    render() {
        return <Modal isOpen={this.props.open} className="addcarmodal" style={{ maxWidth: "75%" }}>
            <ModalHeader> Manage Registration Plates </ModalHeader>
            <ModalBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={this.state.activeTab === 1 ? "active" : ""} onClick={() => this.setState({ activeTab: 1, selectedPlates: [], carToTransfer: null })}><i className={"fa fa-lightbulb-o"} /> Available Plates</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activeTab === 2 ? "active" : ""} onClick={() => this.setState({ activeTab: 2, selectedPlates: [], carToTransfer: null })}><i className={"fa fa-plus-circle"} /> Register Plate</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activeTab === 3 ? "active" : ""} onClick={() => this.setState({ activeTab: 3, selectedPlates: [], carToTransfer: null })}><i className={"fa fa-list"} /> All Plates</NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={String(this.state.activeTab)} className="p-4">
                    {this.state.activeTab === 1 && <TabPane tabId="1">
                        <Row>
                            <Col xs="6" className="p-0">
                                <h5>Available Plates {this.state.selectedPlates && this.state.selectedPlates.length > 0 && <small className="float-right" style={{ fontWeight: "500", paddingTop: "2px", fontStyle: "italic" }}>Selected Plates Count: {this.state.selectedPlates.length}</small>}</h5>
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroupText>
                                                <i className="fa fa-search" />
                                            </InputGroupText>
                                            <Input placeholder="Search Plate by VIN, FHV, DMV" value={this.state.plateFilters.term} onChange={(e) => this.setState({ plateFilters: { ...this.state.plateFilters, term: e.target.value } })} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Select className="bos-custom-select" classNamePrefix="bos-select" isLoading={!(this.props.carStages && this.props.carStages.states && this.props.carStages.states.length > 0)}
                                                options={this.props.carStages && this.props.carStages.states && this.props.carStages.states.length > 0 && this.props.carStages.states.filter(item => [7, 8, 11, 12, 13, 14].includes(renderEnumToInt(item.name))).map(item => ({ value: String(renderEnumToInt(item.name)), label: ("Stage " + renderEnumToInt(item.name) + " - " + item.description) }))}
                                                placeholder="Plates Without Cars" isMulti onChange={(stages) => this.setState({ plateFilters: { ...this.state.plateFilters, carStages: stages.map(item => parseInt(item.value)) } })} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <PlatesList searchTerm={this.state.plateFilters.term} isActive={true} carStages={this.state.plateFilters.carStages} isAvailable={this.state.plateFilters.carStages && this.state.plateFilters.carStages.length > 0 ? false : true} transferred={this.state.transferred} selectedPlates={this.state.selectedPlates} setSelectedPlates={(plates) => this.setState({ selectedPlates: plates })} />
                            </Col>
                            <Col xs={1} className="text-center mt-5 pt-5 px-0">
                                <Button className="mt-5" color="primary" disabled={this.state.selectedPlates.length != 1 || !this.state.carToTransfer} onClick={() => this.handleTransferPlates(this.state.selectedPlates[0], this.state.carToTransfer)}><i className="fa fa-long-arrow-right" aria-hidden="true" /></Button>
                                <br />
                                <Button className="mt-5" color="primary" disabled={!(this.state.selectedPlates.length > 0 && this.state.plateFilters.carStages && this.state.plateFilters.carStages.length > 0) || this.state.carToTransfer} onClick={() => this.handleUpdatePlates(this.state.selectedPlates, null, null)} ><i className="fa fa-chain-broken" aria-hidden="true" /></Button>
                                <br />
                                <Button className="mt-5" color="primary" disabled={!(this.state.selectedPlates.length > 0 && (!this.state.plateFilters.carStages || this.state.plateFilters.carStages.length == 0)) || this.state.carToTransfer} onClick={() => this.handleDeactivatePlates(this.state.selectedPlates)}><i className="fa fa-ban" aria-hidden="true" /></Button>
                            </Col>
                            <Col xs="5" className="p-0">
                                <h5>New Cars without Plates</h5>
                                <InputGroup>

                                    <InputGroupText>
                                        <i className="fa fa-search" />
                                    </InputGroupText>

                                    <Input id="search-active" placeholder="Search Car by VIN, Model, DMV Plate" value={this.state.activeSearch} onChange={(e) => { this.setState({ activeSearch: e.target.value }); this.props.refetchActiveCars(e.target.value) }} />
                                </InputGroup>
                                <div className="bos-manage-plates-table-wrapper">
                                    <Table bordered responsive className="bos-manage-plates-table">
                                        <thead>
                                            <tr>
                                                <th>Car ID</th>
                                                <th>MODEL | GROUP</th>
                                                <th>VIN</th>
                                                <th>STAGE</th>
                                                <th>BRANCH</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.activeCars && this.props.activeCars.edges && this.props.activeCars.edges.length > 0 && this.props.activeCars.edges.map((car, i) =>
                                                <tr key={i} className={"bos-manage-plates-item" + (this.state.carToTransfer === car.node ? " selected" : "")} onClick={() => this.setState({ carToTransfer: car.node === this.state.carToTransfer ? null : car.node })}>
                                                    <td>{car.node.pk}</td>
                                                    <td>{car.node.model} | {car.node.group}</td>
                                                    <td>{car.node.vin ? car.node.vin : "--"}</td>
                                                    <td>{renderEnumToInt(car.node.stage)}</td>
                                                    <td>{car.node.branch && car.node.branch.id ? car.node.branch.name : "--"}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                {this.props.activeCarsLoading && <p>Loading Active Cars Data...</p>}
                                {!this.props.activeCarsLoading && this.props.activeCars && this.props.activeCars.pageInfo && this.props.activeCars.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreActiveCars} color="add-new-car link linkHoverEffect" >Load More <i className={"fa fa-caret-down"} /></Button>}
                            </Col>
                        </Row>
                    </TabPane>}
                    {this.state.activeTab === 2 && <TabPane tabId="2">
                        <Row>
                            <Col xs="12"><h5>Register New Plate</h5></Col>
                        </Row>
                        <Form onSubmit={(e) => { e.preventDefault(); this.handleRegisterPlate(this.state.newPlate.dmvPlate, this.state.newPlate.fhv, this.state.newPlate.fhvExpiration, this.state.newPlate.owner) }}>
                            <Row>
                                <Col xs={{ size: 6, offset: 3 }} className="py-3">
                                    <Label>DMV Plate</Label>
                                    <InputGroup>

                                        <InputGroupText>
                                            <i className="fa fa-car" />
                                        </InputGroupText>

                                        <Input placeholder="Enter DMV Plate" value={this.state.newPlate.dmvPlate} onChange={(e) => { this.setState({ newPlate: { ...this.state.newPlate, dmvPlate: e.target.value } }) }} />
                                    </InputGroup>
                                </Col>
                                <Col xs={{ size: 6, offset: 3 }} className="py-3">
                                    <Row>
                                        <Col>
                                            <Label>FHV #</Label>
                                            <InputGroup>

                                                <InputGroupText>
                                                    <i className="fa fa-id-card-o" />
                                                </InputGroupText>

                                                <Input type="number" placeholder="Enter FHV" value={this.state.newPlate.fhv} onChange={(e) => { this.setState({ newPlate: { ...this.state.newPlate, fhv: e.target.value } }) }} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <Label>FHV Expiration Date #</Label>
                                            <InputGroup>

                                                <InputGroupText>
                                                    <i className="fa fa-calendar" />
                                                </InputGroupText>

                                                <Input type="date" placeholder="Enter FHV Expiration Date" value={this.state.newPlate.fhvExpiration} onChange={(e) => { this.setState({ newPlate: { ...this.state.newPlate, fhvExpiration: e.target.value } }) }} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{ size: 6, offset: 3 }} className="py-3">
                                    <Label>Plate Owner</Label>
                                    <InputGroup>

                                        <InputGroupText>
                                            <i className="fa fa-calendar" />
                                        </InputGroupText>

                                        <Input type="select" value={this.state.newPlate.owner ? this.state.newPlate.owner : ""} onChange={(e) => { this.setState({ newPlate: { ...this.state.newPlate, owner: e.target.value } }) }}>
                                            <option value="">Select Plate Owner</option>
                                            {this.props.allOwners && this.props.allOwners.edges && this.props.allOwners.edges.length > 0 && this.props.allOwners.edges.map((owner, i) => <option value={owner.node.id} key={i}>{owner.node.name}</option>)}
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col xs={{ size: 6, offset: 3 }} className="py-3 text-right">
                                    {this.state.loading ? "Loading..." : <Button type="submit" color="primary">Add Plate</Button>}
                                </Col>
                            </Row>
                        </Form>
                    </TabPane>}
                    {this.state.activeTab === 3 && <TabPane tabId="3">
                        <Row>
                            <Col xs="12" className="p-0">
                                <h5>All Plates</h5>
                                <Row>
                                    <Col>
                                        <InputGroup>

                                            <InputGroupText>
                                                <i className="fa fa-search" />
                                            </InputGroupText>

                                            <Input placeholder="Search Plate by VIN, FHV, DMV" value={this.state.allPlatesFilters.term} onChange={(e) => this.setState({ allPlatesFilters: { ...this.state.allPlatesFilters, term: e.target.value } })} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Select className="bos-custom-select" classNamePrefix="bos-select" isLoading={!(this.props.carStages && this.props.carStages.states && this.props.carStages.states.length > 0)}
                                                options={this.props.carStages && this.props.carStages.states && this.props.carStages.states.length > 0 && [...this.props.carStages.states.map(item => ({ value: String(renderEnumToInt(item.name)), label: ("Stage " + renderEnumToInt(item.name) + " - " + item.description) })), { value: "available", label: "Plates Without Cars" }]}
                                                placeholder="All Car Stages" isClearable onChange={(stage) => this.setState({ allPlatesFilters: { ...this.state.allPlatesFilters, carStage: stage && stage.value ? stage.value : null } })} />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Select className="bos-custom-select" classNamePrefix="bos-select"
                                                options={['Active', 'Inactive'].map(item => ({ value: item, label: item }))} isClearable
                                                placeholder="Active/Inactive Plates" onChange={(status) => this.setState({ allPlatesFilters: { ...this.state.allPlatesFilters, isActive: status && status.value == "Active" ? true : status && status.value == "Inactive" ? false : null } })} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <PlatesList searchTerm={this.state.allPlatesFilters.term} allOwners={this.props.allOwners} carStages={this.state.allPlatesFilters.carStage && this.state.allPlatesFilters.carStage !== "available" ? [parseInt(this.state.allPlatesFilters.carStage)] : null} isAvailable={this.state.allPlatesFilters.carStage && this.state.allPlatesFilters.carStage == "available" ? true : null} isActive={this.state.allPlatesFilters.isActive} />
                            </Col>
                        </Row>
                    </TabPane>}
                </TabContent>

                <Row style={{ border: "1px solid #6c757d5e" }}>
                    <Col xs={12} className="py-2">
                        <Button color="primary" disabled style={{ padding: "0px 7px" }}><i className="fa fa-long-arrow-right" aria-hidden="true" /></Button>
                        &nbsp;&nbsp;<small><b>Transfer plate from one car to another car. Select one car from each list.</b></small>
                    </Col>
                    <Col xs={12} className="py-2">
                        <Button color="primary" disabled style={{ padding: "0px 7px" }}><i className="fa fa-chain-broken" aria-hidden="true" /></Button>
                        &nbsp;&nbsp;<small><b>Remove the plates from inactive cars. Select one or more cars from the list on left side.</b></small>
                    </Col>
                    <Col xs={12} className="py-2">
                        <Button color="primary" disabled style={{ padding: "0px 7px" }}><i className="fa fa-ban" aria-hidden="true" /></Button>
                        &nbsp;&nbsp;<small><b>Deactivate the available plates. Select one or more cars from the list on left side.</b></small>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {this.state.error && <Badge color="danger">{this.state.error}</Badge>}
                <Button color="secondary" onClick={this.props.handleClose}>Close</Button>
            </ModalFooter>
        </Modal>
    };
}
//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllOwnersQuery, { 
    options: { fetchPolicy: 'cache-first' }, 
    props: ({ data: { allOwners } }) => ({ allOwners }) 
  })(
    graphql(CarStages, { 
      props: ({ data: { carStages } }) => ({ carStages }) 
    })(
      graphql(ActiveCarsQuery, {
        options: ({ searchTerm }) => ({ 
          variables: { searchTerm, first: 15 }, 
          notifyOnNetworkStatusChange: true, 
          fetchPolicy: 'network-only' 
        }),
        props: ({ data: { loading, activeCars, fetchMore, variables } }) => ({
          activeCarsLoading: loading,
          activeCars,
          variables,
          refetchActiveCars: (searchTerm) => {
            return fetchMore({
              query: ActiveCarsQuery,
              variables: {
                ...variables,
                searchTerm: searchTerm,
                first: 15,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.activeCars.edges;
                const pageInfo = fetchMoreResult.activeCars.pageInfo;
                return {
                  activeCars: {
                    edges: [...newEdges],
                    pageInfo,
                    searchTerm: previousResult.searchTerm,
                    __typename: previousResult.activeCars.__typename,
                  },
                };
              },
            });
          },
          loadMoreActiveCars: () => {
            return fetchMore({
              query: ActiveCarsQuery,
              variables: {
                cursor: activeCars.pageInfo.endCursor,
                ...variables,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.activeCars.edges;
                const pageInfo = fetchMoreResult.activeCars.pageInfo;
  
                return {
                  activeCars: {
                    edges: [...previousResult.activeCars.edges, ...newEdges],
                    pageInfo,
                    searchTerm: previousResult.searchTerm,
                    issueCategory: previousResult.issueCategory,
                    status: previousResult.status,
                    currentAgreementIsnull: previousResult.currentAgreementIsnull,
                    dateAdded: previousResult.dateAdded,
                    __typename: previousResult.activeCars.__typename,
                  },
                };
              },
            });
          },
        })
      })(withApolloClient(
        ManagePlatesModal
      ))
    )
  );
    // options: { fetchPolicy: 'cache-first' }, props({ data: { inactiveCars } }) { return { inactiveCars }; } }),
     // graphql(AllPlatesQuery, {
    //     options: ({ searchTerm }) => ({ variables: {searchTerm, isAvailable: true, first: 15}, notifyOnNetworkStatusChange:true, fetchPolicy: 'network-only'}),
    //     props({ data: { loading, allPlates, fetchMore, variables} }) {
    //     return {
    //         allPlatesLoading: loading,
    //         allPlates,
    //         variables,
    //         refetchAllPlates: (searchTerm, isAvailable, carStages) => {
    //             //No apollo function that refetches in place with pagination considered so this function instead
    //             return fetchMore({
    //                 query: AllPlatesQuery,
    //                 variables: {
    //                     ...variables,
    //                     searchTerm: searchTerm,
    //                     isAvailable: isAvailable,
    //                     carStages: carStages,
    //                     first: 15,
    //                 },
    //                 updateQuery: (previousResult, {fetchMoreResult}) => {
    //                     const newEdges = fetchMoreResult.allPlates.edges;
    //                     const pageInfo = fetchMoreResult.allPlates.pageInfo;
    //                     return {
    //                         allPlates: {
    //                             edges: [...newEdges],
    //                             pageInfo,
    //                             searchTerm: previousResult.searchTerm,
    //                             __typename: previousResult.allPlates.__typename
    //                         },
    //                     };
    //                 },
    //             });
    //         },
    //         loadMorePlates: () => {
    //             return fetchMore({
    //                 query: AllPlatesQuery,
    //                 variables: {
    //                 cursor: allPlates.pageInfo.endCursor,
    //                 ...variables
    //                 },
    //                 updateQuery: (previousResult, { fetchMoreResult }) => {
    //                 const newEdges = fetchMoreResult.allPlates.edges;
    //                 const pageInfo = fetchMoreResult.allPlates.pageInfo;

    //                 return {
    //                     allPlates: {
    //                         edges: [...previousResult.allPlates.edges, ...newEdges],
    //                         pageInfo,
    //                         searchTerm: previousResult.searchTerm,
    //                         issueCategory: previousResult.issueCategory,
    //                         status: previousResult.status,
    //                         currentAgreementIsnull:previousResult.currentAgreementIsnull,
    //                         dateAdded: previousResult.dateAdded,
    //                         __typename: previousResult.allPlates.__typename
    //                     },
    //                 };
    //                 },
    //             });
    //         },
    //     };
    //     // { options: { fetchPolicy: 'cache-first' }, props({ data: { allPlates } }) { return { allPlates }; 
    // }}),
    // graphql(InactiveCarsQuery, { 
    //     options: ({ searchTerm }) => ({ variables: {searchTerm, first: 15}, notifyOnNetworkStatusChange:true, fetchPolicy: 'network-only'}),
    //     props({ data: { loading, inactiveCars, fetchMore, variables} }) {
    //     return {
    //         inactiveCarsLoading: loading,
    //         inactiveCars,
    //         variables,
    //         refetchInactiveCars: (searchTerm) => {
    //             //No apollo function that refetches in place with pagination considered so this function instead
    //             return fetchMore({
    //                 query: InactiveCarsQuery,
    //                 variables: {...variables, searchTerm: searchTerm, first: 15},
    //                 updateQuery: (previousResult, {fetchMoreResult}) => {
    //                     return {
    //                         inactiveCars: {
    //                             edges: [...fetchMoreResult.inactiveCars.edges],
    //                             pageInfo: fetchMoreResult.inactiveCars.pageInfo,
    //                             searchTerm: previousResult.searchTerm,
    //                             __typename: previousResult.inactiveCars.__typename
    //                         },
    //                     };
    //                 },
    //             });
    //         },
    //         loadMoreInactiveCars: () => {
    //             return fetchMore({
    //                 query: InactiveCarsQuery,
    //                 variables: {
    //                 cursor: inactiveCars.pageInfo.endCursor,
    //                 ...variables
    //                 },
    //                 updateQuery: (previousResult, { fetchMoreResult }) => {
    //                 const newEdges = fetchMoreResult.inactiveCars.edges;
    //                 const pageInfo = fetchMoreResult.inactiveCars.pageInfo;

    //                 return {
    //                     inactiveCars: {
    //                         edges: [...previousResult.inactiveCars.edges, ...newEdges],
    //                         pageInfo,
    //                         searchTerm: previousResult.searchTerm,
    //                         issueCategory: previousResult.issueCategory,
    //                         status: previousResult.status,
    //                         currentAgreementIsnull:previousResult.currentAgreementIsnull,
    //                         dateAdded: previousResult.dateAdded,
    //                         __typename: previousResult.inactiveCars.__typename
    //                     },
    //                 };
    //                 },
    //             });
    //         },
    //     };
    // }}),
