import React, { Component } from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc' // Iagami - Code upgradation React 18
import { Container, Col, Row, Table } from 'reactstrap'
import moment from 'moment-timezone'
import Loader from "../../../Material/Loader"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import { Button } from "@material-ui/core"
import BusinessHoursModal from "./BusinessHoursModal"
import withApolloClient from '../../../withApolloClient' // Iagami - Code upgradation React 18

const AllBusinessHoursQuery = gql`query AllBusinessHours($branchId: ID!){
    allBusinessHours(branchId: $branchId){
        edges {
            node {
                id
                dateAdded
                dateModified
                dayOfWeek
                openTime
                closeTime
                dayOfWeekDisplay
            }
        }
    }
}`

const tableHeaders = [
    { id: "id", name: "ID", sortable: false },
    { id: "date_added", name: "Date Added", sortable: false },
    { id: "date_modified", name: "Date Modified", sortable: false },
    { id: "day_of_week", name: "Day of Week", sortable: false },
    { id: "open_time", name: "Open Time", sortable: false },
    { id: "close_time", name: "Close Time", sortable: false },
    { id: "action", name: "Action", sortable: false },
]

class BusinessHoursTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: ""
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Container fluid className="bos-listing-container">
                {this.props.loading ? <Loader /> : <>
                    <Row>
                        <Col xs={{ offset: 11, xs: 1 }}>
                            <Button className="bos-btn-primary" size="sm" onClick={() => this.toggleModal("businessHoursModal")}>Add New Hours</Button>
                            {this.state.openModal === "businessHoursModal" &&
                                <BusinessHoursModal
                                    isUpdate={false}
                                    isOpen={this.state.openModal === "businessHoursModal"}
                                    toggle={this.toggleModal}
                                    refetch={this.props.refetchBusinessHours}
                                    branchId={this.props.branchId}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="bos-object-section-wrapper">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {tableHeaders.map(tableHeader => <th key={tableHeader.id}>{tableHeader.name}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.allBusinessHours && this.props.allBusinessHours.edges && this.props.allBusinessHours.edges.length > 0 ? this.props.allBusinessHours.edges.map((businessHours, i) =>
                                        <tr key={i + 1}>
                                            <td>{i + 1}</td>
                                            <td><DatetimeRenderer datetime={businessHours.node.dateAdded} /></td>
                                            <td><DatetimeRenderer datetime={businessHours.node.dateModified} /></td>
                                            <td>{businessHours.node.dayOfWeekDisplay}</td>
                                            <td>{moment(businessHours.node.openTime, "HH:mm:ss").format("hh:mm A")}</td>
                                            <td>{moment(businessHours.node.closeTime, "HH:mm:ss").format("hh:mm A")}</td>
                                            <td>
                                                <i className="fa fa-pencil-square-o" onClick={() => this.toggleModal(`businessHoursModal${i}`)} />
                                                {this.state.openModal === `businessHoursModal${i}` &&
                                                    <BusinessHoursModal
                                                        isUpdate={true}
                                                        isOpen={this.state.openModal === `businessHoursModal${i}`}
                                                        toggle={this.toggleModal}
                                                        businessHours={businessHours.node}
                                                        refetch={this.props.refetchBusinessHours}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ) : <tr>
                                        <td colSpan="7" >No Business Hours found</td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>}
            </Container >
        )
    }
}

// Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(AllBusinessHoursQuery, {
    options: ({ branchId }) => ({
        variables: { branchId },
        fetchPolicy: 'network-only',
    }),
    props: ({ data: { allBusinessHours, loading, refetch, variables } }) => ({
        allBusinessHours,
        loading,
        variables,
        refetchBusinessHours: () => {
            return refetch({
                query: AllBusinessHoursQuery,
                variables: { ...variables },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    return { allBusinessHours: fetchMoreResult.allBusinessHours };
                },
            });
        },
    }),
})(withApolloClient(BusinessHoursTab));

