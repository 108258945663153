import React, { Component, Fragment } from 'react'
import { graphql } from '@apollo/client/react/hoc' // Updated Apollo HOC import
import './DriverDetailNew.css'
import { Link } from 'react-router-dom'
import { Badge, Button, Col, Row, Label, Input, UncontrolledTooltip, FormGroup, Nav, NavItem, NavLink, Alert, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import DriverUpdateTagsPopover from "../../Tags/DriverUpdateTagsPopover"
import Loader from "../../../../Material/Loader"
import Languages from "../../../../Material/Language.json"
import renderEnumToInt from "../../../../Functions/renderEnumToInt"
import { UpdateDriver, PaymentScheduleQuery } from "../Mutations"
import DatetimeRenderer from "../../../../Material/DatetimeRenderer"
import { HasPermissionsQuery } from "../../../../Functions/querys"
import { DriverDetailQuery, CustomerIdentifiers, AllExternalIdentifiers } from "../Queries"
import UpdateDriverInfo from "../../../../ApplicationsNew/UpdateDriverInfo"
import ReservationModalNew from "../../../../Reservations/Modals/ReservationModalNew"
import UploadOrCreateBaseStatus from "../../../../Material/UploadOrCreateBaseStatus"
import gql from 'graphql-tag'
import toTitleCase from "../../../../Functions/toTitleCase"
import securityDepositIcon from "../../../../assets/images/icons/security_deposit_icon.png"
import CopyToClipboard from "../../../../Material/CopyToClipboard"
import { CreateAddress, UpdateAddress } from "./AddressUtils"
import CustomerHandleModal from "../../../../Material/CustomerHandleModal"
import ConfirmationPopup from "../../../../Material/ConfirmationPopup"
import UpdateDNR from "../../../DNR/UpdateDNR"
import NotesHistory from "../../../../Material/NotesHistory/NotesHistory"
import withApolloClient from "../../../../withApolloClient"
import SearchPlaces from "../../../../Material/SearchPlaces"
import GoogleMapComponent from "../../../../Material/GoogleMapComponent"
import ViewDocumentImage from "../../../../Material/DocumentModal/viewDocumentImage/ViewDocumentImage"
import { GENDER_LIST } from "../../../../Constants"
import { getStatesByCountry } from "../../../../utils"
import moment from 'moment-timezone'
import { ReactComponent as TokuLogo } from "../../../../assets/images/icons/toku.svg"
import { ReactComponent as StripeLogo } from "../../../../assets/images/icons/stripe.svg"
import ExternalSystemIdentifierModal from "../../../../Material/ExternalSystemIdentifierModal"


const UpdateDriverBalanceMutation = gql`
    mutation updateDriverBalance($input: UpdateDriverBalanceInput!){
        updateDriverBalance(input:$input){
            ok
            errors{
                messages
            }
    }
} `

class OverviewTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            showEdit: "",
            editField: "",
            input: {},
            openModal: "",
            customerHandles: null,
            tab: "",
            customerHandleSubsection: "Email",
            customerSummarySubsection: "bosSummary",
            selectedDocumentUrl: "",
            modalTitle: "",
            selectedIdentifier: null
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    toggleModalDocument = (modalName, documentUrl = "", documentType, identifier) => {
        this.setState({
            openModal: modalName,
            selectedDocumentUrl: documentUrl,
            modalTitle: documentType,
            selectedIdentifier: identifier
        })
    };


    updateInput = (e) => {
        let value = e.target.value
        this.setState({ value: value })
    };

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    toggleSwitch = (toggleName, toggleValue, updateDriver = false) => {
        let input = { [toggleName]: toggleValue }
        this.setState({ input: input }, () => {
            if (updateDriver === true) { this.updateDriver(this.props.driver.id, toggleName, toggleValue) }
        })
    }

    editField(type, value) {
        if (value) {
            this.setState({ [type]: value, value: value })
        }
        if (type === this.state.editField) {
            this.setState({ error: null })
        }
        this.setState({ editField: type === this.state.editField ? "" : type })

    }

    getLanguageVal(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }
    //Iagami - Code upgradation React 18
    componentDidMount() {
        const { apolloClient } = this.props
    }


    updateDriver = (driverId, name, value) => {
        try {
            if (driverId && name && ((typeof value == "boolean" && (value === true || value === false)) || (typeof value !== "boolean" && value))) {
                this.setState({ loading: true })
                let input = {
                    id: driverId,
                    [name]: name === "stage" ? renderEnumToInt(value) : value
                }
                //Iagami - Code upgradation React 18
                this.props.apolloClient.mutate({
                    mutation: UpdateDriver,
                    variables: { input },
                }).then((result) => {
                    if (result && result.data && result.data.updateDriver && result.data.updateDriver.ok) {
                        this.setState({ editField: "", loading: false, error: "" })
                        this.props.refetchDriverQuery()

                    } else if (result && result.data && result.data.updateDriver && result.data.updateDriver.errors && result.data.updateDriver.errors[0] && result.data.updateDriver.errors[0].messages) {
                        this.setState({ loading: false, error: result.data.updateDriver.errors[0].messages.toString() })
                    } else {
                        this.setState({ loading: false, error: "Something went wrong, Please contact admin for support!" })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: err.message })
                })
            } else if (value === this.state.value) {
                this.setState({ editField: "", loading: false, error: "" })
            }
        } catch (err) {
            this.setState({ loading: false, error: err })
        }
    }
    refetchBalance = () => {
        this.props.apolloClient.mutate({
            mutation: UpdateDriverBalanceMutation,
            variables: { input: { driverId: this.props.driver.id } }
        }).then((result) => {
            if (result.data && result.data.updateDriverBalance && result.data.updateDriverBalance.ok) {
                this.props.refetchDriverQuery()
            }
        })
    }

    refetchQuery = () => {
        this.props.refetchDriverQuery()
        this.props.refetchQuery()
    }

    stringToJson = (jsonString) => {
        let jsonObj
        try {
            jsonObj = JSON.parse(jsonString.replace(/'/g, '"'))
        } catch {
            jsonObj = null
        }
        return jsonObj
    }

    fetchMetaData = (metadata = null, name) => {
        if (!metadata || metadata === "")
            return
        //Iagami - Code upgradation React 18
        if (name == "legacy_system_ids") {
            metadata = (metadata)
        } else {
            metadata = this.stringToJson(metadata)
        }
        return metadata && metadata[name] ? metadata[name] : null
    }

    removeLegacySystemId = (index) => {
        let { driver } = this.props
        let legacySystemIds = driver && driver.metadata && this.fetchMetaData(driver.metadata, "legacy_system_ids")
        if (legacySystemIds && legacySystemIds.length > 0) {
            legacySystemIds.splice(index, 1)
            this.updateDriver(driver.id, "legacySystemIds", JSON.stringify(legacySystemIds))
        }
    }

    editLegacySystemId = (index, value) => {
        let { driver } = this.props
        let legacySystemIds = driver && driver.metadata && this.fetchMetaData(driver.metadata, "legacy_system_ids")
        if (legacySystemIds && legacySystemIds.length > 0) {
            legacySystemIds[index].value = value
            this.updateDriver(driver.id, "legacySystemIds", JSON.stringify(legacySystemIds))
        }
    }


    render() {
        let { driver } = this.props
        let legacySystemIds = driver && driver.metadata && this.fetchMetaData(driver.metadata, "legacy_system_ids")
        let branchCountry = (driver && driver.branch && driver.branch.country) || ""
        let states = getStatesByCountry(branchCountry)
        return (
            <div className="tab-container">
                {this.props.driverLoading ? <Loader /> :
                    driver ?
                        <>
                            <Row>
                                <Col className="balance-box-wrapper">
                                    <Row>
                                        <Col>
                                            {driver && driver.branch && driver.branch.tenant && driver.branch.tenant.customId === "mnave" ?
                                                <h2 className={driver && driver.bcBalance && driver.bcBalance > 0 ? "columnContent danger" : "columnContent success"}>{"$" + (driver && driver.bcBalance ? driver.bcBalance : 0)}</h2> :
                                                <h2 className={driver && driver.balance > 0 ? "columnContent danger" : "columnContent success"}>{"$" + (driver && driver.balance)}</h2>
                                            }
                                            <p className="balance-box-wrapper-text">TOTAL BALANCE</p>
                                        </Col>
                                        <Col className="text-right">
                                            <span className="balance-box-wrapper-icon"><i className="fa fa-usd" aria-hidden="true" style={{ color: "#1B4D7E" }}></i></span>
                                            <br />
                                            <UncontrolledTooltip placement="left" target="refetch_balance">Refresh Balance</UncontrolledTooltip>
                                            <i id="refetch_balance" className="fa fa-rotate-right" onClick={this.refetchBalance}></i>&nbsp;&nbsp;
                                        </Col>
                                    </Row>
                                </Col>
                                {driver.balanceWriteOff < 0 &&
                                    <Col className="balance-box-wrapper">
                                        <Row>
                                            <Col>
                                                <h2 className="columnContent danger">${driver.balanceWriteOff * -1}</h2>
                                                <p className="balance-box-wrapper-text">BALANCE WRITE OFF</p>
                                            </Col>
                                            <Col className="text-right">
                                                <span className="balance-box-wrapper-icon" > <i className="fa fa-line-chart" aria-hidden="true" style={{ color: "red" }}></i></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                {driver.creditWriteOff > 0 &&
                                    <Col className="balance-box-wrapper">
                                        <Row>
                                            <Col>
                                                <h2 className="columnContent success">${driver && driver.creditWriteOff}</h2>
                                                <p className="balance-box-wrapper-text">CREDIT WRITE OFF</p>
                                            </Col>
                                            <Col className="text-right">
                                                <span className="balance-box-wrapper-icon"> <i className="fa fa-area-chart" aria-hidden="true" style={{ color: "green" }}></i></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                                <Col className="balance-box-wrapper">
                                    <Row>
                                        <Col>
                                            <h2 className={driver && driver.currentCharge > 0 ? "columnContent danger" : "columnContent success"}>{"$" + (driver && driver.currentCharge)}</h2>
                                            <p className="balance-box-wrapper-text">CURRENT CHARGE</p>
                                        </Col>
                                        <Col className="text-right">
                                            <span className="balance-box-wrapper-icon" ><i className="fa fa-pie-chart" aria-hidden="true" style={{ color: "#DB9360" }}></i></span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="balance-box-wrapper">
                                    <Row>
                                        <Col>
                                            <h2 className="columnContent">{"$" + (driver && driver.deposit)}</h2>
                                            <p className="balance-box-wrapper-text">DEPOSIT</p>
                                        </Col>
                                        <Col className="text-right">
                                            <span className="balance-box-wrapper-icon" > <img src={securityDepositIcon} width={60} height={60} lt="security_deposit logo" /></span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {driver.currentAgreement && <>
                                <br />
                                <div>
                                    {/* <div className="info-container" style={{ backgroundColor: "white" }}> */}
                                    <div className="green-highlighted-header">ACTIVE RENTAL</div>
                                    <div className="info-container py-4" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                                        <Row>
                                            <Col>
                                                <Label>CAR</Label>
                                                <div className={"sidebar-listing-wrapper-text "} style={{ margin: "-5px" }}>
                                                    <p>{driver.currentAgreement && driver.currentAgreement.car && driver.currentAgreement.car.year} {driver.currentAgreement && driver.currentAgreement.car && driver.currentAgreement.car.carModel && driver.currentAgreement.car.carModel.make}  {driver.currentAgreement && driver.currentAgreement.car && driver.currentAgreement.car.carModel && driver.currentAgreement.car.carModel.name}  {driver.currentAgreement && driver.currentAgreement.car && driver.currentAgreement.car.carModel && driver.currentAgreement.car.carModel.series && "(" + driver.currentAgreement.car.carModel.series + ")"} {driver.currentAgreement.car && driver.currentAgreement.car.pk && <small>&nbsp;<Link color="primary" to={'/car/' + driver.currentAgreement.car.pk} target="_blank"><i className={"fa fa-external-link-square"}></i></Link></small>}</p>
                                                    <small>{driver.currentAgreement && driver.currentAgreement.car && driver.currentAgreement.car.pk} - {driver.currentAgreement && driver.currentAgreement.car && driver.currentAgreement.car.vin}</small>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Label>START DATE</Label>
                                                <p>{driver.currentAgreement.startDate ? <DatetimeRenderer datetime={driver.currentAgreement.startDate} /> : "--"}</p>
                                            </Col>
                                            <Col>
                                                <Label>END DATE</Label>
                                                <p>{driver.currentAgreement.endDate ? <DatetimeRenderer datetime={driver.currentAgreement.endDate} /> : "--"}</p>
                                            </Col>
                                            <Col>
                                                <Label>AGREEMENT TYPE</Label>
                                                <p>{driver.currentAgreement.agreementType ? driver.currentAgreement.agreementType : "--"}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </>}
                            {driver.reservationDriver && driver.reservationDriver.edges && driver.reservationDriver.edges.length > 0 &&
                                <>
                                    <br />
                                    <div>
                                        <div className="green-highlighted-header">ACTIVE RESERVATION</div>
                                        <div className="info-container py-4" style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                                            {driver.reservationDriver && driver.reservationDriver.edges && driver.reservationDriver.edges.length > 0 ?
                                                driver.reservationDriver.edges.map((reservation, i) =>
                                                    <div>
                                                        <Row key={i}>
                                                            <Col xs={2}>
                                                                <Label>Car</Label>
                                                                {reservation.node && reservation.node.car ? <>
                                                                    <p className="mb-0">{reservation.node && reservation.node.car && reservation.node.car.year} {reservation.node && reservation.node.car && reservation.node.car.carModel && reservation.node.car.carModel.make}  {reservation.node && reservation.node.car && reservation.node.car.carModel && reservation.node.car.carModel.name}  {reservation.node && reservation.node.car && reservation.node.car.carModel && reservation.node.car.carModel.series && "(" + reservation.node.car.carModel.series + ")"} {reservation.node.car && reservation.node.car.pk && <small>&nbsp;<Link color="primary" to={'/car/' + reservation.node.car.pk} target="_blank"><i className={"fa fa-external-link-square"}></i></Link></small>}</p>
                                                                    <small>{reservation.node && reservation.node.car && reservation.node.car.pk} - {reservation.node && reservation.node.car && reservation.node.car.vin}</small>
                                                                </> : <p>No Reserved Car Found!</p>
                                                                }
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Label>Pickup Date</Label>
                                                                <p className="columnContent">{reservation.node.pickupDate ? <DatetimeRenderer datetime={reservation.node.pickupDate} format={"MMM Do, YYYY - hh:mm A"} /> : "--"}</p>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Label>Pickup Type</Label>
                                                                <p className="columnContent">{reservation.node.pickupType ? toTitleCase(reservation.node.pickupType) : "--"}</p>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Label>Deposit</Label>
                                                                <p className="columnContent">{toTitleCase(reservation.node.depositScheme)} - {`$${reservation.node.depositAmount}`}</p>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Label>Surcharge</Label>
                                                                <p className="columnContent">${reservation.node.surcharge}</p>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Label>Actions</Label>
                                                                <p className="columnContent">
                                                                    <Button size="sm" className="primary" onClick={() => this.toggleModal("EditReservationModal" + i)}>Edit</Button>
                                                                </p>
                                                                {(this.state.openModal === "EditReservationModal" + i && reservation.node.id) &&
                                                                    <ReservationModalNew
                                                                        id={reservation.node.id}
                                                                        open={this.state.openModal === "EditReservationModal" + i}
                                                                        title={"Update Reservation"}
                                                                        isUpdate={true}
                                                                        handleClose={() => this.toggleModal("")}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : <p className="columnContent">No Active Reservation Found</p>
                                            }
                                        </div>
                                    </div>
                                </>}
                            <br /><br />
                            <h4>PERSONAL INFO</h4>
                            <div className="info-container">
                                {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>First Name</Label>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "firstName" ?
                                                    <>
                                                        <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("firstName", driver && driver.firstName ? driver.firstName : "")}></i>&nbsp;&nbsp;
                                                        <CopyToClipboard textToCopy={driver && driver.firstName ? driver.firstName : ""} displayText={"first name"} />
                                                    </> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("firstName")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "firstName", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Input disabled={this.state.editField !== "firstName"} defaultValue={driver && driver.firstName ? driver.firstName : "--"} onChange={this.updateInput} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Last Name</Label>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "lastName" ?
                                                    <>
                                                        <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("lastName", driver && driver.lastName ? driver.lastName : "")}></i>&nbsp;&nbsp;
                                                        <CopyToClipboard textToCopy={driver && driver.lastName ? driver.lastName : ""} displayText={"last name"} />
                                                    </>
                                                    :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("lastName")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "lastName", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Input disabled={this.state.editField !== "lastName"} defaultValue={driver && driver.lastName ? driver.lastName : "--"} onChange={this.updateInput} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={9}>
                                                <Label>DMV License Number</Label>
                                            </Col>
                                            <Col className="text-right" xs={3}>
                                                {this.state.editField !== "dmvLicense" ?
                                                    <>
                                                        <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("dmvLicense", driver && driver.dmvLicense ? driver.dmvLicense : "")}></i>&nbsp;&nbsp;
                                                        <CopyToClipboard textToCopy={driver && driver.dmvLicense ? driver.dmvLicense : ""} displayText={"dmv license number"} />
                                                    </>
                                                    :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("dmvLicense")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "dmvLicense", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Input disabled={this.state.editField !== "dmvLicense"} defaultValue={driver && driver.dmvLicense ? driver.dmvLicense : "--"} onChange={this.updateInput} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={9}>
                                                <Label>TLC License Number</Label>
                                            </Col>
                                            <Col className="text-right" xs={3}>
                                                {this.state.editField !== "tlcLicense" ?
                                                    <>
                                                        <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("tlcLicense", driver && driver.tlcLicense ? driver.tlcLicense : "")}></i>&nbsp;&nbsp;
                                                        <CopyToClipboard textToCopy={driver && driver.tlcLicense ? driver.tlcLicense : ""} displayText={"tlc license number"} />
                                                    </>
                                                    :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("tlcLicense")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "tlcLicense", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Input disabled={this.state.editField !== "tlcLicense"} defaultValue={driver && driver.tlcLicense ? driver.tlcLicense : "--"} onChange={this.updateInput} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Date Of Birth</Label>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "dob" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("dob", driver && driver.dob ? driver.dob : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("dob")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "dob", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Input type="date" disabled={this.state.editField !== "dob"} defaultValue={driver && driver.dob ? driver.dob : "--"} onChange={this.updateInput} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Gender</Label>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "gender" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("gender", driver && driver.gender ? driver.gender : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("gender")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "gender", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        {this.state.editField !== "gender" &&
                                            <Input disabled={this.state.editField !== "gender"} value={driver && driver.gender ? GENDER_LIST.find(gender => driver.gender === gender.value)?.label || "Choose Gender" : "Choose Gender"} />}
                                        {this.state.editField === "gender" &&
                                            <FormGroup className="m-0">
                                                <Input style={{ padding: "5px", width: "100%" }} type="select" value={this.state.value} onChange={this.updateInput} >
                                                    <option value={""}>Choose Gender</option>
                                                    {GENDER_LIST.map((obj, i) =>
                                                        <option key={i} value={obj.value}>{obj.label}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={9}>
                                                <Label>Preferred Language</Label>
                                            </Col>
                                            <Col className="text-right" xs={3}>
                                                {this.state.editField !== "preferredLanguage" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("preferredLanguage", driver && driver.preferredLanguage ? this.getLanguageVal(driver.preferredLanguage) : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("preferredLanguage")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "preferredLanguage", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        {this.state.editField !== "preferredLanguage" &&
                                            <Input disabled={this.state.editField !== "preferredLanguage"} defaultValue={driver && driver.preferredLanguage ? this.getLanguageVal(driver.preferredLanguage) : "--"} />
                                        }
                                        {this.state.editField === "preferredLanguage" &&
                                            <FormGroup>
                                                <Input style={{ padding: "5px", width: "100%" }} type="select" value={this.state.value} onChange={this.updateInput} >
                                                    <option value={this.getLanguageVal(driver.preferredLanguage)}>Choose Preferred Language</option>
                                                    {Object.keys(Languages).map((key, i) =>
                                                        <option key={i} value={key}>{Languages[key].name + " - " + Languages[key].nativeName}</option>
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        }
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Street Address</Label>
                                            </Col>
                                            {/* <Col className="text-right" xs={6}>
                                                {this.state.editField !== "streetAddress" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("streetAddress", driver && driver.streetAddress ? driver.streetAddress : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("streetAddress")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "streetAddress", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col> */}
                                        </Row>
                                        <div>
                                            {this.state.editField !== "streetAddress" &&
                                                <Input disabled={this.state.editField !== "streetAddress"} defaultValue={driver && driver.streetAddress ? driver.streetAddress : "--"} />
                                            }
                                            {this.state.editField === "streetAddress" &&
                                                <FormGroup>
                                                    <SearchPlaces
                                                        onSaveAction={(streetAddress) => {
                                                            if (streetAddress && streetAddress.value) {
                                                                this.setState({ streetAddress: streetAddress.name, value: streetAddress.name })
                                                            } else {
                                                                this.setState({ streetAddress: "", value: "" })
                                                            }
                                                        }}
                                                        initialValue={driver.streetAddress}
                                                        searchType={"address"}
                                                    />
                                                </FormGroup>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>City</Label>
                                            </Col>
                                            {/* <Col className="text-right" xs={6}>
                                                {this.state.editField !== "city" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("city", driver && driver.city ? driver.city : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("city")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "city", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col> */}
                                        </Row>
                                        <div>
                                            {this.state.editField !== "city" &&
                                                <Input disabled={this.state.editField !== "city"} defaultValue={driver && driver.city ? driver.city : "--"} />
                                            }
                                            {this.state.editField === "city" &&
                                                <FormGroup>
                                                    <SearchPlaces
                                                        onSaveAction={(city) => {
                                                            if (city && city.value) {
                                                                this.setState({ city: city.name, value: city.name })
                                                            } else {
                                                                this.setState({ city: "", value: "" })
                                                            }
                                                        }}
                                                        initialValue={driver.city}
                                                        searchType={"city"}
                                                    />
                                                </FormGroup>
                                            }
                                        </div>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>State</Label>
                                            </Col>
                                            {/* <Col className="text-right" xs={6}>
                                                {this.state.editField !== "state" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("state", driver && driver.state ? driver.state : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("state")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "state", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col> */}
                                        </Row>
                                        <div>
                                            {this.state.editField !== "state" &&
                                                <Input disabled={this.state.editField !== "state"} defaultValue={driver && driver.state ? states[driver.state] : "--"} />
                                            }
                                            {this.state.editField === "state" &&
                                                <FormGroup>
                                                    <Input style={{ padding: "5px" }} type="select" value={this.state.value} onChange={this.updateInput} >
                                                        <option value={driver.state}>Choose State</option>
                                                        {Object.keys(states).map((state, i) =>
                                                            <option key={i} value={state}>{state + " - " + states[state]}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            }
                                        </div>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Zip Code</Label>
                                            </Col>
                                            {/* <Col className="text-right" xs={6}>
                                                {this.state.editField !== "zipCode" ?
                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("zipCode", driver && driver.zipCode ? driver.zipCode : "")}></i> :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("zipCode")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "zipCode", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col> */}
                                        </Row>
                                        <Input disabled={this.state.editField !== "zipCode"} defaultValue={driver && driver.zipCode ? driver.zipCode : "--"} onChange={this.updateInput} />
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Label>Branch</Label>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                <i className="fa fa-lg fa-pencil-square" id={"branchEdit"} onClick={() => this.setState({ openModal: "branchEdit" })}></i>
                                            </Col>
                                            {this.state.openModal === "branchEdit" &&
                                                <UpdateDriverInfo
                                                    driverId={driver.id} title="Edit Driver's Branch" name="branch" value={driver && driver.branch ? driver.branch.id : ""}
                                                    target={"branchEdit"} id="branchEdit" type="text" open={this.state.openModal === "branchEdit"}
                                                    handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery} tenantId={driver.branch && driver.branch.tenant.id}
                                                />
                                            }
                                        </Row>
                                        {/* <span id="branch">Branch&nbsp;&nbsp; {this.props.currentUser && <i onClick={() => this.toggleModal("branchEdit")} className="fa fa-pencil-square" aria-hidden="true"></i>}&nbsp;&nbsp;&nbsp;</span> */}
                                        <Input disabled={this.state.openModal !== "branchEdit"} defaultValue={driver && driver.branch ? driver.branch.name : "--"} />
                                    </Col>
                                </Row>
                                <Row>
                                    {driver.folderId ? <>
                                        <Col xs={3}>
                                            <Row>
                                                <Col xs={6}>
                                                    <Label>Folder ID</Label>
                                                </Col>
                                                <Col className="text-right" xs={6}>
                                                    {this.state.editField !== "folderId" ?
                                                        <>
                                                            {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_folder_id") &&
                                                                <>
                                                                    <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("folderId", driver && driver.folderId ? driver.folderId : "")}></i>&nbsp;&nbsp;
                                                                </>
                                                            }
                                                            <a target="_blank" href={`https://drive.google.com/drive/u/0/folders/${driver.folderId}`}> <i className="fa fa-lg fa-external-link" id={"folderId"} /></a>&nbsp;&nbsp;
                                                            <UncontrolledTooltip target="folderId" placement="top">Link To Folder</UncontrolledTooltip>
                                                            <CopyToClipboard textToCopy={driver && driver.folderId ? driver.folderId : ""} displayText={"folder id"} />
                                                        </>
                                                        :
                                                        <div>
                                                            <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("folderId")}></i>&nbsp;&nbsp;
                                                            <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "folderId", this.state.value)}></i>
                                                        </div>
                                                    }

                                                </Col>
                                            </Row>
                                            <Input disabled={this.state.editField !== "folderId"} defaultValue={driver && driver.folderId ? driver.folderId : "--"} onChange={this.updateInput} />
                                        </Col>
                                    </> : <Col>
                                        <Label>Folder ID</Label>
                                        <div className="input-alternative-box" style={{ color: "red" }}>No longer using Google Drive for this driver documents.</div>
                                    </Col>
                                    }
                                    <Col xs={3}>
                                        <Label>Created At</Label>
                                        <div className="input-alternative-box" style={{ color: "#495057" }}><DatetimeRenderer datetime={driver && driver.dateAdded ? driver.dateAdded : "--"} /></div>
                                    </Col>
                                    <Col xs={3}>
                                        {(driver.branch && driver.branch.branchSettings && driver.branch.branchSettings.edges.length > 0 && driver.branch.branchSettings.edges.some((branchSettings) => branchSettings.node.value === "true" && branchSettings.node.key.name === "ENABLE_PALENCA_WORKFLOW")) ?
                                            <>
                                                <Label>Palenca Link</Label>
                                                <div className="input-alternative-box">{driver.palencaUserId ?
                                                    driver.palencaUserId
                                                    : 'Not on Palenca'}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <Label>Argyle Link</Label>
                                                <div className="input-alternative-box">{driver.argyleAccountId ?
                                                    <a href={`https://console.argyle.com/connections/${driver.argyleAccountId}`} target="_blank">{driver.argyleAccountId}</a>
                                                    : 'Not on Argyle'}
                                                </div>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={3}>
                                        <Label>Stripe ID</Label>
                                        <Input disabled={true} defaultValue={driver && driver.stripeId ? driver.stripeId : "--"} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Label className="columnTitle"><span id="driverNotesHistory">Notes&nbsp;&nbsp;<i onClick={() => this.toggleModal("driverNotesHistory")} className={"fa fa-pencil-square-o"}></i></span></Label>
                                        {driver && this.state.openModal === "driverNotesHistory" &&
                                            <NotesHistory
                                                driverId={driver.id}
                                                id={"notes"}
                                                target={"driverNotesHistory"}
                                                noteType="CUSTOMER SERVICE"
                                                title="CS Notes History"
                                                open={this.state.openModal === "driverNotesHistory"}
                                                name={"notes"}
                                                handleClose={() => this.toggleModal("")}
                                                objectType={"driverNotes"}
                                                refetchQuery={() => window.location.reload()}
                                            />
                                        }
                                        <Input disabled={this.state.openModal !== "driverNotesEdit"} defaultValue={driver.notes ? driver.notes : "--"} />
                                    </Col>

                                    {legacySystemIds && legacySystemIds.length > 0 ? legacySystemIds.map((legacyId, i) =>
                                        <Col xs={3}>
                                            <Row>
                                                <Col>
                                                    <Label>{legacyId.name} (ID)</Label>
                                                </Col>
                                                <Col className="text-right">
                                                    {this.state.openModal === "removeLegacySystemId" + i &&
                                                        <ConfirmationPopup
                                                            open={this.state.openModal === "removeLegacySystemId" + i}
                                                            message={"Are you sure you want to remove the system Id?"}
                                                            loading={this.state.loading}
                                                            handleClose={() => this.toggleModal("")}
                                                            action={"Yes"}
                                                            confirmAction={() => this.removeLegacySystemId(i)}
                                                            color="danger"
                                                            error={this.state.error ? this.state.error : ""}
                                                            success={this.state.insuranceCreated ? "Action Performed Successfully" : ""}
                                                        />
                                                    }
                                                    {this.state.editField === "editSystemId" + i ? <>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("editSystemId" + i)}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.editLegacySystemId(i, this.state.value)}></i>
                                                    </> :
                                                        <>
                                                            <i id="legacySystemIds" className="fa fa-minus-square text-danger" aria-hidden="true" onClick={() => this.toggleModal("removeLegacySystemId" + i)}></i>&nbsp;&nbsp;
                                                            <i id="legacySystemIds" className="fa fa-pencil-square text-warning" aria-hidden="true" onClick={() => this.editField("editSystemId" + i, legacyId.value)}></i>
                                                            {(legacySystemIds.length - 1) === i && <>&nbsp;&nbsp;<i onClick={() => this.toggleModal("legacySystemIds")} id="legacySystemIds" className="fa fa-plus-square text-success" aria-hidden="true"></i></>}
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input type="text" disabled={this.state.editField !== "editSystemId" + i} defaultValue={legacyId.value} onChange={this.updateInput} />
                                                </Col>
                                            </Row>
                                        </Col>)
                                        : <Col>
                                            <Row>
                                                <Col>
                                                    <Label>Legacy System Id</Label>
                                                </Col>
                                                <Col className="text-right">
                                                    <i onClick={() => this.toggleModal("legacySystemIds")} id="legacySystemIds" className="fa fa-plus-square text-success" aria-hidden="true"></i>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input type="text" disabled={true} defaultValue={"--"} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    <Col xs={3}>
                                        <Row>
                                            <Col xs={6}>
                                                <Label>Tax Id</Label>
                                            </Col>
                                            <Col className="text-right" xs={6}>
                                                {this.state.editField !== "taxId" ?
                                                    <>
                                                        <i className="fa fa-lg fa-pencil-square" onClick={() => this.editField("taxId", driver && driver.taxId ? driver.taxId : "")}></i>&nbsp;&nbsp;
                                                        <CopyToClipboard textToCopy={driver && driver.taxId ? driver.taxId : ""} displayText={"tax id"} />
                                                    </>
                                                    :
                                                    <div>
                                                        <i className="fa fa-solid fa-lg fa-times" onClick={() => this.editField("taxId")}></i>&nbsp;&nbsp;
                                                        <i className="fa fa-solid fa-lg fa-check" onClick={() => this.updateDriver(driver.id, "taxId", this.state.value)}></i>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Input disabled={this.state.editField !== "taxId"} defaultValue={driver && driver.taxId ? driver.taxId : "--"} onChange={this.updateInput} />
                                    </Col>
                                    <Col xs={3}>
                                        {/*  */}
                                        <Label className="columnTitle">
                                            <span id="allowNotification">Allow notifications&nbsp;&nbsp;
                                                {this.props.hasPermissions && this.props.hasPermissions.includes("change_driver") &&
                                                    <i onClick={() => this.toggleModal("allowNotificationEdit")} className="fa fa-pencil-square" aria-hidden="true"></i>}
                                            </span>
                                        </Label>
                                        {driver && this.state.openModal === "allowNotificationEdit" &&
                                            <UpdateDriverInfo driverId={driver.id} title="Edit Allow Notifications" name="allowNotification" value={driver.allowNotification}
                                                target={"allowNotification"} id="allowNotification" type="boolean" open={this.state.openModal === "allowNotificationEdit"}
                                                handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                            />
                                        }
                                        <p className="columnContent">
                                            <i className={driver && driver.allowNotification === true ? "fa fa-toggle-on switch text-success" : "fa fa-toggle-off switch text-danger"} aria-hidden="true"></i>
                                        </p>
                                    </Col>
                                    {driver && this.state.openModal === "legacySystemIds" &&
                                        <UpdateDriverInfo driverId={driver.id} title="Edit Legacy System Id" name="legacySystemIds" value={legacySystemIds}
                                            target={"legacySystemIds"} open={this.state.openModal === "legacySystemIds"}
                                            handleClose={() => this.toggleModal("")} refetchDriverQuery={this.props.refetchDriverQuery}
                                        />
                                    }
                                    <Col xs={6}>
                                        <Label>Base Status </Label>
                                        {this.state.openModal === "UploadOrCreateBaseStatus" && <UploadOrCreateBaseStatus handleClose={() => this.toggleModal("")} open={this.state.openModal === "UploadOrCreateBaseStatus"} driver={driver} refetchQuery={this.props.refetchDriverQuery} />}
                                        <p className="columnContent">
                                            {driver && driver.driverbasestatusSet && driver.driverbasestatusSet.edges && driver.driverbasestatusSet.edges.length > 0 &&
                                                driver.driverbasestatusSet.edges.map((item, i) =>
                                                    <><Badge key={i} size="sm" className={"black"}>{item.node.base} : {item.node.status}</Badge>&nbsp;</>
                                                )}
                                            &nbsp;<Badge color="success" onClick={() => this.toggleModal("UploadOrCreateBaseStatus")}><i className="fa fa-lg fa-plus" aria-hidden="true" /></Badge>
                                        </p>
                                    </Col>

                                </Row>
                            </div >
                            <Row>
                                <Col>
                                    <h4>CUSTOMER IDENTIFIERS</h4>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Email" ? "active" : "inactive"}>
                                            <NavLink className="active navItem">
                                                DOCUMENTS
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className={`info-container ${this.props.customerIdentifiers[0] ? 'CustomerIdentifierContainer' : ""}`} style={{ borderTopLeftRadius: "0px" }}>
                                        <Row>
                                            <Col xs={4}><Label>Type</Label></Col>
                                            <Col xs={3}><Label>Status</Label></Col>
                                            <Col xs={5}><Label>Upload Date</Label></Col>
                                        </Row>
                                        {this.props.customerIdentifiers[0] ? this.props.customerIdentifiers.map((item,) =>
                                            <div onClick={() => this.toggleModalDocument("viewImage", "", "", item)} style={{ cursor: "pointer" }} className="CustomerIdentifierRow">
                                                <Row key={item.id}>
                                                    <Col xs={4} >
                                                        <p>{item.identifierType.typeName}</p>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <p>{item.status}</p>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <DatetimeRenderer datetime={item.dateAdded ? item.dateAdded : "--"} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ) :
                                            <Row> <Col>No customer identifiers found! </Col></Row>
                                        }
                                        {this.state.openModal === "viewImage" &&
                                            <ViewDocumentImage
                                                title={this.state.modalTitle}
                                                open={this.state.openModal === "viewImage"}
                                                handleClose={() => this.toggleModal("")}
                                                img={this.state.selectedDocumentUrl}
                                                identifier={this.state.selectedIdentifier}
                                            />
                                        }
                                    </div>
                                </Col>
                                <Col>
                                    <h4>EXTERNAL SYSTEM IDs</h4>
                                    <Nav tabs>
                                        <NavItem className={"active"}>
                                            <NavLink className="active navItem">
                                                IDENTIFIERS
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", maxHeight: "300px", overflow: "auto" }}>
                                        <Row>
                                            <Col xs={3}><Label>Date Added</Label></Col>
                                            <Col xs={3}><Label>System Info</Label></Col>
                                            <Col xs={2} className="text-center"><Label>Is Default</Label></Col>
                                            <Col xs={2} className="text-center"><Label>Is Archived</Label></Col>
                                            <Col xs={2} className="text-center"><Label>Action</Label></Col>
                                        </Row>

                                        {this.props.allExternalSystemIdentifiers && this.props.allExternalSystemIdentifiers.length > 0 ? this.props.allExternalSystemIdentifiers.map((item) =>
                                            <Row key={item.id}>
                                                <Col xs={3}>
                                                    <p>{item.dateAdded ? moment(item.dateAdded).format('MMM D, YYYY') : "--"}</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <p>
                                                        {item.systemName && item.systemName.toLowerCase() === "stripe" ? <StripeLogo /> : <TokuLogo />}<br />
                                                        {item.externalId ? `${item.externalId.substring(0, 5)}*****` : "--"}&nbsp;<CopyToClipboard textToCopy={item.externalId ? item.externalId : ""} displayText={"external ID"} />
                                                    </p>
                                                </Col>
                                                <Col xs={2} className="text-center">
                                                    <p><i style={{ color: item.isDefault ? "green" : "red" }} class="fa fa-circle" aria-hidden="true"></i></p>
                                                </Col>
                                                <Col xs={2} className="text-center">
                                                    <p><i style={{ color: item.isArchived ? "green" : "red" }} class="fa fa-circle" aria-hidden="true"></i></p>
                                                </Col>
                                                <Col xs={2} className="text-center">
                                                    <p><i className={"fa fa-lg fa-pencil-square"} aria-hidden="true" onClick={() => this.toggleModal("externalSystemIdentifierModal" + item.pk)} ></i></p>
                                                    {this.state.openModal === "externalSystemIdentifierModal" + item.pk &&
                                                        <ExternalSystemIdentifierModal
                                                            open={this.state.openModal === "externalSystemIdentifierModal" + item.pk}
                                                            handleClose={() => this.toggleModal("")}
                                                            isUpdate={true}
                                                            identifierId={item.id}
                                                            refetchQuery={this.props.refetchIdentifiers}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        ) :
                                            <Row><Col><p>No external system identifiers found!</p></Col></Row>
                                        }
                                        <div className="d-flex justify-content-end">
                                            <Button className="btn btn-secondary" size="sm" onClick={() => this.toggleModal("addExternalSystemIdentifier")}><i className="fa fa-plus" aria-hidden="true" /> Add Identifier </Button>
                                        </div>
                                        {this.state.openModal === "addExternalSystemIdentifier" &&
                                            <ExternalSystemIdentifierModal
                                                open={this.state.openModal === "addExternalSystemIdentifier"}
                                                handleClose={() => this.toggleModal("")}
                                                isUpdate={false}
                                                customerId={driver.id}
                                                refetchQuery={this.props.refetchIdentifiers}
                                            />
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <h4>CUSTOMER HANDLES</h4>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Email" ? "active" : "inactive"}>
                                            <NavLink className={this.state.customerHandleSubsection === "Email" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("customerHandleSubsection", "Email")}>
                                                EMAIL
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Phone" ? "active" : "inactive"}>
                                            <NavLink className={this.state.customerHandleSubsection === "Phone" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("customerHandleSubsection", "Phone")}>
                                                Phone
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.customerHandleSubsection === "Email" && <>
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col xs={4}><Label>Email</Label></Col>
                                                <Col xs={3}><Label>Is Primary</Label></Col>
                                                <Col xs={3}><Label>Is Archived</Label></Col>
                                                <Col xs={2}><Label>Action</Label></Col>
                                            </Row>
                                            {driver.customerhandleSet && driver.customerhandleSet.edges && driver.customerhandleSet.edges.length > 0 && driver.customerhandleSet.edges.filter(item => item.node.type === "EMAIL").length > 0 ?
                                                driver.customerhandleSet.edges.filter(item => item.node.type === "EMAIL").sort((a, b) => (b.node.isPrimary - a.node.isPrimary)).map((item, handleCount) =>
                                                    <Row key={item.node.id}>
                                                        <Col xs={4}>
                                                            <a href={"mailto:" + item.node.handle}>{item.node.handle ? item.node.handle : "--"}</a>{item.node.title && ` (${item.node.title})`}
                                                            {item.node.isDuplicate && <>
                                                                &nbsp;&nbsp;<i id={"handleDulicate" + handleCount} className="fa fa-exclamation-triangle text-danger" aria-hidden="true" />
                                                                <UncontrolledTooltip target={"handleDulicate" + handleCount} placement="top">Duplicate Email</UncontrolledTooltip>
                                                            </>}
                                                        </Col>
                                                        <Col xs={3}>
                                                            <p className="columnContent mb-0">
                                                                <span>
                                                                    <div>
                                                                        <div className="toggle-switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="toggle-switch-checkbox"
                                                                                id={"primaryHandle"}
                                                                                name={"primaryHandle"}
                                                                                checked={!item.node.isPrimary}
                                                                            />
                                                                            <label className="toggle-switch-label" htmlFor={"primaryHandle"}>
                                                                                <span className="toggle-switch-inner" />
                                                                                <span className="toggle-switch-switch" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <p className="columnContent mb-0">
                                                                <span>
                                                                    <div>
                                                                        <div className="toggle-switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="toggle-switch-checkbox"
                                                                                id={"archivedHandle"}
                                                                                name={"archivedHandle"}
                                                                                checked={!item.node.isArchived}
                                                                            />
                                                                            <label className="toggle-switch-label" htmlFor={"archivedHandle"}>
                                                                                <span className="toggle-switch-inner" />
                                                                                <span className="toggle-switch-switch" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col xs={2} className="text-center">
                                                            <i className={"fa fa-lg fa-pencil-square"} aria-hidden="true" onClick={() => this.toggleModal("editEmailHandle" + item.node.pk)} ></i>
                                                            {this.state.openModal === "editEmailHandle" + item.node.pk &&
                                                                <CustomerHandleModal
                                                                    handleType={"email"}
                                                                    customerHandleId={item.node.id}
                                                                    isUpdate={true}
                                                                    open={this.state.openModal === "editEmailHandle" + item.node.pk}
                                                                    handleClose={() => this.toggleModal("")}
                                                                    refetchQuery={this.refetchQuery}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                ) :
                                                <Row> <Col>No email handle found! </Col></Row>
                                            }
                                            <div className="d-flex justify-content-end">
                                                <Button className="btn btn-secondary" size="sm" onClick={() => this.toggleModal("addEmail")}><i className="fa fa-plus" aria-hidden="true" /> Add Email </Button></div>
                                            {this.state.openModal === "addEmail" &&
                                                <CustomerHandleModal
                                                    handleType={"email"}
                                                    customerId={driver.id}
                                                    isUpdate={false}
                                                    open={this.state.openModal === "addEmail"}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchQuery={this.refetchQuery}
                                                />
                                            }
                                        </div>
                                    </>
                                    }
                                    {this.state.customerHandleSubsection === "Phone" && <>
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col xs={4}><Label>Phone</Label></Col>
                                                <Col xs={3}><Label>Is Primary</Label></Col>
                                                <Col xs={3}><Label>Is Archived</Label></Col>
                                                <Col xs={2}><Label>Action</Label></Col>
                                            </Row>
                                            {driver.customerhandleSet && driver.customerhandleSet.edges && driver.customerhandleSet.edges.length > 0 && driver.customerhandleSet.edges.filter(item => item.node.type === "PHONE").length > 0 ?
                                                driver.customerhandleSet.edges.filter(item => item.node.type === "PHONE").sort((a, b) => (b.node.isPrimary - a.node.isPrimary)).map((item, handleCount) =>
                                                    <Row key={item.node.id}>
                                                        <Col xs={4}>
                                                            <a href={"tel:" + item.node.handle}>{item.node.handle ? item.node.handle : "--"}</a>{item.node.title && ` (${item.node.title})`}
                                                            {item.node.isDuplicate && <>
                                                                &nbsp;&nbsp;<i id={"handleDulicate" + handleCount} className="fa fa-exclamation-triangle text-danger" aria-hidden="true" />
                                                                <UncontrolledTooltip target={"handleDulicate" + handleCount} placement="top">Duplicate Phone</UncontrolledTooltip>
                                                            </>}
                                                        </Col>
                                                        <Col xs={3}>
                                                            <p className="columnContent mb-0">
                                                                <span>
                                                                    <div>
                                                                        <div className="toggle-switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="toggle-switch-checkbox"
                                                                                id={"primaryHandle"}
                                                                                name={"primaryHandle"}
                                                                                checked={!item.node.isPrimary}
                                                                            />
                                                                            <label className="toggle-switch-label" htmlFor={"primaryHandle"}>
                                                                                <span className="toggle-switch-inner" />
                                                                                <span className="toggle-switch-switch" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <p className="columnContent mb-0">
                                                                <span>
                                                                    <div>
                                                                        <div className="toggle-switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="toggle-switch-checkbox"
                                                                                id={"archivedHandle"}
                                                                                name={"archivedHandle"}
                                                                                checked={!item.node.isArchived}
                                                                            />
                                                                            <label className="toggle-switch-label" htmlFor={"archivedHandle"}>
                                                                                <span className="toggle-switch-inner" />
                                                                                <span className="toggle-switch-switch" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col xs={2} className="text-center">
                                                            <i className={"fa fa-lg fa-pencil-square"} aria-hidden="true" onClick={() => this.toggleModal("editPhoneHandle" + item.node.pk)} ></i>
                                                            {this.state.openModal === "editPhoneHandle" + item.node.pk &&
                                                                <CustomerHandleModal
                                                                    handleType={"phone"}
                                                                    customerHandleId={item.node.id}
                                                                    isUpdate={true}
                                                                    open={this.state.openModal === "editPhoneHandle" + item.node.pk}
                                                                    handleClose={() => this.toggleModal("")}
                                                                    refetchQuery={this.refetchQuery}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                ) :
                                                <Row> <Col>No phone handle found! </Col></Row>
                                            }
                                            <div className="d-flex justify-content-end">
                                                <Button className="btn btn-secondary" size="sm" onClick={() => this.toggleModal("addPhone")}><i className="fa fa-plus" aria-hidden="true" /> Add Phone </Button></div>
                                            {this.state.openModal === "addPhone" &&
                                                <CustomerHandleModal
                                                    handleType={"phone"}
                                                    customerId={driver.id}
                                                    isUpdate={false}
                                                    open={this.state.openModal === "addPhone"}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchQuery={this.refetchQuery}
                                                    branchCountry={branchCountry}
                                                />
                                            }
                                        </div></>
                                    }
                                </Col>
                                <Col>
                                    <h4>ADDRESS</h4>
                                    <Nav tabs>
                                        <NavItem className={"active"}>
                                            <NavLink className={"active navItem"}>
                                                ADDRESS
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {driver && driver.id && this.props.apolloClient &&
                                        <CreateAddress
                                            driverId={driver.id}
                                            driverName={driver.name}
                                            refetchDriverQuery={this.props.refetchDriverQuery}
                                            client={this.props.apolloClient}
                                            open={this.state.openModal === "CreateAddress"}
                                            handleClose={() => { this.toggleModal("") }}
                                        />
                                    }

                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", maxHeight: "300px", overflow: "auto" }}>
                                        <Row>
                                            <Col xs={3}><Label>Title</Label></Col>
                                            <Col xs={5}><Label>Address</Label></Col>
                                            <Col xs={2}><Label>Zip Code</Label></Col>
                                            <Col xs={2}><Label>Action</Label></Col>
                                        </Row>

                                        {driver && driver.addressSet && driver.addressSet.edges && driver.addressSet.edges.length > 0 &&
                                            driver.addressSet.edges.map((address, i) => {
                                                address = address.node
                                                return (
                                                    <Row key={i}>
                                                        <Col xs={3}>
                                                            <p className="columnContent">{address.title}</p>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <p className="columnContent"><small>{address.fullAddress}</small></p>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <p className="columnContent">{address.postalCode}</p>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <p><i className="fa fa-lg fa-pencil-square" onClick={() => this.toggleModal("addressEdit" + i)}></i></p>
                                                            {this.state.openModal === "addressEdit" + i &&
                                                                <UpdateAddress
                                                                    address={address}
                                                                    refetchDriverQuery={this.props.refetchDriverQuery}
                                                                    client={this.props.appolloClient}
                                                                    open={this.state.openModal === "addressEdit" + i}
                                                                    handleClose={() => { this.toggleModal("") }}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                        {/* Backward compatibility, show original address from driver table */}
                                        {/* {driver && driver.streetAddress &&
                                            <Col xs={12}>
                                                <Label>Address {driver && driver.addressSet && driver.addressSet.edges && driver.addressSet.edges.length && Number(driver.addressSet.edges.length) + 1}</Label>
                                                <p className="columnContent">{driver.streetAddress + " " + (driver.city || "") + " " + (driver.state || "") + " " + (driver.zipcode || "")}</p>
                                            </Col>
                                        } */}
                                        <Col xs={12} className="d-flex justify-content-end"> <Button className="btn btn-secondary" size="sm" onClick={() => this.toggleModal("CreateAddress")}><i className="fa fa-plus" aria-hidden="true" /> NEW </Button></Col>

                                    </div>
                                </Col>
                            </Row>
                            <br /><br />
                            <Row>
                                <Col>
                                    <h4>DO NOT RENT</h4>
                                    <Nav tabs>
                                        <NavItem className={"active"}>
                                            <NavLink className={"active navItem"}>
                                                DNR
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", maxHeight: "300px", overflow: "auto" }}>
                                        <Row className="mb-1">
                                            <Col xs={6}><Label>Reason</Label></Col>
                                            <Col xs={3}><Label>Date Added</Label></Col>
                                            <Col xs={2}><Label>Archived</Label></Col>
                                            <Col xs={1}><Label>Action</Label></Col>
                                        </Row>
                                        {/* Iagami-React 18 Upgradation Changes */}
                                        {driver.blockedcustomerSet && driver.blockedcustomerSet.edges && driver.blockedcustomerSet.edges.length > 0 ? ([...driver.blockedcustomerSet.edges].sort((a, b) => new Date(b.node.dateAdded) - new Date(a.node.dateAdded)).map((item, i) =>
                                            <><Row key={item.node.id}>
                                                <Col xs={6} >
                                                    <p id={"blockedCustomer" + i} className="columnContent mb-0">{item.node.reason ? item.node.reason.name : "--"}</p>
                                                    <div className="shortText" style={{ maxWidth: "30rem" }}>
                                                        <small id={"notes" + i}>Notes: {item.node.notes ? item.node.notes : "--"}</small>
                                                    </div>
                                                    {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"blockedCustomer" + i} placement="top" autohide={false} className="bos-custom-tooltip">
                                                        <label>{item.node.reason ? item.node.reason.name : "--"}</label>
                                                        <p className="bos-custom-tooltip-body">
                                                            <b>Date Added:</b> {item.node.dateAdded ? <DatetimeRenderer datetime={item.node.dateAdded} format="ddd, MMM D, YYYY" /> : "--"} <br />
                                                            <b>Blocked By:</b> {item.node.blockedBy ? item.node.blockedBy.firstName ? item.node.blockedBy.firstName + " " + item.node.blockedBy.lastName : item.node.blockedBy.username : "--"}<br />
                                                            <b>Archived By:</b> {item.node.archivedBy ? item.node.archivedBy.firstName ? item.node.archivedBy.firstName + " " + item.node.archivedBy.lastName : item.node.archivedBy.username : "--"} <br />
                                                            <b>Archive Date:</b> {item.node.archivedDate ? <DatetimeRenderer datetime={item.node.archivedDate} format="ddd, MMM D, YYYY" /> : "--"} <br />
                                                        </p>
                                                        <span className="bos-custom-tooltip-footer">
                                                            <Button onClick={() => this.toggleModal("UpdateDNRModal" + item.node.pk)}>UPDATE</Button>
                                                        </span>
                                                    </UncontrolledTooltip>}
                                                </Col>
                                                <Col xs={3}>
                                                    <p className="columnContent mb-0"> <DatetimeRenderer datetime={item.node.dateAdded} format="ddd, MMM D, YYYY" /></p>
                                                </Col>
                                                <Col xs={2}>
                                                    <p className="columnContent mb-0">
                                                        <span>
                                                            <i style={{ color: !item.node.isArchived === true ? "green" : "red" }} className={item.node.isArchived === true ? "fa fa-toggle-on switch" : "fa fa-toggle-off switch"} aria-hidden="true"></i>
                                                        </span>
                                                    </p>
                                                </Col>
                                                <Col xs={1}>
                                                    <i className={"fa fa-lg fa-pencil-square"} aria-hidden="true" onClick={() => this.toggleModal("UpdateDNRModal" + item.node.pk)} ></i>
                                                    {this.state.openModal === "UpdateDNRModal" + item.node.pk &&
                                                        <UpdateDNR
                                                            handleClose={() => this.toggleModal("")}
                                                            open={this.state.openModal === "UpdateDNRModal" + item.node.pk}
                                                            blockedCustomerId={item.node.id}
                                                            refetchQuery={this.props.refetchDriverQuery}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                                <hr />
                                            </>
                                        )) : <Row><Col><p className="columnContent mb-0">No Record Found!</p></Col></Row>
                                        }
                                    </div>

                                </Col>
                                <Col>
                                    <h4>TAGS</h4>
                                    <Nav tabs>
                                        <NavItem className={"active"}>
                                            <NavLink className={"active navItem"}>
                                                TAGS
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container">
                                        <Row>
                                            {driver && driver.tags && driver.tags.map((tag, i) => (
                                                <><Badge key={i} size="sm" style={{ background: `#${tag.color ? tag.color : 'black'}` }}>{tag.name}</Badge>&nbsp;</>
                                            ))}&nbsp;
                                            <Badge color="success" onClick={() => this.toggleModal("editTag")} id={"editTag"}>
                                                <i className="fa fa-lg fa-plus" aria-hidden="true" />
                                            </Badge>
                                            <UncontrolledTooltip target="editTag" placement="top">Add Or Remove Driver Tag</UncontrolledTooltip>
                                            {this.state.openModal === "editTag" &&
                                                <DriverUpdateTagsPopover
                                                    isOpen={this.state.openModal === "editTag"}
                                                    handleClose={() => this.toggleModal("")}
                                                    refetchQuery={() => { return true }}
                                                    driverId={this.props.driver.id}
                                                    driverTags={this.props.driver.tags}
                                                    target="editTag"
                                                />
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br /><br />
                            <Row>
                                <Col xs={6}>
                                    <h4>DRIVING PROFILE</h4>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "bosSummary" ? "active" : "inactive"}>
                                            <NavLink className={this.state.customerSummarySubsection === "bosSummary" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("customerSummarySubsection", "bosSummary")}>
                                                BOS SUMMARY
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "lightRating" ? "active" : "inactive"}>
                                            <NavLink className={this.state.customerSummarySubsection === "lightRating" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("customerSummarySubsection", "lightRating")}>
                                                BEHAVIOR SCORE
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.customerSummarySubsection === "bosSummary" ? <div className="info-container">
                                        {driver && driver.driverRecord ?
                                            driver.driverRecord.map((record, ii) =>
                                                <Row key={ii}>
                                                    <Col>
                                                        <p style={{ fontWeight: "600" }}>{record.name ? record.name : ""}</p>
                                                    </Col>
                                                    <Col>
                                                        <p style={{ fontWeight: "600" }}>{record.value ? record.value : 0}</p>
                                                    </Col>
                                                </Row>
                                            ) : <p>No Car Return Charges Found</p>
                                        }
                                    </div> : <div className="info-container">
                                        {driver && driver.customerRating ? <>
                                            <Row>
                                                <Col xs={4}><Label>Weekend Date</Label></Col>
                                                <Col xs={4}><Label>Percentile</Label></Col>
                                                <Col xs={4}><Label>Traffic Light Rating</Label></Col>
                                            </Row>
                                            {driver.customerRating.map((record, ii) =>
                                                <Row key={ii}>
                                                    <Col>
                                                        <p>{record.weekendDate ? <DatetimeRenderer datetime={record.weekendDate} /> : "--"}</p>
                                                    </Col>
                                                    <Col>
                                                        <p>{record.percentile ? record.percentile : "--"}</p>
                                                    </Col>
                                                    <Col>
                                                        <p>{record.trafficLightRating ? record.trafficLightRating : "--"}</p>
                                                    </Col>
                                                </Row>
                                            )} </> : <p>No Customer Rating Found From EDW</p>
                                        }
                                    </div>}
                                </Col>
                                {driver && driver.mobileappdriver && driver.mobileappdriver.active && <Col xs={6}>
                                    <h4>MOBILE APP INFO</h4>
                                    <Nav tabs>
                                        <NavItem className={"active"}>
                                            <NavLink className={"active navItem"}>
                                                MOBILE APP INFO
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container">
                                        <Row>
                                            <Col>
                                                <p style={{ fontWeight: "600" }}>Active Device Id</p>
                                            </Col>
                                            <Col>
                                                <p>{driver.mobileappdriver.activeDeviceId}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p style={{ fontWeight: "600" }}>Active Device OS</p>
                                            </Col>
                                            <Col>
                                                <p>
                                                    {driver.mobileappdriver.activeDeviceOs &&
                                                        <i
                                                            id={"deviceOS"}
                                                            className={driver.mobileappdriver.activeDeviceOs.toLowerCase().includes("android") ? "fa fa-android fa-2x" : "fa fa-apple fa-2x"}
                                                            style={{ color: driver.mobileappdriver.activeDeviceOs.toLowerCase().includes("android") ? "green" : "black" }}
                                                            aria-hidden="true"
                                                        ></i>}&nbsp;&nbsp;
                                                    {driver.mobileappdriver.activeDeviceOs && driver.mobileappdriver.activeDeviceOs}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p style={{ fontWeight: "600" }}>Last Login</p>
                                            </Col>
                                            <Col>
                                                <p><DatetimeRenderer datetime={driver.mobileappdriver.lastLogin} /></p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p style={{ fontWeight: "600" }}>Recent Location</p>
                                            </Col>
                                            <Col>
                                                <p>
                                                    <i id={"driverLocation"} class="fa fa-street-view fa-2x" style={{ color: "red" }} aria-hidden="true" onClick={() => this.toggleModal("driverLocation")}></i>
                                                    <UncontrolledTooltip target={"driverLocation"} placement="top">Locate Driver</UncontrolledTooltip>
                                                    {this.state.openModal === "driverLocation" &&
                                                        <Popover placement="bottom" isOpen={this.state.openModal === "driverLocation"} target={"driverLocation"} toggle={() => this.setState({ openModal: "" })} style={{ width: 350 }}>
                                                            <PopoverHeader>Most Recent Driver's Location</PopoverHeader>
                                                            <PopoverBody>
                                                                {driver && driver.geoLocation && driver.geoLocation.latitude && driver.geoLocation.longitude ? <>
                                                                    <Label>Last location fetched at: <DatetimeRenderer datetime={driver.geoLocation.timestamp} /></Label>
                                                                    <GoogleMapComponent
                                                                        isMarkerShown
                                                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDg4oSLbYS6boBTyYBWHmpIOUU__8Mfh4I&v=3.exp&libraries=geometry,drawing,places"
                                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                                        containerElement={<div style={{ height: `300px` }} />}
                                                                        mapElement={<div style={{ height: `100%` }} />}
                                                                        driverlatitude={driver.geoLocation.latitude}
                                                                        driverlongitude={driver.geoLocation.longitude}
                                                                    />
                                                                </> : "Driver Location Not Found!"
                                                                }
                                                            </PopoverBody>
                                                        </Popover>
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>}
                            </Row>
                        </>
                        :
                        <Label>No Information Found Against This Driver</Label>
                }
            </div>
        )
    }
}


export default graphql(HasPermissionsQuery, {
    options: () => ({
        variables: {
            userPermissions: ["custom_can_edit_folder_id", "change_driver"],
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    }),
    props({ data: { hasPermissions, variables } }) {
        return { hasPermissions, variables }
    },
})(
    graphql(PaymentScheduleQuery, {
        options: ({ id }) => ({ variables: { driverId: id } }),
        props({ data: { paymentSchedule, variables } }) {
            return { paymentSchedule, variables }
        },
    })(
        graphql(DriverDetailQuery, {
            options: ({ id }) => ({
                variables: {
                    id,
                    settingsKeyNames: ["ENABLE_PALENCA_WORKFLOW"]
                },
                fetchPolicy: 'cache-first'
                ,
            }),
            props({ data: { driver, loading, fetchMore, variables } }) {
                return {
                    driver,
                    driverLoading: loading,
                    variables,
                    refetchDriverQuery: (e) => {
                        return fetchMore({
                            variables: {
                                ...variables,
                            },
                            updateQuery: (previousResult, { fetchMoreResult }) => {
                                return { driver: fetchMoreResult.driver }
                            },
                        })
                    },
                }
            },
        })(
            graphql(CustomerIdentifiers, {
                options: ({ id }) => ({
                    variables: { customerId: id },
                    fetchPolicy: 'cache-first',
                }),
                props({ data: { customerIdentifiers, loading, error } }) {
                    return {
                        customerIdentifiers,
                        customerIdentifiersLoading: loading,
                        customerIdentifiersError: error,
                    }
                },
            })(
                graphql(AllExternalIdentifiers, {
                    options: ({ id }) => ({
                        variables: { customerId: id },
                        fetchPolicy: 'cache-first',
                    }),
                    props({ data: { allExternalSystemIdentifiers, loading, error, fetchMore, variables } }) {
                        return {
                            allExternalSystemIdentifiers,
                            externalSystemIdentifiersLoading: loading,
                            externalSystemIdentifiersError: error,
                            refetchIdentifiers: (e) => {
                                return fetchMore({
                                    variables: {
                                        ...variables,
                                    },
                                    updateQuery: (previousResult, { fetchMoreResult }) => {
                                        return { allExternalSystemIdentifiers: fetchMoreResult.allExternalSystemIdentifiers }
                                    },
                                })
                            },
                        }
                    },
                })(withApolloClient(OverviewTab))
            )
        )
    )
)
