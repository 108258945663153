import React, { Component } from 'react'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import { Button, Row, Col, Label, Badge, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import { DriverInsuranceStatus } from "./Queries"
import { HasPermissionsQuery } from "../Functions/querys"
import "./InsuranceDetailSection.css"
import "../Material/DocumentModal/DocumentList.css"
import renderEnumToInt from "../Functions/renderEnumToInt"
import DenyInsurance from "../ApplicationsNew/DenyInsurance"
import ApproveInsurance from "../ApplicationsNew/ApproveInsurance"
import RemoveInsurance from "../ApplicationsNew/RemoveInsurance"
import DatetimeRenderer from "../Material/DatetimeRenderer"
import UpdateInsuranceInfo from "../ApplicationsNew/UpdateInsuranceInfo"
import DriverNotes from "../ApplicationsNew/DriverNotes"
import withApolloClient from "../withApolloClient"

const ACTIVE = "Active"
const PENDING = "Pending"
const DENIED = "Denied"
const CANCELED = "Canceled"
const REMOVED = "Removed"
const RENDER_SETTINGS = {
    "dateAdded": { "viewable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED], "editable": [] },
    "status": { "viewable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED], "editable": [] },
    "type": { "viewable": [ACTIVE, REMOVED], "editable": [ACTIVE] },
    "deposit": { "viewable": [ACTIVE, PENDING], "editable": [ACTIVE, PENDING] },
    "surcharge": { "viewable": [ACTIVE, PENDING], "editable": [ACTIVE, PENDING] },
    "broker": { "viewable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED], "editable": [] },
    "deniedReason": { "viewable": [DENIED], "editable": [DENIED] },
    "removedReason": { "viewable": [REMOVED], "editable": [REMOVED] },
    "dateToReconsider": { "viewable": [DENIED, CANCELED, REMOVED], "editable": [DENIED, CANCELED, REMOVED] },
    "extraDocsRequired": { "viewable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED], "editable": [PENDING] },
    "approvedBy": { "viewable": [ACTIVE, REMOVED], "editable": [] },
    "deniedBy": { "viewable": [DENIED], "editable": [] },
    "removedBy": { "viewable": [REMOVED], "editable": [] },
    "createdBy": { "viewable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED], "editable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED] },
    "notes": { "viewable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED], "editable": [ACTIVE, PENDING, DENIED, CANCELED, REMOVED] },
    "approve": { "viewable": [PENDING], "editable": [] },
    "deny": { "viewable": [PENDING], "editable": [] },
    "remove": { "viewable": [ACTIVE], "editable": [] },
}
const HISTORY_TABLE_HEADER = [
    { id: "date", name: "Date" },
    { id: "detail", name: "Details" },
    { id: "createdBy", name: "Created By" },
]
class InsuranceDetailSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    refetchQuery = () => {
        this.props.refetchInsuranceQuery()
        this.props.refetchQuery()
    }

    handleClose = () => {
        this.setState({ openModal: "" })
        if (this.props.handleClose)
            this.props.handleClose()
    }

    renderMetadataVal = (metadata, key) => {
        //Iagami- React Upgradation Changes due to DriverInsuranceStatusStatus enum Value changes
        //---------------------------------------------------------------------------------------
        if (!metadata) {
            return "--"
        }
        let metaObject
        try {
            if (typeof metadata === "string") {
                metaObject = JSON.parse(metadata.replace(/'/g, '"'))
            } else if (typeof metadata === "object") {
                metaObject = metadata
            } else {
                return "--"
            }
            if (metaObject && metaObject[key]) {
                return metaObject[key]
            }
        } catch (error) {
            console.error("Error parsing metadata:", error)
        }
        return "--"
    };


    getStatusValue = (enumValue) => {
        //Iagami- React Upgradation Changes due to DriverInsuranceStatusStatus enum Value changes
        //---------------------------------------------------------------------------------------
        const foundState = this.props.DriverInsuranceStatusStatus?.states.find(val => val.name === enumValue)
        if (foundState?.name) {
            let InsuranceStatus = foundState.name.charAt(0).toUpperCase() + foundState.name.slice(1).toLowerCase()
            return InsuranceStatus
        }
        return enumValue
    };

    canView = (attributeName) => {
        let status = this.props.insurance && this.props.insurance.status
        let attributeSettings = RENDER_SETTINGS[attributeName]
        if (status && attributeSettings) {
            status = this.getStatusValue(status)
            attributeSettings = attributeSettings["viewable"]
            return attributeSettings.includes(status)
        } else {
            return false
        }
    }

    canEdit = (attributeName) => {
        let status = this.props.insurance && this.props.insurance.status
        let attributeSettings = RENDER_SETTINGS[attributeName]
        if (status && attributeSettings) {
            status = this.getStatusValue(status)
            attributeSettings = attributeSettings["editable"]
            return (attributeSettings.includes(status) && (this.props.hasPermissions && this.props.hasPermissions.includes("change_insurance")))
        } else {
            return false
        }
    }

    render() {
        let pendingDocs = this.props.insurance && this.props.insurance.driver && this.props.insurance.driver.missingInsuranceDocuments && this.props.insurance.driver.missingInsuranceDocuments.length > 0 ? this.props.insurance.driver.missingInsuranceDocuments.map(doc => doc.id) : []
        return (
            this.props.insurance ? <>
                {this.state.openModal === "insuraceDeposit" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Insurance Deposit" name="deposit" value={this.props.insurance.deposit} target={"insuraceDeposit" + this.props.insurance.pk} type="number" open={this.state.openModal === "insuraceDeposit" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "insuranceSurcharge" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Surcharge" name="surcharge" value={this.props.insurance.surcharge} target={"insuranceSurcharge" + this.props.insurance.pk} type="number" open={this.state.openModal === "insuranceSurcharge" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "extraDocsRequired" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Required Driver Docs" name="extraDocsRequired" value={this.props.insurance.extraDocsRequired} target={"extraDocsRequired" + this.props.insurance.pk} type="select" open={this.state.openModal === "extraDocsRequired" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} driverId={this.props.insurance.driver ? this.props.insurance.driver.id : ""} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "insuranceNotesEdit" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Insurance Notes" name="notes" value={this.props.insurance.notes} target={"insuranceNotesEdit" + this.props.insurance.pk} type="text" open={this.state.openModal === "insuranceNotesEdit" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "deniedReason" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Denied Reason" name="insuranceReasonId" value={this.props.insurance.insuranceReason} target={"deniedReason" + this.props.insurance.pk} type="select" open={this.state.openModal === "deniedReason" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "removedReason" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Removed Reason" name="removedReasonId" value={this.props.insurance.driverremovedreasonSet} target={"removedReason" + this.props.insurance.pk} type="select" open={this.state.openModal === "removedReason" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "driverInsuranceType" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Insurance Type" name="driverInsuranceType" value={this.props.insurance.driverInsuranceType} target={"driverInsuranceType" + this.props.insurance.pk} type="select" open={this.state.openModal === "driverInsuranceType" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "dateToReconsider" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Insurance Date To Consider" name="dateToReconsider" value={this.props.insurance.dateToReconsider} target={"dateToReconsider" + this.props.insurance.pk} type="date" open={this.state.openModal === "dateToReconsider" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}
                {this.state.openModal === "createdBy" + this.props.insurance.pk && <UpdateInsuranceInfo insuranceId={this.props.insurance.id} title="Edit Representative" name="representative" value={this.props.insurance.createdBy} target={"createdBy" + this.props.insurance.pk} type="select" open={this.state.openModal === "createdBy" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} brokerId={this.props.insurance.broker ? this.props.insurance.broker.id : ""} />}

                <Row>
                    {this.canView("dateAdded") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Date Added</Label>
                        <p>{this.props.insurance.dateAdded ? <DatetimeRenderer datetime={this.props.insurance.dateAdded} /> : "--"}</p>
                    </Col>}
                    {this.canView("status") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Status</Label><br />
                        {this.props.insurance.status && (<Badge color={(this.props.insurance.status) === "ACTIVE" ? 'success' : (this.props.insurance.status) === "DENIED" ? 'danger' : 'warning'}>{this.getStatusValue(this.props.insurance.status)}</Badge>)}</Col>}
                    {this.canView("broker") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Broker</Label>
                        <p>{this.props.insurance.broker ? this.props.insurance.broker.name : "--"}</p>
                    </Col>}
                    {this.canView("type") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Type {this.canEdit("type") && <i id={"driverInsuranceType" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("driverInsuranceType" + this.props.insurance.pk)}></i>}</Label>
                        <p>{this.props.insurance.driverInsuranceType ? this.props.insurance.driverInsuranceType.name : "-- "}</p>
                    </Col>}
                    {this.canView("deposit") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Deposit {this.canEdit("deposit") && <i id={"insuraceDeposit" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("insuraceDeposit" + this.props.insurance.pk)}></i>}</Label>
                        <p>{"$" + this.props.insurance.deposit}</p>
                    </Col>}
                    {this.canView("surcharge") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Surcharge {this.canEdit("surcharge") && <i id={"insuranceSurcharge" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("insuranceSurcharge" + this.props.insurance.pk)}></i>}</Label>
                        <p>{"$" + this.props.insurance.surcharge}</p>
                    </Col>}
                    {this.canView("deniedReason") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Denied Reason {this.canEdit("deniedReason") && <i id={"deniedReason" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("deniedReason" + this.props.insurance.pk)}></i>}</Label>
                        <p>{this.props.insurance.insuranceReason ? this.props.insurance.insuranceReason.title : "--"}</p>
                    </Col>}
                    {this.canView("removedReason") && this.props.insurance.driverremovedreasonSet && this.props.insurance.driverremovedreasonSet.edges && this.props.insurance.driverremovedreasonSet.edges.length > 0 &&
                        this.props.insurance.driverremovedreasonSet.edges.map((item, i) =>
                            <Col xs={this.props.colSize ? this.props.colSize : "4"} key={i}>
                                <Label>Removed Reason {this.canEdit("removedReason") && <i id={"removedReason" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("removedReason" + this.props.insurance.pk)}></i>}</Label>
                                <p className="columnContent">{item.node.reason ? item.node.reason.reasonName : ""}</p>
                            </Col>
                        )
                    }
                    {this.canView("dateToReconsider") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Date To Reconsider {this.canEdit("dateToReconsider") && <i id={"dateToReconsider" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("dateToReconsider" + this.props.insurance.pk)}></i>}</Label>
                        <br />
                        {this.props.insurance.dateToReconsider ? <p>
                            <DatetimeRenderer datetime={this.props.insurance.dateToReconsider} />&nbsp;
                            <Badge color="warning white">By {this.renderMetadataVal(this.props.insurance.metadata, "reconsider_date_added_by") ? this.renderMetadataVal(this.props.insurance.metadata, "reconsider_date_added_by") : "--"}</Badge>
                        </p> : "--"}
                    </Col>}
                    {this.canView("extraDocsRequired") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Additional Docs Required {this.canEdit("extraDocsRequired") && <i id={"extraDocsRequired" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("extraDocsRequired" + this.props.insurance.pk)}></i>}</Label><br />
                        {this.props.insurance.extraDocsRequired && this.props.insurance.extraDocsRequired.edges && this.props.insurance.extraDocsRequired.edges.length > 0 ? this.props.insurance.extraDocsRequired.edges.map(additionalDoc => <>
                            <Badge color={pendingDocs.includes(additionalDoc.node.id) ? "danger" : "success"}>{additionalDoc.node.typeName}</Badge>&nbsp;
                        </>) : "No Additional Doc Required"}
                    </Col>}
                    {this.canView("approvedBy") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Approved By</Label>
                        <p>{this.renderMetadataVal(this.props.insurance.metadata, "approved_by")}</p>
                    </Col>}
                    {this.canView("deniedBy") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Denied By</Label>
                        <p>{this.renderMetadataVal(this.props.insurance.metadata, "denied_by")}</p>
                    </Col>}
                    {this.canView("removedBy") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Removed By</Label>
                        <p>{this.renderMetadataVal(this.props.insurance.metadata, "removed_by")}</p>
                    </Col>}
                    {this.canView("createdBy") && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>Representative {this.canEdit("createdBy") && <i id={"createdBy" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("createdBy" + this.props.insurance.pk)}></i>}</Label>
                        <p>{this.props.insurance.createdBy ? this.props.insurance.createdBy.firstName ? this.props.insurance.createdBy.firstName + " " + this.props.insurance.createdBy.lastName : this.props.insurance.createdBy.username : "--"}</p>
                    </Col>}
                    {!this.props.loading && (this.canView("approve") || this.canView("deny") || this.canView("remove")) && <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <><Label>Actions</Label><br /></>
                        {this.canView("approve") && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_approve_insurance") &&
                            <><Button className="insurance-detail-section-success-btn" color={"success"} onClick={() => this.toggleModal("ApproveInsurance" + this.props.insurance.pk)}>APPROVE</Button>&nbsp;&nbsp;</>
                        }
                        {this.canView("deny") && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_deny_insurance") &&
                            <><Button className="insurance-detail-section-danger-btn" color={"danger"} onClick={() => this.toggleModal("DenyInsurance" + this.props.insurance.pk)}>DENY</Button>&nbsp;&nbsp;</>
                        }
                        {this.canView("remove") && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_remove_insurance") &&
                            <><Button onClick={() => this.toggleModal("RemoveInsurance" + this.props.insurance.pk)} className="insurance-detail-section-danger-btn" color={"danger"} disabled={this.props.insurance.driver.currentAgreement || (this.props.insurance.driver.reservationDriver && this.props.insurance.driver.reservationDriver.edges && this.props.insurance.driver.reservationDriver.edges.length > 0)}>REMOVE</Button>&nbsp;&nbsp;</>
                        }
                        {this.state.openModal === "DenyInsurance" + this.props.insurance.pk && <DenyInsurance refetchDriverQuery={this.props.refetchQuery} handleClose={this.handleClose} open={this.state.openModal === "DenyInsurance" + this.props.insurance.pk} insurance={this.props.insurance} driverId={this.props.insurance.driver ? this.props.insurance.driver.id : null} />}
                        {this.state.openModal === "ApproveInsurance" + this.props.insurance.pk && <ApproveInsurance refetchDriverQuery={this.props.refetchQuery} handleClose={this.handleClose} open={this.state.openModal === "ApproveInsurance" + this.props.insurance.pk} insurance={this.props.insurance} driverId={this.props.insurance.driver ? this.props.insurance.driver.id : null} />}
                        {this.state.openModal === "RemoveInsurance" + this.props.insurance.pk && <RemoveInsurance refetchDriverQuery={this.props.refetchQuery} handleClose={this.handleClose} open={this.state.openModal === "RemoveInsurance" + this.props.insurance.pk} insurance={this.props.insurance} />}
                    </Col>}
                    <Col xs={this.props.colSize ? this.props.colSize : "4"}>
                        <Label>History</Label>
                        <p><i id={"showEditHistory" + this.props.insurance.pk} style={{ fontSize: 20 }} className="fa fa-history" aria-hidden="true" onClick={() => this.toggleModal("showEditHistory" + this.props.insurance.pk)}></i></p>
                        {this.state.openModal === "showEditHistory" + this.props.insurance.pk &&
                            <Popover trigger="legacy" placement="right" isOpen={this.state.openModal} target={"showEditHistory" + this.props.insurance.pk} toggle={() => this.setState({ openModal: false })} style={{ maxHeight: "300px", overflow: "auto" }}>
                                <PopoverHeader className="text-center">Insurance History</PopoverHeader>
                                <PopoverBody>
                                    <Row>
                                        {HISTORY_TABLE_HEADER.map(tableHeader =>
                                            <Col key={tableHeader.id} xs={4}><Label >{tableHeader.name}</Label></Col>
                                        )}
                                    </Row>
                                    {this.props.insurance.history && this.props.insurance.history.length > 0 ? this.props.insurance.history.map(history => <>
                                        <Row>
                                            <Col><p>{history.date ? <DatetimeRenderer datetime={history.date} /> : "--"}</p></Col>
                                            <Col><p>{history.comment ? history.comment : "--"}</p></Col>
                                            <Col><p>{history.user ? history.user : "--"}</p></Col>
                                        </Row>
                                        <hr />
                                    </>) :
                                        <Row>
                                            <Col>
                                                <p>No Insurance History Found!</p>
                                            </Col>
                                        </Row>
                                    }
                                </PopoverBody>
                            </Popover>
                        }
                    </Col>
                </Row>
                <Row>
                    {this.canView("notes") && <Col xs={12}>
                        <Label>Notes <i id={"insuranceNotesEdit" + this.props.insurance.pk} className="fa fa-pencil-square" aria-hidden="true" onClick={() => this.toggleModal("insuranceNotesEdit" + this.props.insurance.pk)}></i>&nbsp;<i id={"notesHistory" + this.props.insurance.pk} className="fa fa-history" aria-hidden="true" onClick={() => this.toggleModal("notesHistory" + this.props.insurance.pk)}></i></Label>
                        <p>{this.props.insurance.notes ? this.props.insurance.notes : "--"}</p>
                        {this.state.openModal === "notesHistory" + this.props.insurance.pk &&
                            <DriverNotes driver={this.props.insurance.driver} noteType="INSURANCE" title="Insurance Notes History" objectId={this.props.insurance.id} target={"notesHistory" + this.props.insurance.pk} open={this.state.openModal === "notesHistory" + this.props.insurance.pk} handleClose={() => this.toggleModal("")} placement={"right"} />
                        }
                    </Col>}
                </Row>
            </> : <></>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(DriverInsuranceStatus, {
    props: ({ data: { __type: DriverInsuranceStatusStatus } }) => ({
        DriverInsuranceStatusStatus
    })
})(graphql(HasPermissionsQuery, {
    options: () => ({
        variables: {
            userPermissions: [
                "custom_can_approve_insurance",
                "custom_can_deny_insurance",
                "custom_can_remove_insurance",
                "change_insurance"
            ]
        }
    }),
    props: ({ data: { hasPermissions, loading, variables } }) => ({
        hasPermissions,
        loading,
        variables
    })
})(withApolloClient(InsuranceDetailSection)));


