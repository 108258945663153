import React, { Component } from "react"
import { graphql } from '@apollo/client/react/hoc' //Iagami - Code upgradation React 18
import { Popover, PopoverBody, PopoverHeader, Input, Button, Alert, Progress } from "reactstrap"
import Select from "react-select"
import { UpdateCustomerLead } from "./Mutations"
import { RepsQuery } from "../Functions/querys"
import { LeadStatus, LeadType } from "./Queries"
import { EMAIL_REGEX } from "../Constants"
import withApolloClient from '../withApolloClient' //Iagami - Code upgradation React 18
import { processCarReturnStates, getEnumDescription } from '../Functions/processEnumDescription'//Iagami - Code upgradation React 18


class UpdateLeadInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: { id: this.props.id },
            error: "",
            loading: false,
            allReps: [],
            leadStatus: [],
            leadType: [],

        }
    }
    updateInput = (name, value) => {
        if (name === "email") {
            if (EMAIL_REGEX.test(value))
                this.setState({ error: "" })
            else
                this.setState({ error: "Please enter a valid email address." })
        }
        this.setState({ input: { ...this.state.input, [name]: value } })
    }

    updateCustomerLead = () => {
        this.setState({ loading: true, error: "" })
        let input = this.state.input
        //Iagami - Code upgradation React 18
        this.props.apolloClient.mutate({
            mutation: UpdateCustomerLead,
            variables: { input }
        }).then(result => {
            if (result && result.data && result.data.updateCustomerLead && result.data.updateCustomerLead.ok) {
                this.setState({ input: {}, loading: false, error: "" })
                this.props.refetchQuery()
                this.props.handleClose()
            }
            else {
                let error = "An error occurred, could not complete request."
                if (result && result.data && result.data.updateCustomerLead && result.data.updateCustomerLead.errors[0] && result.data.updateCustomerLead.errors[0].messages) {
                    error = result.data.updateCustomerLead.errors[0].messages.toString()
                }
                this.setState({ error: error, loading: false })
            }
        }).catch((err) => {
            this.setState({ loading: false, error: "An error has occurred. Please contact admin: " + err })
        })
    }

    componentDidMount() {
        if (this.props.name === "assignedRep") {
            //Iagami - Code upgradation React 18
            this.props.apolloClient.query({
                query: RepsQuery,
                variables: { groups: this.props.groups }
            }).then((result) => {
                if (result.data.allReps && result.data.allReps.edges && result.data.allReps.edges.length > 0) {
                    this.setState({ allReps: result.data.allReps.edges.map(rep => ({ value: rep.node.id, label: rep.node.name })) })
                }
            })

        }

        else if (this.props.name === "status") {
            //Iagami - Code upgradation React 18
            this.props.apolloClient.query({
                query: LeadStatus,
            }).then((result) => {
                if (result.data.optionsList) {
                    //Iagami - Code upgradation React 18
                    let leadsStatus = getEnumDescription(result.data.optionsList.states)
                    this.setState({ leadStatus: leadsStatus.filter(status => ["Open", "Contacted"].includes(status.description)).map(status => ({ value: status.description, label: status.description })) })
                }
            })
        }
        else if (this.props.name === "leadType") {
            //Iagami - Code upgradation React 18
            this.props.apolloClient.query({
                query: LeadType,
            }).then((result) => {
                let leadsStatus = getEnumDescription(result.data.optionsList.states)

                if (result.data.optionsList) {
                    this.setState({ leadType: leadsStatus.map(status => ({ value: status.description, label: status.description })) })
                }
            })
        }
        //Iagami - Code upgradation React 18
        const { apolloClient } = this.props
    }

    render() {
        return (
            <Popover trigger="legacy" placement="left" isOpen={this.props.isOpen} target={this.props.target} toggle={this.props.handleClose}>
                <PopoverHeader>
                    {this.props.title}&nbsp;&nbsp;<i className="fa fa-times-circle float-right" aria-hidden="true" onClick={this.props.handleClose}> </i>
                </PopoverHeader>
                <PopoverBody>
                    {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                    <div>
                        {this.props.type == "select" ?
                            <Select
                                className="bos-custom-select" classNamePrefix="bos-select"
                                options={this.props.name === "assignedRep" ? this.state.allReps : this.props.name === "status" ? this.state.leadStatus : this.state.leadType}
                                defaultValue={this.props.defaultValue}
                                onChange={(item) => this.updateInput(this.props.name, item.value)}
                            /> :
                            <Input
                                className="mt-2 mb-2" type={this.props.type} name={this.props.name} placeholder={this.props.placeholder}
                                defaultValue={this.props.defaultValue}
                                onChange={(e) => this.updateInput(e.target.name, e.target.value)}
                            />
                        }
                    </div>
                    {this.state.loading ? <Progress animated color="info" value={100} /> :
                        <Button className="float-right submit-button  my-2 py-1" onClick={this.updateCustomerLead} disabled={this.state.error != ""}>UPDATE</Button>
                    }
                </PopoverBody>
            </Popover>
        )
    }
}

//Iagami - Code upgradation React 18
export default (withApolloClient(UpdateLeadInfo))
