import gql from 'graphql-tag'

const AllPromotionsTemplates = gql`query AllPromotionTemplates($agreementTypeId: ID,$carId: ID){
    allPromotionsTemplates(agreementTypeId: $agreementTypeId,carId: $carId, isActive:true){
      edges{
        node{
            id
            interval
            intervalUnit
            currency { code symbol }
            title
            startDate
            expirationDate
            isPercentage
            promoPrice
            minimumPromoPrice
            maximumPromoPrice
            cycle
            minCycle
            maxCycle
            agreementType{
                id
                name
                chargeFrequency
                chargeFrequencyUnit
                initialChargeInterval
                initialChargeFrequency
                subsequentChargeInterval
            }
            carModel{
                id
                make
                name
                series
                groupType{
                    id
                    name
                }
            }
        }
      }
} }`



const CreateReservationPromoMutation = gql`
mutation createReservationPromoMutation($input: CreateReservationPromoMutationInput!){
    createReservationPromo(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const RemoveReservationPromoMutation = gql`
mutation removeReservationPromoMutation($input: RemoveReservationPromoMutationInput!){
    removeReservationPromo(input:$input){
        ok
        errors{
            messages
        }
    }
}`

const AllPaymentMethodsQuery = gql`
  query AllPaymentMethods{
    allPaymentMethods(isActive:true){
        edges{
            node{
                name
                id
                pk
            }
        }
    }
  }
`

const StripeCards = gql`query StripeCards($id: ID!){
    cards: getStripeCards(id: $id){
        id
        cards {
            id
            expYear
            expMonth
            last4
            brand
            default
        }
    } 
}`

const ReservationQuery = gql`
    query ReservationQuery($id: ID!){
        reservation(id:$id){
            id
            pickupType
            pickupDate
            expectedBillingDay
            pickUpLocation{
                id
                name
            }
            rep{
                id
                username
                firstName
                lastName
            }
            preferredCar
            priceRange
            deposit
            depositAmount
            depositScheme
            notes
            isSplit
            totalLeaseAmount
            leaseTermInMonths
            branch{
                id
                customId
                name
            }
            reservationpromoSet{
                edges {
                  node {
                    id
                    interval
                    intervalUnit
                    currency { code symbol }
                    title
                    startDate
                    expirationDate
                    isPercentage
                    promoPrice
                    minimumPromoPrice
                    maximumPromoPrice
                    cycle
                    minCycle
                    maxCycle
                  }
                }
            }
            agreementType{
                id
                name
                requireEndDate
                chargeType
                forLeaseToOwn
                subtypeSet: subscriptiontypeSet(isActive:true){
                    edges {
                      node {
                        id
                      }
                    }
                }
            }
            reservationPrice:reservationpriceSet(first:5){
                edges {
                    node {
                        id
                        price
                        startDate
                        interval
                        intervalUnit
                        minimumPrice
                        maximumPrice
                    }
                }
            }
            driver{
                id
                name
                firstName
                lastName
                tlcLicense
                phone
                email
                deposit
                balance
                bcBalance
                isTlc
                chargeDayofweek
                currentCharge
                hasActivePlan
                subSet: subscriptionSet(first:1,status_Gt:"INACTIVE_0"){
                    edges {
                      node {
                        id
                        statusDisplay
                        dateAdded
                        externalLink
                        createdBy{
                            id
                            username
                            firstName
                            lastName
                        }
                        subType: subscriptionType{
                            id
                            name
                            amount
                            deductibleAmount
                        }
                      }
                    }
                }
                activeCards{
                    id
                    last4
                }
                branch{
                    id
                    name
                    customId
                    tenant{
                        id
                        customId
                    }
                }
                paymentplanSet(status:ACTIVE,last:1) {
                    edges {
                      node {
                        id
                      }
                    }
                }
            }
            car{
                id
                pk
                year
                color
                vin
                carModel{
                    id
                    make
                    name
                    series
                    groupType{
                        id
                        name
                    }
                }
            }
        }  
    }`

const AllAgreementTypes = gql`{
    allAgreementTypes(isActive: true){
        edges{
            node{
                id
                name
                depositScheme
                requiredDeposit
                forLeaseToOwn
                branch{
                    id
                    name
                }
            }
        }
    }
}`

const AllDrivers = gql`query AllDrivers($searchTerm: String){
    drivers: allDrivers(searchTerm: $searchTerm,first:10){
      edges{
        node{
          id
          pk
          name
          tlcLicense
          isTlc
          phone
          currentAgreement{
              id
              agreementType
          }
        }
      }
} }`

const AllCarModelsQuery = gql`query AllCarModelsQuery($orderBy: [String], $carStages: [Int], $hasReturnDate: Boolean, $hasReservation: Boolean){
    allCarModels(orderBy: $orderBy, carStages: $carStages, hasReturnDate: $hasReturnDate, hasReservation: $hasReservation ){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`

const AllCarLocations = gql`{
    allCarLocations(isPickupLocation:true){
      edges{
        node{
          name
          id
          branch{
            id
          }
        }
      }
    }
}`

const AllPricingTemplates = gql`query AllPricingTemplates($agreementTypeId: ID,$carId: ID){
    allPricingTemplates(agreementTypeId: $agreementTypeId,carId: $carId, isActive:true){
      edges{
        node{
            id
            price
            interval
            intervalUnit
            currency { code symbol }
            title
            minimumPrice
            maximumPrice
            tierType
            tiers{
                edges{
                    node{
                        id
                        price
                        interval
                        intervalUnit
                        currency { code symbol }
                        upperLimit
                        upperLimitUnit
                    }
                }
            }
            agreementType{
                id
                name
                chargeFrequency
                chargeFrequencyUnit
                initialChargeInterval
                initialChargeFrequency
                subsequentChargeInterval
            }
            carModel{
                id
                name
            }
        }
      }
} }`

const ReservationPickupTypes = gql`query ReservationPickupTypes{
    __type(name:"ReservationPickupType"){
      enumValues{
        name
        description
      }
    }
}`

const AgreementTypeDepositSchemes = gql`query AgreementTypeDepositSchemes{
    __type(name:"AgreementTypeDepositScheme"){
        enumValues{
            name
            description
        }
    }
}`

const DriverQuery = gql`query Driver($id: ID!){
    driver(id: $id){
        id
        name
        phone
        tlcLicense
        branch{
            id
            name
            tenant{
                id
                customId
            }
        }
        currentAgreement{
            id
            agreementType
        }
    }
}
`

const CarQuery = gql`query CarQuery($id: ID!){
    car(gqlId: $id){
        id
        pk
        year
        color
        vin
        carModel{
            id
            make
            name
            series
            groupType{
                id
                name
            }
        }
    }
  }`

const SubscriptionTypes = gql`query SubscriptionTypes($agreementTypeId: ID){
    allSubscriptionTypes(isActive:true, agreementTypeId: $agreementTypeId){
        edges{
          node{
            id
            amount
            name
            deductibleAmount
          }
        }
    }
}`

const AllPaymentPlans = gql`query AllPaymentPlans($driverId: ID!, $first: Int!, $status: String!) {
    allPaymentPlans(driverId: $driverId, first: $first, status_Exact: $status){
        edges {
            node {
                id
                pk
                reason
                paydownStartDate
                paydownNotes
                paydownOriginalBalance
                paydownAmount
            }
        }
    }
}`

export {
    ReservationQuery, RemoveReservationPromoMutation, AllPromotionsTemplates, CreateReservationPromoMutation, AgreementTypeDepositSchemes,
    AllPaymentMethodsQuery, AllAgreementTypes, AllDrivers, AllCarModelsQuery, AllCarLocations, AllPricingTemplates, ReservationPickupTypes,
    DriverQuery, CarQuery, SubscriptionTypes, StripeCards, AllPaymentPlans
}
