import React, { Component } from 'react'
import { Badge } from 'reactstrap'
import { useApolloClient } from '@apollo/client'
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import Select from 'react-select'
import { TagsQuery } from "../Functions/querys"
import { indexOf } from "lodash"


const addTags = gql`
mutation AddCarTagsMutation($input: AddCarTagsInput!){
addCarTags(input:$input){
    car{
        id
        tags{
            id
            name
            color
        }
    }
}
}
`

const removeTags = gql`
mutation RemoveCarTagsMutation($input: AddCarTagsInput!){
removeCarTags(input:$input){
    car{
        id
        tags{
            id
            name
            color
        }
    }
}
}
`

class UpdateCarTags extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tag: []
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.tag !== this.state.tag) {
            let input = { car: this.props.car.id }
            let tagsFound = []
            let tagsNotFound = []
            let allValues = [...this.state.tag, ...this.props.car.tags.map(tag => tag.name)]
            allValues = allValues.filter((item, index) => allValues.indexOf(item) === allValues.lastIndexOf(item))
            allValues.forEach(tag => {
                if (this.props.car && this.props.car.tags.find(carTag => carTag.name === tag)) {
                    tagsFound = [...tagsFound, tag]
                } else {
                    tagsNotFound = [...tagsNotFound, tag]
                }
            })
            if (tagsNotFound) {
                input['tags'] = tagsNotFound
                this.props.add(input)
            }
            if (tagsFound) {
                input['tags'] = tagsFound
                this.props.remove(input)
            }

            this.props.refetchCar()

        }
    }
    render() {
        return (
            <div>
                <Select
                    className="bos-custom-select" classNamePrefix="bos-select"
                    placeholder="Select Car Tags"
                    options={this.props.carTags && this.props.carTags.edges.map(tag => ({ value: tag.node.name, label: tag.node.name }))}
                    defaultValue={this.props.carTags && this.props.carTags.edges.filter(tag => this.props.car && this.props.car.tags.map(carTags => carTags.name).includes(tag.node.name)).map(tag => ({ value: tag.node.name, label: tag.node.name }))}
                    isClearable
                    isMulti
                    onChange={tags => this.setState({ tag: tags && tags.length > 0 ? tags.map(item => item.value) : [] })}
                />
            </div>
        )
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default graphql(addTags, {
    props: ({ mutate }) => ({
        add: (input) => mutate({ variables: { input } }),
    })
})(
    graphql(TagsQuery, {
        props: ({ data: { loading, carTags } }) => ({
            carTags
        })
    })(
        graphql(removeTags, {
            props: ({ mutate }) => ({
                remove: (input) => mutate({ variables: { input } }),
            })
        })(
            UpdateCarTags
        )
    )
)
