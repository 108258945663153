import React, { Component } from 'react'

import { Button, Popover, PopoverHeader, PopoverBody, Table, UncontrolledTooltip, Badge, CardBody, Card } from 'reactstrap'
import { ReactComponent as ReserveCar } from '../assets/images/icons/reserve-car.svg'
import DocumentModal from '../Material/DocumentModal/DocumentModal'
import CarModelGroupIcon from './CarModelGroupIcon'
import ReservationModalNew from "../Reservations/Modals/ReservationModalNew"
import renderEnumToInt from '../Functions/renderEnumToInt'
import toTitleCase from '../Functions/toTitleCase'
import CarNotes from '../Material/CarNotes'
import UpdateCarInfo from '../Material/UpdateCarInfo'
import CarEquipmentModal from './CarEquipmentModal'
import CarInfoModal from './CarInfoModal'
import CarPricingModal from './CarPricingModal'
import gql from 'graphql-tag'
import InfoModal from "../Material/InfoModal"
import UpdateCarTags from "./UpdateCarTags"
import "../Material/ListingHeader.css"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { ReactComponent as CarEquipments } from '../assets/images/icons/car-equipments.svg'
import DocumentModalNew from "../Material/DocumentModal/DocumentModalNew"
import { cleanDocumentUrl } from "../Material/DocumentModal/DocumentsListNew"
import NotesHistory from "../Material/NotesHistory/NotesHistory"
//Iagami - Code upgradation React 18
import { graphql } from '@apollo/client/react/hoc'
//Iagami - Code upgradation React 18
import withApolloClient from '../withApolloClient'
import { ReactComponent as LocationIcon } from '../assets/images/icons/location-icon.svg'


const CarsQuery = gql`query CarsQuery($cursor: String, $first: Int!, $searchTerm: String, $orderBy: [String], $locationId: [ID], $modelIds:[ID], $stage: [Int], $isReady: Boolean, $isFleetManagement: Boolean, $isLocationNull: Boolean, $hasDeliveryDate: Boolean, $carTags: [String], $carModelNames:[String], $minYear: Int, $maxYear: Int) {
    cars: allCars(first:$first, orderBy:$orderBy, after: $cursor, searchTerm:$searchTerm, locationId:$locationId, modelIds:$modelIds, stage: $stage, isReady:$isReady, isFleetManagement:$isFleetManagement, isLocationNull:$isLocationNull, hasDeliveryDate: $hasDeliveryDate, carTags: $carTags, carModelNames: $carModelNames, minYear: $minYear, maxYear: $maxYear){
      edges{
        cursor
        node{
            id
            pk
            vin
            hasOpenIssues
            year
            lastMileage
            lastMileageInKm
            tags{
                id
                name
                color
            }
            carModel{
                id
                make
                name
                series
                groupType{
                    id
                    name
                }
            }
            location
            color
            notes
            stage
            branch{
                id
                name
                defaultMileageUnit
            }
            lastCheckout{
                id
                dateAdded
                carpicturesSet {
                    edges {
                        node {
                            id
                            pictureUrl
                            pictureType
                            description
                            dateAdded
                        }
                    }
                }
            }
            currentAgreement{
                id
            }
            carReservation(status:OPEN){
                edges{
                    node {
                        id
                    }
                }
            }
            equipmentSet(removed:false){
                edges {
                    node {
                        id
                        dateAdded
                        removed
                        equipmentItems{
                            id
                            pk
                            name
                        }
                    }
                }
            }
            TLCWeeklyPrice: prices(isDefault:true, isActive:true, agreementType_Name_Iexact:"TLC Weekly", orderBy:"-id"){
                edges {
                    node {
                        id
                        title
                        price
                        interval
                        intervalUnit
                        minimumPrice
                        maximumPrice
                    }
                }
            }
            prices(isActive:true, orderBy:"-id"){
                edges {
                    node {
                        id
                        title
                        price
                        interval
                        isDefault
                        intervalUnit
                        minimumPrice
                        maximumPrice
                    }
                }
            }
        }
    }
    pageInfo{
        endCursor
        hasNextPage
        length
    }
}}`

const tableHeaders = [
    { id: "id", name: "Car", sortable: true }, { id: "branch__name", name: "Branch", sortable: true }, { id: "tags", name: "Tags", sortable: false }, { id: "location", name: "Location", sortable: true },
    { id: "notes", name: "Car Notes", sortable: false }, { id: "weekly_charge", name: "Rental Pricing", sortable: false },
    { id: "action", name: "Actions", sortable: false }
]

class CarsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            location: null,
            loading: false,
            error: "",
            images: null,
            carTags: []
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }


    viewImages(picturesObject, i) {
        let tmpArr = []
        if (picturesObject && picturesObject.carpicturesSet && picturesObject.carpicturesSet.edges && picturesObject.carpicturesSet.edges.length > 0) {
            const toBeAdded = picturesObject.carpicturesSet.edges.map((pic, i) => ({ id: pic.node.id, documentUrl: cleanDocumentUrl(pic.node.pictureUrl), description: pic.node.description, dateAdded: pic.node.dateAdded, name: pic.node.pictureType }))
            tmpArr = [...tmpArr, ...toBeAdded]
        }
        this.setState({ checkoutImages: tmpArr, selectedImage: tmpArr[0] })
        this.toggleModal("viewImages" + i)
    }
    next = () => {
        const checkoutImages = this.state.checkoutImages
        const currentIndex = checkoutImages.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === checkoutImages.length - 1) return
        this.setState({ selectedImage: checkoutImages[currentIndex + 1] })
    }

    prev = () => {
        const checkoutImages = this.state.checkoutImages
        const currentIndex = checkoutImages.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: checkoutImages[currentIndex - 1] })
    }

    componentDidMount() {
        const { apolloClient } = this.props
    }

    render() {
        return (<>
            {/* {this.props.loading || this.state.loading ? <Progress animated color="info" value="100" /> : ""}
            <Card className={"cars-data-table-container"}>
                <CardBody>
                    <div className="table-container"> */}
            <PrimaryListingContainer listLoading={this.props.loading} totalFetchedRows={this.props.cars && this.props.cars.edges.length} pageInfo={this.props.cars && this.props.cars.pageInfo} loadMoreEntries={this.props.cars && this.props.cars.pageInfo && this.props.cars.pageInfo.hasNextPage ? this.props.loadMoreEntries : null} refetchListQuery={this.props.refetchQuery}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th onClick={this.toggleSelectAllClick} style={{ cursor: "pointer" }}> {this.state.selectAll ? <i className="fa fa-plus-square" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {tableHeaders.map(tableHeader => tableHeader.sortable ?
                                <th key={tableHeader.id} onClick={() => this.props.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{this.props.orderBy.some(order => order.includes(tableHeader.id)) ? <i className={"fa " + (this.props.orderBy.includes(tableHeader.id) ? 'fa-sort-asc' : 'fa-sort-desc')}></i> : <i className='fa fa-sort'></i>}&nbsp;&nbsp;{tableHeader.name}</th> :
                                <th key={tableHeader.id}>{tableHeader.name}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.cars && this.props.cars.edges && this.props.cars.edges.length > 0 ? this.props.cars.edges.map((car, i) =>
                            <tr>
                                <td scope="row">{i + 1}</td>
                                <td className="py-3">
                                    <div className="cars-wrapper">
                                        <div className="car-info">
                                            {this.state.openModal === "CarInfo" + car.node.pk && <CarInfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "CarInfo" + car.node.pk} id={car.node.pk} carPk={car.node.pk} carId={car.node.id} isFleetManagement={this.props.isFleetManagement} refetchQuery={this.props.refetchQuery} />}
                                            <a onClick={() => this.toggleModal("CarInfo" + car.node.pk)} style={{ cursor: "pointer" }}> <i className="fa fa-wrench fa-2x" style={car.node.hasOpenIssues ? { color: "#DC3546" } : { color: "#303e67" }} aria-hidden="true"></i></a><br />
                                            {this.props.isFleetManagement && <small>Stage {renderEnumToInt(car.node.stage)}</small>}
                                        </div>
                                        <div className="car-id-detail">
                                            <CarModelGroupIcon carGroup={car.node.carModel && car.node.carModel.groupType ? car.node.carModel.groupType.name : null} carColor={car.node.color} id={car.node.pk} key={i + 1} />
                                            <br />
                                            <span>{car.node.pk}</span>
                                        </div>
                                        <div className="car-name-years">
                                            <span>
                                                <small>{car.node.year} | {car.node.color} {car.node.carModel && car.node.carModel.groupType ? "| " + car.node.carModel.groupType.name : ""} | {car.node.lastMileage ? car.node.branch.defaultMileageUnit === "KM" ? (parseFloat(car.node.lastMileageInKm).toFixed(2) + " KM") : parseFloat(car.node.lastMileage).toFixed(2) + " MI" : "--"}</small>&nbsp;&nbsp;
                                            </span>
                                            {car.node.carModel && <div className="car-name">
                                                <a className="bos-custom-link" href={"/car/" + car.node.pk} target="_blank">
                                                    {toTitleCase(car.node.carModel.make)} {toTitleCase(car.node.carModel.name)}
                                                </a>&nbsp;
                                                <small>{car.node.carModel.series ? "(" + car.node.carModel.series + ")" : ""}</small>
                                                {(car.node.stage == 10 || (car.node.currentAgreement && car.node.currentAgreement.id)) && <>
                                                    <i id={"driverIsActive" + i} className="ml-2 fa fa-circle text-success" ></i>
                                                    <UncontrolledTooltip placement="right" target={"driverIsActive" + i}>Active Car</UncontrolledTooltip>
                                                </>}
                                            </div>}
                                            <small>{car.node.vin}</small>
                                        </div>
                                        <div className="car-actions">
                                            <ul className="actions">
                                                <li>
                                                    {this.state.openModal === "carDocuments" + i && <DocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "carDocuments" + i} objectType="car" id={car.node.id} currentUser={this.props.currentUser} />}
                                                    <a id={"carDocuments" + i} type="button" className="cargadgets-button" onClick={() => this.toggleModal("carDocuments" + i)}>
                                                        <i className="fa fa-folder-open-o fa-lg" aria-hidden="true"></i>
                                                    </a>
                                                    <UncontrolledTooltip placement="bottom" target={"carDocuments" + i}>Car Docs</UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    {this.state.openModal === "viewImages" + i && !car.node.lastCheckout &&
                                                        <InfoModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "viewImages" + i} title={"Checkout Images"} message={"No checkout images for car " + car.node.pk} />}
                                                    {this.state.openModal === "viewImages" + i && car.node.lastCheckout &&
                                                        <DocumentModalNew
                                                            document={this.state.selectedImage}
                                                            handleClose={() => this.setState({ selectedImage: null, openModal: "", checkoutImages: null })}
                                                            next={this.next}
                                                            prev={this.prev}
                                                            objectType={"car"}
                                                            isPicture={true}
                                                            refetch={this.props.refetch}
                                                            currentUser={this.props.currentUser}
                                                        />
                                                    }
                                                    <a id={"viewImages" + i} type="button" className="cargadgets-button" onClick={() => this.viewImages(car.node.lastCheckout, i)}>
                                                        <i className="fa fa-file-image-o fa-lg" style={car.node.lastCheckout && car.node.lastCheckout.carpicturesSet && car.node.lastCheckout.carpicturesSet.edges && car.node.lastCheckout.carpicturesSet.edges.length > 0 ? { color: "#28a745" } : car.node.lastCheckout ? { color: "#FFAA32" } : { color: "#DC3546" }} aria-hidden="true"></i>
                                                    </a>
                                                    <UncontrolledTooltip placement="bottom" target={"viewImages" + i}>{car.node.lastCheckout && car.node.lastCheckout.carpicturesSet && car.node.lastCheckout.carpicturesSet.edges && car.node.lastCheckout.carpicturesSet.edges.length > 0 ? "Checkout Pictures" : car.node.lastCheckout ? "Checkout Without Pictures" : "Needs Checkout"}</UncontrolledTooltip>
                                                </li>
                                                <li>
                                                    {this.state.openModal === "carEquipments" + car.node.pk && <CarEquipmentModal carEquipments={car.node.equipmentSet} isFleetManagement={this.props.isFleetManagement} id={car.node.pk} key={i + 1} open={this.state.openModal === "carEquipments" + car.node.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />}
                                                    <UncontrolledTooltip placement="bottom" target={"equipment" + car.node.pk}>Installed Equipments</UncontrolledTooltip>
                                                    <a id={"equipment" + car.node.pk} type="button" className="cargadgets-button" onClick={() => this.toggleModal("carEquipments" + car.node.pk)} >
                                                        <CarEquipments width={20} fill="#303e67" />
                                                    </a>
                                                    {/* <CarEquipmentModal iconWidth={20} carEquipments={car.node.equipmentSet} id={car.node.pk} key={i} isFleetManagement={this.props.isFleetManagement} refetchQuery={this.props.refetchQuery} /> */}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <Badge color="primary" className='bos-badge-blue'>{car.node.branch && car.node.branch.name ? car.node.branch.name : "No Branch Seleted"}</Badge>
                                </td>
                                <td>
                                    <div className="car-tags">
                                        <Popover style={{ minWidth: "2rem" }} isOpen={this.state.openModal === "cartags" + car.node.pk} placement="bottom" target={"cartags" + car.node.pk}>
                                            <PopoverHeader onClick={() => this.toggleModal("")}>Edit Tags <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                                            <PopoverBody style={{ minWidth: "200px", maxwidth: "300px" }}>
                                                <UpdateCarTags
                                                    car={car.node}
                                                    carsTags={car.node.tags}
                                                    refetchCar={this.props.refetchQuery}
                                                />
                                            </PopoverBody>
                                        </Popover>
                                        <div className="edit-location-icon">
                                            <UncontrolledTooltip target={"cartags" + car.node.pk}>Edit Car Tags</UncontrolledTooltip>
                                            <i className={"fa fa-pencil"} onClick={() => this.toggleModal("cartags" + car.node.pk)} id={"cartags" + car.node.pk}></i>&nbsp;&nbsp;
                                        </div>
                                        <div>
                                            {car.node.tags && car.node.tags.length > 0 ? car.node.tags.map((tag, i) =>
                                                <Badge className="text-light" key={i} style={{ background: `#${tag.color ? tag.color : "grey"}` }}>{tag.name}</Badge>
                                            ) : <small className="text-danger">No Tags Found</small>
                                            }
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={"car-location" + (this.props.isFleetManagement ? " not-bold" : "")}>
                                        {this.props.isFleetManagement && <div className="edit-location-icon">
                                            <UpdateCarInfo car={car.node} title="Edit Location" name="location" value={car.node.location} target={"locationEdit" + i} id="location" type="text" open={this.state.openModal === "locationEdit" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery} />
                                            <i id={"locationEdit" + i} className="fa fa-pencil" aria-hidden="true" onClick={() => this.toggleModal("locationEdit" + i)}></i></div>
                                        }
                                        {car.node.location ? car.node.location : "--"}
                                    </div>
                                </td>
                                <td>
                                    <div className="notes">
                                        {this.state.openModal === "notesHistory" + i &&
                                            <NotesHistory
                                                carId={car.node.id}
                                                id={"carNotesEdit" + i}
                                                target={"carNotesEdit" + i}
                                                noteType="GENERAL"
                                                title="Car Notes History"
                                                open={this.state.openModal === "notesHistory" + i}
                                                name={"notes"}
                                                handleClose={() => this.toggleModal("")}
                                                objectType={"carNotes"}
                                                objectId={""}
                                                refetchQuery={this.props.refetchQuery}
                                            />
                                        }
                                        <span id={"carNotesEdit" + i}>
                                            <i onClick={() => this.toggleModal("notesHistory" + i)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                            <small className="note-text">
                                                {car.node.notes ? car.node.notes : "--"}
                                            </small>
                                            <UncontrolledTooltip placement="right" target={"carNotesEdit" + i}>{car.node.notes ? car.node.notes : "--"}</UncontrolledTooltip>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="view-prices">
                                        <CarPricingModal key={i + 1} prices={car.node.prices} carPk={car.node.pk} title={"All Rental Pricing Templates"} />
                                        <span className="car-pricing-default">
                                            {car.node.TLCWeeklyPrice && car.node.TLCWeeklyPrice.edges && car.node.TLCWeeklyPrice.edges.length > 0 && car.node.TLCWeeklyPrice.edges[0].node.price ?
                                                ("$" + car.node.TLCWeeklyPrice.edges[0].node.price + "/- " + (car.node.TLCWeeklyPrice.edges[0].node.interval > 1 ? car.node.TLCWeeklyPrice.edges[0].node.interval + " " : "") + car.node.TLCWeeklyPrice.edges[0].node.intervalUnit)
                                                : car.node.prices && car.node.prices.edges && car.node.prices.edges.length > 0 ?
                                                    (car.node.prices.edges.filter(item => item.node.isDefault).map(price => "$" + price.node.price + "/- " + (price.node.interval > 1 ? price.node.interval + " " : "") + price.node.intervalUnit)[0] || "") : "No Price Found!"}
                                            <br />
                                            <small>
                                                {car.node.TLCWeeklyPrice && car.node.TLCWeeklyPrice.edges && car.node.TLCWeeklyPrice.edges.length > 0 && car.node.TLCWeeklyPrice.edges[0].node.minimumPrice ?
                                                    ("$" + car.node.TLCWeeklyPrice.edges[0].node.minimumPrice + (car.node.TLCWeeklyPrice.edges[0].node.maximumPrice ? " - $" + car.node.TLCWeeklyPrice.edges[0].node.maximumPrice : ""))
                                                    :
                                                    car.node.prices && car.node.prices.edges && car.node.prices.edges.length > 0 ?
                                                        (car.node.prices.edges.filter(item => item.node.isDefault).map(price => price.node.minimumPrice ?
                                                            "$" + price.node.minimumPrice + (price.node.maximumPrice ? " - $" + price.node.maximumPrice : "") : "")[0] || "") : ""}
                                            </small>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <ul className="actions" style={{ display: "flex", alignItems: "center", padding: 0, margin: 0, listStyle: "none", }}>
                                        <li>
                                            <a id={"LocationPopup" + i} href={`https://fleetsync.io/cars/${car.node.pk}?tab=Map`} target={"_blank"} rel="noopener noreferrer">
                                                <LocationIcon width="20" fill="#303E67" />
                                            </a>
                                            <UncontrolledTooltip placement="top" target={"LocationPopup" + i}>
                                                Locate car on fleetSync
                                            </UncontrolledTooltip>
                                        </li>
                                        <li>
                                            {this.state.openModal === "NewReservationModal" + i && <ReservationModalNew handleClose={() => this.setState({ openModal: null })} open={this.state.openModal === "NewReservationModal" + i} carId={car.node.id} refetchQuery={this.props.refetchQuery} title={"New Reservation for Car " + car.node.pk} />}
                                            {(this.state.openModal === "UpdateReservationModal" + i && car.node.carReservation && car.node.carReservation.edges[0] && car.node.carReservation.edges[0].node.id) &&
                                                <ReservationModalNew id={car.node.carReservation.edges[0].node.id} title="Update Reservation" open={this.state.openModal === "UpdateReservationModal" + i} car={car.node} isUpdate={true} handleClose={() => this.toggleModal("")} />
                                            }
                                            <a id={"reserve-car-" + i} onClick={() => this.setState({ openModal: (car.node.carReservation && car.node.carReservation.edges && car.node.carReservation.edges.length > 0 ? "UpdateReservationModal" : "NewReservationModal") + i })}><ReserveCar width={30} fill={car.node.carReservation && car.node.carReservation.edges && car.node.carReservation.edges.length > 0 ? "#28a745" : "#303E67"} /></a>
                                            <UncontrolledTooltip placement="bottom" target={"reserve-car-" + i}>{car.node.carReservation && car.node.carReservation.edges && car.node.carReservation.edges.length > 0 ? "Edit Reservation Info" : "Reserve Car"}</UncontrolledTooltip>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ) : <tr>
                            <td colSpan={10}>{this.props.loading ? "Loading..." : "No Cars Found!"}</td>
                        </tr>}
                        {/* {this.props.cars && this.props.loading ? <tr><td colSpan={10}><Progress animated color="info" value="100" /></td></tr> :
                                    this.props.cars && this.props.cars.pageInfo && this.props.cars.pageInfo.hasNextPage &&
                                    <tr>
                                        <td colSpan={10}><Button style={{ paddingLeft: 0 }} onClick={this.props.loadMoreEntries} color="link linkHoverEffect">Show More <i className={"fa fa-caret-down"}></i></Button></td>
                                    </tr>
                                } */}
                    </tbody>
                </Table>
            </PrimaryListingContainer>
            {/* </div>
                </CardBody>
            </Card>
            <div>
                <Button disabled={this.props.loading} className="float-left mt-2 btn btn-secondary" onClick={() => this.props.refetchQuery()}>Reload &nbsp;&nbsp;<i className="fa fa-refresh"></i></Button>
                {this.props.cars && this.props.cars.pageInfo && this.props.cars.pageInfo.hasNextPage && <Button onClick={this.props.loadMoreEntries} className="float-right mt-2 btn btn-primary-showmore-outline">Show More <i className={"fa fa-caret-down"}></i></Button>}
            </div> */}
        </>)
    }
}

//Iagami - Code upgradation React 18
//-----------------------------------
export default
    graphql(CarsQuery, {
        options: ({ searchTerm, orderBy, isReady, stage, locationId, isFleetManagement, isLocationNull, modelIds, hasDeliveryDate, carTags, carModelNames }) => ({
            variables: {
                searchTerm,
                orderBy,
                isReady,
                stage: isFleetManagement ? stage : stage || 9,
                isFleetManagement,
                locationId,
                first: 30,
                isLocationNull,
                modelIds,
                hasDeliveryDate,
                carTags,
                carModelNames,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
        }),
        props: ({ data: { loading, cars, fetchMore, variables } }) => ({
            loading,
            cars,
            variables,
            refetchQuery: () => {
                const currentLength = cars?.edges?.length || 30
                return fetchMore({
                    query: CarsQuery,
                    variables: { ...variables, first: currentLength },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.cars.edges
                        const pageInfo = fetchMoreResult.cars.pageInfo
                        return {
                            cars: {
                                edges: [...newEdges],
                                pageInfo,
                                searchTerm: prev.searchTerm,
                                __typename: prev.cars.__typename,
                            },
                        }
                    },
                })
            },
            loadMoreEntries: () => {
                return fetchMore({
                    query: CarsQuery,
                    variables: {
                        cursor: cars.pageInfo.endCursor,
                        ...variables,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        const newEdges = fetchMoreResult.cars.edges
                        const pageInfo = fetchMoreResult.cars.pageInfo
                        return {
                            cars: {
                                edges: [...prev.cars.edges, ...newEdges],
                                pageInfo,
                                searchTerm: prev.searchTerm,
                                orderBy: prev.orderBy,
                                isReady: prev.isReady,
                                stage: prev.stage,
                                locationId: prev.locationId,
                                isFleetManagement: prev.isFleetManagement,
                                isLocationNull: prev.isLocationNull,
                                __typename: prev.cars.__typename,
                            },
                        }
                    },
                })
            },
        }),
    })(withApolloClient(CarsList))

